import { InternalRouter } from './InternalRouter';
import { AppProviders } from './AppProviders';
import { AuthenticationManager } from '@gkuis/gkp-authentication';

export type AutomationCheckInternalEntryPointProps = {
  basename: string;
  authenticationManager: AuthenticationManager;
  ekp: string;
};

export const AutomationCheckInternalEntryPoint = ({
  authenticationManager,
  ekp,
  basename,
}: AutomationCheckInternalEntryPointProps): JSX.Element => (
  <AppProviders authenticationManager={authenticationManager} ekp={ekp} basename={basename}>
    <InternalRouter basename={basename} />
  </AppProviders>
);
