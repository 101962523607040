interface WarningIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const WarningIcon = ({ size, style, ...others }: WarningIconProps) => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_645_12758)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00002 18.7979C13.9703 18.7979 18 14.7682 18 9.79793C18 4.82769 13.9703 0.797955 9.00002 0.797955C4.02978 0.797955 0 4.82769 0 9.79793C0 14.7682 4.02978 18.7979 9.00002 18.7979ZM9.00002 17.2979C4.86449 17.2979 1.50003 13.9334 1.50003 9.79793C1.50003 5.66244 4.86449 2.29795 9.00002 2.29795C13.1355 2.29795 16.5 5.66244 16.5 9.79793C16.5 13.9334 13.1355 17.2979 9.00002 17.2979ZM8.25003 11.2979H9.75002V5.29792H8.25003V11.2979ZM8.25003 14.2979H9.75002V12.7979H8.25003V14.2979Z"
        fill="#D40511"
      />
    </g>
    <defs>
      <clipPath id="clip0_645_12758">
        <rect width="18" height="18" fill="white" transform="translate(0 0.797913)" />
      </clipPath>
    </defs>
  </svg>
);
