interface LetterWithWindowStandardProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const LetterWithWindowStandard = ({ size, style, ...others }: LetterWithWindowStandardProps) => (
  <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M61.3033 47.2901V24.7095C61.3029 24.3358 61.1542 23.9775 60.8899 23.7132C60.6257 23.4489 60.2674 23.3003 59.8936 23.2998H13.6066C13.2328 23.3002 12.8745 23.4488 12.6102 23.7131C12.3459 23.9774 12.1973 24.3357 12.1969 24.7095V47.2901C12.1973 47.6639 12.3459 48.0222 12.6102 48.2865C12.8745 48.5508 13.2328 48.6994 13.6066 48.6998H59.8939C60.2676 48.6993 60.6258 48.5507 60.89 48.2864C61.1542 48.0221 61.303 47.6638 61.3033 47.2901Z"
      fill="black"
    />
    <path d="M59.6099 24.9932H13.8899V47.0065H59.6099V24.9932Z" fill="white" />
    <path
      d="M33.7867 36.8466H20.6634V43.1966C20.6635 43.3452 20.6243 43.4913 20.5499 43.6199H33.6473C33.7964 43.6195 33.9392 43.56 34.0447 43.4546C34.1502 43.3492 34.2097 43.2064 34.2103 43.0573V36.7639C34.2103 36.7533 34.2099 36.7436 34.2095 36.7334C34.0808 36.8075 33.9352 36.8466 33.7867 36.8466Z"
      fill="#FFCC00"
    />
    <path
      d="M48.3193 28.3799H55.6605C55.7345 28.3799 55.8077 28.3944 55.876 28.4227C55.9443 28.451 56.0064 28.4925 56.0587 28.5448C56.1109 28.5971 56.1524 28.6591 56.1807 28.7275C56.209 28.7958 56.2236 28.869 56.2236 28.9429V32.4735C56.2236 32.5475 56.209 32.6207 56.1807 32.689C56.1524 32.7573 56.1109 32.8194 56.0587 32.8716C56.0064 32.9239 55.9443 32.9654 55.876 32.9937C55.8077 33.022 55.7345 33.0366 55.6605 33.0366H48.3195C48.1702 33.0366 48.027 32.9772 47.9214 32.8716C47.8158 32.7661 47.7565 32.6228 47.7565 32.4735V28.9425C47.7565 28.8686 47.771 28.7954 47.7993 28.7271C47.8276 28.6589 47.8691 28.5968 47.9213 28.5446C47.9736 28.4923 48.0356 28.4509 48.1039 28.4226C48.1722 28.3944 48.2454 28.3799 48.3193 28.3799Z"
      fill="#FFCC00"
    />
    <path
      d="M19.8166 44.0433C20.0412 44.0432 20.2565 43.954 20.4152 43.7953C20.574 43.6365 20.6632 43.4212 20.6633 43.1967V36.8467H33.7866C34.0112 36.8467 34.2265 36.7575 34.3853 36.5987C34.5441 36.4399 34.6333 36.2245 34.6333 36C34.6333 35.7754 34.5441 35.5601 34.3853 35.4013C34.2265 35.2425 34.0112 35.1533 33.7866 35.1533H19.8166C19.7054 35.1533 19.5953 35.1752 19.4926 35.2177C19.3899 35.2602 19.2965 35.3226 19.2179 35.4012C19.1393 35.4799 19.0769 35.5732 19.0344 35.6759C18.9918 35.7787 18.9699 35.8888 18.97 36V43.1967C18.9699 43.3079 18.9918 43.418 19.0344 43.5207C19.0769 43.6234 19.1393 43.7168 19.2179 43.7954C19.2965 43.874 19.3899 43.9364 19.4926 43.9789C19.5953 44.0215 19.7054 44.0434 19.8166 44.0433Z"
      fill="black"
    />
  </svg>
);
