interface LetterWithoutEnvelopeProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const LetterWithoutEnvelope = ({ size, style, ...others }: LetterWithoutEnvelopeProps) => (
  <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.05 12.7002H17.3567V58.4202H35.1938C44.1124 58.4202 55.1477 59.6902 55.1477 59.6902V13.5468L53.4543 13.475V10.1602C53.4543 10.1602 45.0649 11.8535 36.6755 11.8535H19.8967L19.05 12.7002Z"
      fill="#FFCC00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.6755 10.1607C42.1553 10.1607 47.6361 9.60759 53.1193 8.50086C54.1679 8.28919 55.1476 9.09092 55.1476 10.1607V11.8525L55.2194 11.8556C56.1259 11.894 56.841 12.6401 56.841 13.5474V59.6907C56.841 60.6844 55.9908 61.4603 55.0082 61.3783V61.3841C55.0082 61.3841 43.973 60.1141 35.0544 60.1141C29.6855 60.1141 24.3166 59.5004 20.8934 59.0119L17.3567 58.8441C17.2757 58.8441 17.196 58.8384 17.1181 58.8274C17.4001 58.8028 17.9953 58.7537 18.8373 58.6995C17.8079 58.5329 17.2172 58.4207 17.2172 58.4207V12.7007H19.05L17.78 13.9707L47.1043 48.4208V58.4207C47.1043 59.3559 47.8624 60.1141 48.7976 60.1141H52.6076V59.4291C53.2778 59.4903 53.954 59.558 54.6364 59.6332C54.7929 59.4806 54.9199 59.298 55.0082 59.0947V59.6748C55.0547 59.6801 55.1011 59.6854 55.1476 59.6907V13.5474L53.4543 13.4755L44.028 13.0756C41.0192 12.4571 37.0736 11.854 33.128 11.854H16.9333V58.7907C16.203 58.6027 15.6633 57.9397 15.6633 57.1507V12.7007C15.6633 11.7655 16.4215 11.0074 17.3567 11.0074H18.3486L18.6993 10.6567C19.0169 10.3391 19.4476 10.1607 19.8967 10.1607H36.6755ZM53.4543 11.7807L45.1068 11.4265C47.6821 11.1653 50.2575 10.7844 52.8329 10.2838L53.4543 10.1607V11.7807ZM48.7976 58.4207V50.4101L53.4543 55.8807L53.4543 58.4207H48.7976Z"
      fill="black"
    />
    <path
      d="M16.9333 58.4202C16.9333 58.4202 25.8113 57.5697 34.7705 58.4202C43.7297 59.2707 52.6077 61.8069 52.6077 61.8069V17.7802L49.3227 14.3935C49.3227 14.3935 41.2254 11.8535 33.128 11.8535C25.0307 11.8535 16.9333 11.8535 16.9333 11.8535L16.9333 58.4202Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.4133 14.394V17.7807C47.4133 18.7159 48.1715 19.474 49.1067 19.474H52.4933C54.0019 19.474 54.7574 17.65 53.6907 16.5833L50.304 13.1966C49.2373 12.1299 47.4133 12.8854 47.4133 14.394ZM49.1067 14.394L52.4933 17.7807H49.1067V14.394Z"
      fill="black"
    />
    <path
      d="M38.3084 22.1459C39.8131 22.3138 41.342 22.5038 42.8952 22.7158L44.1136 22.8859L44.9179 23.002L46.1031 23.1798C46.5653 23.2511 46.882 23.6836 46.8107 24.1457C46.7393 24.6078 46.3068 24.9246 45.8447 24.8532L44.922 24.7141L43.8744 24.5623L43.5931 24.5224C41.7338 24.2596 39.9096 24.0285 38.1206 23.8288C37.6559 23.7769 37.3212 23.3582 37.3731 22.8935C37.4226 22.4499 37.8064 22.1248 38.2454 22.1412L38.3084 22.1459Z"
      fill="black"
    />
    <path
      d="M38.1309 19.4742L38.1939 19.4789C39.6986 19.6469 41.2275 19.8368 42.7807 20.0488L44.1569 20.2415L45.177 20.39L46.2441 20.5525L47.0028 20.6749L47.5246 20.7644L47.8662 20.8265C48.3258 20.9126 48.6286 21.355 48.5425 21.8146C48.4564 22.2742 48.014 22.577 47.5544 22.4909L47.1033 22.4096L46.4316 22.2971L45.4674 22.146L44.5326 22.0067L43.4786 21.8554C41.6193 21.5927 39.7951 21.3615 38.0061 21.1618C37.5414 21.11 37.2067 20.6912 37.2586 20.2265C37.3081 19.7829 37.6919 19.4578 38.1309 19.4742Z"
      fill="black"
    />
    <path
      d="M38.1309 16.9342L38.1939 16.9389C39.9995 17.1404 41.84 17.3737 43.7155 17.6387C44.1785 17.7041 44.5008 18.1325 44.4354 18.5955C44.3699 19.0585 43.9416 19.3808 43.4786 19.3153C41.6193 19.0526 39.7951 18.8214 38.0061 18.6218C37.5414 18.5699 37.2067 18.1511 37.2586 17.6864C37.3081 17.2428 37.6919 16.9177 38.1309 16.9342Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9333 10.1602H33.128C38.7002 10.1602 44.2692 11.0336 49.8295 12.7778C50.0984 12.8621 50.342 13.0122 50.5382 13.2145L53.8231 16.6012C54.1296 16.9171 54.301 17.34 54.301 17.7802V61.8068C54.301 62.9318 53.2242 63.744 52.1425 63.435C46.323 61.7726 40.4793 60.6631 34.6104 60.1059C28.7443 59.549 22.9063 59.549 17.0948 60.1058C16.1004 60.2011 15.24 59.4192 15.24 58.4202V11.8535C15.24 10.9183 15.9981 10.1602 16.9333 10.1602ZM48.8319 14.2419C43.5973 12.6496 38.3626 11.8535 33.128 11.8535H16.9333V58.4202L17.5911 58.3595C23.2924 57.8539 29.0189 57.8742 34.7705 58.4202C40.7433 58.9872 46.689 60.1161 52.6076 61.8068V17.7802L49.3227 14.3935L48.8319 14.2419Z"
      fill="black"
    />
    <path d="M49.1067 14.3936L52.4934 17.7802H49.1067V14.3936Z" fill="white" />
  </svg>
);
