import { ReactNode, useState } from 'react';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import { MantineProvider } from '@mantine/core';
import { AuthenticationManagerContext } from './hooks/useAuthenticationManager';
import { theme } from './theme';
import { GlobalStyle } from './GlobalStyle';
import { emotionTransform, MantineEmotionProvider } from '@mantine/emotion';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ProductQueryInfoProvider } from './hooks/useProductInfo';
import { AuthenticationManager } from '@gkuis/gkp-authentication';
import { LanguageInitializer } from './hooks/useLanguage';
import { OpenAPIInitializer } from './hooks/useSetupOpenAPI';
import { MockAuthenticationManager } from './mocks/mockAuthenticationManager';
import { Notifications, notifications } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';

const mockAuthenticationManager = new MockAuthenticationManager();

export type AppProvidersProps = {
  children: ReactNode;
  authenticationManager?: AuthenticationManager;
  ekp?: string;
  basename?: string;
};

export const AppProviders = ({ children, authenticationManager, ekp, basename }: AppProvidersProps) => {
  const [error, setError] = useState<Error | null>(null);
  const { t } = useTranslation();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
      },
      mutations: {
        retry: 0,
      },
    },
    queryCache: new QueryCache({
      onError: (error, query) => {
        if (query.queryKey[0] === 'ProjectControllerServiceGetPreview') {
          return;
        }
        setError(error);
      },
    }),
    mutationCache: new MutationCache({
      onError: (error, _, __, mutation) => {
        notifications.clean();
        notifications.show({
          position: 'top-left',
          title: t('network.error-title'),
          message: t('network.error-notification'),
          color: 'red',
          autoClose: false,
        });
      },
    }),
  });

  const search = window.location.search;
  const params = new URLSearchParams(search);

  if (error) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '100px', flexDirection: 'column' }}>
        <h2>{t('network.error')}</h2>
        <div>
          <a
            style={{ textDecoration: 'none', color: 'blue' }}
            href={`${basename ?? ''}/wizard/1${params.size > 0 ? `?${params.toString()}` : ''}`}
          >
            {t('network.retry')}
          </a>
        </div>
      </div>
    );
  }

  return (
    <AuthenticationManagerContext.Provider value={authenticationManager ?? mockAuthenticationManager}>
      <LanguageInitializer>
        <MantineProvider theme={theme} stylesTransform={emotionTransform}>
          <MantineEmotionProvider>
            <Notifications />
            <OpenAPIInitializer ekp={ekp}>
              <GlobalStyle />
              <QueryClientProvider client={queryClient}>
                <ProductQueryInfoProvider>{children}</ProductQueryInfoProvider>
              </QueryClientProvider>
            </OpenAPIInitializer>
          </MantineEmotionProvider>
        </MantineProvider>
      </LanguageInitializer>
    </AuthenticationManagerContext.Provider>
  );
};
