type IconProps = React.ComponentPropsWithoutRef<'svg'>;

export const CalendarIcon = (props: IconProps) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <path id="Vector" d="M0 20H20V0H0V20Z" fill="#D1D1D1" />
      <path id="Vector_2" d="M0.625 19.375H19.375V0.625H0.625V19.375Z" fill="white" />
      <path
        id="Vector_3"
        d="M7.55493 14.2801C8.17083 14.2801 8.6643 14.14 9.03535 13.8599C9.4064 13.5797 9.59193 13.1872 9.59193 12.6823C9.59193 12.2431 9.45941 11.891 9.19438 11.626C8.92934 11.3609 8.58226 11.2158 8.15316 11.1906L9.36476 10.0623V9.1687H6.14644V10.138H8.01685L6.83554 11.2663V12.0008H7.48678C7.79472 12.0008 8.03073 12.0526 8.19481 12.1561C8.35888 12.2595 8.44091 12.4224 8.44091 12.6445C8.44091 12.8717 8.35888 13.037 8.19481 13.1405C8.03073 13.244 7.81997 13.2957 7.5625 13.2957C7.31513 13.2957 7.08796 13.2579 6.88098 13.1821C6.67399 13.1064 6.4973 13.0054 6.3509 12.8792L5.77539 13.6819C5.98742 13.8687 6.2411 14.0151 6.53643 14.1211C6.83175 14.2271 7.17126 14.2801 7.55493 14.2801Z"
        fill="#666666"
      />
      <path
        id="Vector_4"
        d="M13.7492 14.1667V13.1975H12.795V9.16919H10.75V10.1384H11.6592V13.1975H10.75V14.1667H13.7492Z"
        fill="#666666"
      />
      <path id="Vector_5" d="M0 6.25H20V0H0V6.25Z" fill="#D40511" />
    </g>
  </svg>
);
