interface DeleteIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const DeleteIcon = ({ size, style, ...others }: DeleteIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    style={{ width: size, height: size, ...style }}
    {...others}
  >
    <g clipPath="url(#clip0_4087_6603)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.37032 6.83105C5.37032 6.42395 5.69644 6.09545 6.09711 6.09545C6.49646 6.09545 6.82203 6.42395 6.82203 6.83105V17.9633C6.82203 18.3709 6.49646 18.6994 6.09711 18.6994C5.69644 18.6994 5.37032 18.3709 5.37032 17.9633V6.83105ZM8.99458 6.83105C8.99458 6.42395 9.31961 6.09545 9.72004 6.09545C10.1201 6.09545 10.4439 6.42395 10.4439 6.83105V17.9633C10.4439 18.3709 10.1201 18.6994 9.72004 18.6994C9.31961 18.6994 8.99458 18.3709 8.99458 17.9633V6.83105ZM12.6154 6.83105C12.6154 6.42395 12.9429 6.09545 13.3425 6.09545C13.7421 6.09545 14.0669 6.42395 14.0669 6.83105V17.9633C14.0669 18.3709 13.7421 18.6994 13.3425 18.6994C12.9429 18.6994 12.6154 18.3709 12.6154 17.9633V6.83105ZM3.68004 18.8631C3.68004 19.7671 4.40033 20.5 5.2906 20.5H14.7104C15.5991 20.5 16.3202 19.7671 16.3202 18.8631V4.2946H3.68004V18.8631ZM16.8678 3.672H3.133C2.78328 3.672 2.5 3.3843 2.5 3.02985C2.5 2.67485 2.78328 2.3874 3.133 2.3874H16.8678C17.2164 2.3874 17.5 2.67485 17.5 3.02985C17.5 3.3843 17.2164 3.672 16.8678 3.672ZM11.9735 1.7846H8.02489C7.67516 1.7846 7.39297 1.4969 7.39297 1.14215C7.39297 0.78745 7.67516 0.5 8.02489 0.5H11.9735C12.3232 0.5 12.6073 0.78745 12.6073 1.14215C12.6073 1.4969 12.3232 1.7846 11.9735 1.7846Z"
        fill="#D40511"
      />
    </g>
    <defs>
      <clipPath id="clip0_4087_6603">
        <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
