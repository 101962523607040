import React, { ReactNode, useEffect } from 'react';
import { changeLanguage, initializeI18n } from '../translations';
import { useAuthenticationManager } from './useAuthenticationManager';

interface LanguageComponentProps {
  children: ReactNode;
}

const getBrowserLanguage = (): string => {
  const language = navigator.language || 'de';
  return language.split('-')[0]; // Nur den Sprachcode verwenden
};

initializeI18n(getBrowserLanguage());

export const LanguageInitializer: React.FC<LanguageComponentProps> = ({ children }) => {
  const authenticationManager = useAuthenticationManager();

  useEffect(() => {
    const handleAuthSuccess = async () => {
      const accessToken = await authenticationManager.getAccessTokenParsed();
      changeLanguage(accessToken.language);
    };

    authenticationManager.addEventListener('onAuthSuccess', handleAuthSuccess);
    authenticationManager.addEventListener('onAuthRefreshSuccess', handleAuthSuccess);

    handleAuthSuccess();

    return () => {
      authenticationManager.removeEventListener('onAuthSuccess', handleAuthSuccess);
      authenticationManager.removeEventListener('onAuthRefreshSuccess', handleAuthSuccess);
    };
  }, []);

  return children;
};
