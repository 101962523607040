import { ActionIcon, Flex, Menu, Modal, Text, Tooltip } from '@mantine/core';
import { useProjectControllerServiceArchiveProject, useProjectControllerServiceCopyProject } from '../api/queries';
import 'mantine-datatable/styles.layer.css';
import { useTranslation } from 'react-i18next';
import { ProjectVO } from '../api/requests';
import { GhostButton, PrimaryButton } from '../components/Buttons/Buttons';
import { useDisclosure } from '@mantine/hooks';
import { ThreeDots } from '../components/Icons/ThreeDots';
import { DeleteIcon } from '../components/Icons/DeleteIcon';
import React, { useEffect, useState } from 'react';
import { InputCompact } from '../components/Input/InputCompact';
import { useValidation } from '../hooks/useValidation';
import { useNavigateWithSearchParams } from '../hooks/useNavigateWithSearchParams';

export const ThreeDotsMenu = ({ row, refetchProjects }: { row: ProjectVO; refetchProjects: () => void }) => {
  const [copyModalOpened, { open: openCopyModal, close: closeCopyModal }] = useDisclosure(false);
  const [confirmDeleteModalOpened, { open: openConfirmDeleteModal, close: closeConfirmDeleteModal }] =
    useDisclosure(false);

  const copyProjectQueryInfo = useProjectControllerServiceCopyProject();
  const [newProjectNumber, setNewProjectNumber] = useState<string>('');

  const [newName, setNewName] = useState('');
  const { t } = useTranslation();
  const { navigateAndKeepSearchParams: navigate } = useNavigateWithSearchParams();

  const { validateName } = useValidation();

  const [error, setError] = useState<string | null>(null);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const error = validateName(value);
    setNewName(value);
    setError(error);
  };

  const archiveQueryInfo = useProjectControllerServiceArchiveProject();

  const confirmDelete = async () => {
    closeConfirmDeleteModal();
    await archiveProject();
  };

  const copyProject = async () => {
    const response = await copyProjectQueryInfo.mutateAsync({
      projectNumber: row.projectNumber,
      requestBody: {
        name: newName,
      },
    });
    setNewProjectNumber(response.projectNumber);
  };

  const archiveProject = async () => {
    await archiveQueryInfo.mutateAsync({
      projectNumber: row.projectNumber,
    });
    refetchProjects();
  };

  useEffect(() => {
    setError(null);
    setNewName('');
  }, [copyModalOpened]);

  useEffect(() => {
    if (copyProjectQueryInfo.isSuccess && newProjectNumber !== '') {
      navigate(`/wizard/${newProjectNumber}/1`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyProjectQueryInfo.isSuccess, newProjectNumber]);

  return (
    <Flex>
      <Menu>
        <Menu.Target>
          <Tooltip
            withArrow
            arrowSize={10}
            label={t('optionsButton.mouseover')}
            style={{
              backgroundColor: 'var(--BASIC-Black)',
              color: 'var(--BASIC-White)',
            }}
          >
            <ActionIcon variant="default" aria-label="Settings">
              <ThreeDots />
            </ActionIcon>
          </Tooltip>
        </Menu.Target>
        <Menu.Dropdown style={{ boxShadow: '0px 5px 5px 0px rgba(50, 50, 50, 0.2)' }}>
          <Menu.Item onClick={openCopyModal} style={{ color: 'var(--BRAND-DHL-Red)', fontWeight: 'bold' }}>
            <Flex align="center">
              <DeleteIcon size={15} style={{ marginRight: '5px' }} />
              <Text style={{ color: 'var(--BRAND-DHL-Red)', fontWeight: 'bold', fontSize: '12px' }}>
                {t('dashboard.copyAndapplySettings')}
              </Text>
            </Flex>
          </Menu.Item>
          <Menu.Item onClick={openConfirmDeleteModal}>
            <Flex align="center">
              <DeleteIcon size={15} style={{ marginRight: '5px' }} />
              <Text style={{ color: 'var(--BRAND-DHL-Red)', fontWeight: 'bold', fontSize: '12px' }}>
                {t('dashboard.deleteCheck')}
              </Text>
            </Flex>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <Modal
        opened={copyModalOpened}
        onClose={closeCopyModal}
        title={
          <Text
            style={{
              fontSize: '20px',
              fontWeight: 800,
              lineHeight: '22.5px',
            }}
          >
            {t('dashboard.NameOfCheck')}
          </Text>
        }
      >
        <InputCompact
          style={{ marginBottom: '20px' }}
          data-testid="name"
          required
          label={t('step-one.test-order-name')}
          value={newName}
          onChange={handleNameChange}
          error={error}
          styles={{
            error: {
              width: '100%!important',
            },
          }}
        />
        <Flex align="center" gap="10px">
          <GhostButton
            onClick={() => {
              closeCopyModal();
            }}
          >
            {t('dashboard.cancel')}
          </GhostButton>
          <PrimaryButton
            disabled={error !== null}
            onClick={() => {
              copyProject();
            }}
          >
            {t('dashboard.overtake')}
          </PrimaryButton>
        </Flex>
      </Modal>
      <Modal
        opened={confirmDeleteModalOpened}
        onClose={closeConfirmDeleteModal}
        title={
          <Text
            style={{
              fontSize: '20px',
              fontWeight: 800,
              lineHeight: '22.5px',
            }}
          >
            {t('dashboard.confirmDeleteTitle', { testName: row.name })}
          </Text>
        }
      >
        <Text style={{ marginBottom: '20px' }}>{t('dashboard.confirmDeleteMessage')}</Text>
        <Flex align="center" justify={'space-between'}>
          <GhostButton onClick={closeConfirmDeleteModal}>{t('dashboard.cancel')}</GhostButton>
          <PrimaryButton onClick={confirmDelete}>{t('dashboard.deleteTest')}</PrimaryButton>
        </Flex>
      </Modal>
    </Flex>
  );
};
