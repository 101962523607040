interface FileUploadIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const FileUploadIcon = ({ size, style, ...others }: FileUploadIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_1749_49577)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.58823 3.33407L20.1765 12.0649L18.6851 13.2946L10.631 6.65333L10.6317 20.08H8.54476L8.544 6.65417L0.491371 13.2946L-1 12.0649L9.58823 3.33407ZM-0.254314 1.73913V0H19.4308V1.73913H-0.254314Z"
        fill="#878787"
      />
    </g>
    <defs>
      <clipPath id="clip0_1749_49577">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
