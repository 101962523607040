import React from 'react';
import { Flex, List, ListItem, Text } from '@mantine/core';
import { InfoIcon } from '../Icons/InfoIcon';

interface ListItem {
  title?: string;
  details: React.ReactNode;
}

interface ImportantInstructionsProps {
  infoText: string;
  listItems: ListItem[];
  hideBulletpoints?: boolean;
  footer?: string;
}

export const ImportantInstructions: React.FC<ImportantInstructionsProps> = ({
  infoText,
  listItems,
  hideBulletpoints,
  footer,
}) => {
  return (
    <Flex
      direction="column"
      style={{
        backgroundColor: 'var(--BRAND-Postyellow-30p)',
        borderRadius: '8px',
        padding: '14px 20px',
        alignItems: 'flex-start',
      }}
    >
      <Flex
        direction="row"
        gap={10}
        style={{
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <InfoIcon color="var(--BASIC-Black)"></InfoIcon>
        <Text fw={700}>{infoText}</Text>
      </Flex>
      <Flex style={{ padding: '10px 25px' }}>
        <List listStyleType={hideBulletpoints ? 'none' : 'initial'}>
          {listItems.map((item, index) => (
            <ListItem key={index}>
              {item.title ? <Text style={{ fontWeight: 700 }}>{item.title}</Text> : null} <Text>{item.details}</Text>
            </ListItem>
          ))}
        </List>
      </Flex>
      {footer ? (
        <Flex
          direction="row"
          style={{
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '25px',
          }}
        >
          <Text fw={700}>{footer}</Text>
        </Flex>
      ) : null}
    </Flex>
  );
};
