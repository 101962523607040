import { Container } from '@mantine/core';
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';

interface RouterProps {
  routes: RouteObject[];
  basename?: string;
}

export const Router = ({ routes, basename }: RouterProps) => {
  const router = createBrowserRouter(routes, { basename });

  return (
    <Container size="xl">
      <RouterProvider router={router} />
    </Container>
  );
};
