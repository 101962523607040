interface EditIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const EditIcon = ({ size, style, ...others }: EditIconProps) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" style={style} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_352_31619)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12L1.06065 8.1109L3.8891 10.9394L0 12ZM2.1005 7.07105L4.92895 9.8995L12 2.82845L9.17155 0L2.1005 7.07105Z"
        fill="#4CAF50"
      />
    </g>
    <defs>
      <clipPath id="clip0_352_31619">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
