import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const resources = {
  en: {
    translation: {
      'network.error': 'The page could not be loaded.',
      'network.retry': 'Retry',
      'network.error-notification': 'The action could not be performed. Please perform the action again.',
      'network.error-title': 'Error',
      automationCheck: 'Check automation capability of mail item layout',
      NameTestOrder: 'Name & Mail Item Dimensions',
      ShipmentType: 'Select design variant',
      FrankingAndSenderPosition: 'Franking & Sender Position',
      SelectPDF: 'Select PDF',
      UploadPDF: 'Upload PDF & Check',
      fillMandatoryFields: 'Please fill in all mandatory fields to continue',
      cancel: 'Cancel',
      next: 'Next',
      startCheck: 'Upload PDF & Check',
      complete: 'Complete check & download check report',
      download: 'Download check report',
      retry: 'Upload new layout',
      backToDashboard: 'Back to dashboard',
      mandatory: 'Mandatory',
      'single-file-upload.drag-file-here': 'Drag a file here',
      'single-file-upload.or': 'or',
      'single-file-upload.select-file': 'select a file',
      'file-upload-alert.selected': 'selected',
      'file-upload-alert.not-usable': 'not usable',
      'file-upload-alert.file-invalid-type': 'Only PDF files are allowed',
      'file-upload-alert.file-too-large': 'The uploaded file exceeds the maximum file size',
      'file-upload-alert.file-too-small': 'The uploaded file is too small',
      'file-upload-alert.too-many-files': 'You have uploaded too many files',
      'step-one.input.cannot-be-empty': 'Please enter a value',
      'step-one.input.too-long': 'The name is too long. Maximum 50 characters allowed',
      'step-one.input.invalid-characters':
        'The name contains invalid characters. Allowed characters: A-Za-z0-9_äöüÄÖÜß-',
      'step-one.input.must-be-number': 'The value must be a number',
      'step-one.input.invalid-length': 'The value must be between 140 and 353',
      'step-one.input.invalid-width': 'The value must be between 90 and 250',
      'step-one.specify.test-order-name': 'Name & Mail Item Dimensions',
      'step-one.test-order-name': 'Name of the check',
      'step-one.specify.shipment.dimensions': 'Mail item dimensions',
      'step-one.specify.shipment.dimensions.details': 'The length is the longest side of the mail item.',
      'step-one.specify.weight-of-order': 'Weight of mail item',
      'step-one.specify.height-of-order': 'Thickness of mail item',
      'step-one.length': 'Length',
      'step-one.width': 'Width',
      'step-one.height': 'Height',
      'step-one.weight': 'Weight',
      'step-one.height.up-to-five': 'up to 5mm',
      'step-one.height.five-to-thirty': 'from 5mm to 30mm',
      'step-one.weight.up-to-fifty': 'up to 50g',
      'step-one.weight.fifty-to-thousand': 'from 50g to 1000g',
      'step-one.subtitle': 'Name of this check',
      'step-one.base-format.standard': 'Standard basic format detected',
      'step-one.base-format.large': 'Large basic format detected',
      'step-one.base-format.standard.details': 'Based on your entries, we have detected the basic format "Standard".',
      'step-one.base-format.large.details': 'Based on your entries, we have detected the basic format "Large".',
      'step-one.info.general-hints': 'General hints for mail item dimensions',
      'step-one.info.list.length': 'Length:',
      'step-one.info.list.length.details': 'The longest side is always the length.',
      'step-one.info.list.format-standard': 'Standard basic format:',
      'step-one.info.list.format-standard.details':
        'The length for standard corresponds to at least 1.4 times the width.',
      'step-one.info.list.format-gross': 'Large basic format:',
      'step-one.error.short-side-greater-than-long-side':
        'The value in the "Width" field must not be greater than the value in the "Length" field',
      'step-two': {
        'specify.envelope-window': 'Select design variant',
        NO_ENVELOPE: 'Mail item without envelope',
        'NO_ENVELOPE.disabled-reason':
          'This selection is not available to you because mail items without envelopes under 50 g are not automation-capable.',
        NO_WINDOW: 'Mail item without window',
        WITH_WINDOW: 'Mail item with window',
        WITH_TRENNSTRICH: 'Mail item with dividing line',
        'specify.format': 'Select format',
        'landscape-format': 'Landscape format',
        'portrait-format': 'Portrait format',
        'specify.address': 'Specify address',
        'important-instructions': {
          small: {
            NO_WINDOW: {
              title: 'Important hints for "Mail item without window"',
              list: [
                'The address area must be legible and placed parallel to the long sides of the mail item.',
                'Please enter the sender information either in the designated sender zone or in a single line above the recipient address.',
                'Alphanumeric information may only be placed to the left and above the address area at a distance of at least 20 mm. Exceptions are alphanumeric information in negative print. These may extend up to 5 mm to the address area and may therefore also be placed within the quiet zone.',
                'A dark border around the address area field is allowed.',
              ],
            },
            WITH_WINDOW: {
              title: 'Important hints for "Mail item with window"',
              list: [
                'The address area must always be fully visible in the window and must be at least 3 mm away from all edges.',
                'Windows can be used for both envelopes and self-mailers and must be aligned parallel to the long sides in the reading zone.',
                'The window must be made of a firmly glued, transparent material so that the address area is clearly legible.',
              ],
              footer:
                'If you want to check a mail item with a panorama window and a dividing line, please select "Mail item with dividing line".',
            },
            WITH_TRENNSTRICH: {
              title: 'Important hints for "Mail item with dividing line"',
              list: [
                'The dividing line must be 1.2 mm wide and at least 35 mm long and cover the entire height of the address area.',
                'It must be dark, continuous, and free-standing, and a white or light quiet zone of at least 5 mm must be maintained around it.',
                'The recipient address must start 5 to 8 mm to the right of the dividing line and the entire address area must end at least 5 mm before the right edge of the mail item.',
              ],
            },
          },
          big: {
            NO_WINDOW: {
              title: 'Important hints for "Mail item without window"',
              list: [
                'Make sure that a quiet zone of 20 mm is maintained around the address area, which must be white or light-colored. The rest of the reading zone can be colored or graphically designed.',
                'A quiet zone of 20 mm must be observed around the address area, which must be white or light-colored. Alphanumeric information in negative print may extend up to 5 mm to the address area, even within the quiet zone.',
                'A dark design of the quiet zone is also possible. In this case, a clearly visible field (similar to a label or window) must be used for the address area.',
              ],
            },
            WITH_WINDOW: {
              title: 'Important hints for "Mail item with window"',
              list: [
                'The window must be located within the reading zone and aligned parallel to the narrow or long sides.',
                'The address area must be fully visible in the window.',
                'For envelopes with windows, a color design up to the window edge is allowed.',
                'A quiet zone of at least 10 mm must be maintained around the window. No alphanumeric information may be made or protrude into this quiet zone (except in negative print).',
              ],
            },
            NO_ENVELOPE: {
              title: 'Important hints for "Mail item without envelope"',
              list: [
                'The mail item must be designed in portrait format and be either longer than 235 mm or wider than 125 mm and at least 50 g in weight.',
                'The bound edge and/or the final fold must always be placed on the right long side of the mail item. You can place the address either on the cover page in upside-down format or legibly on the back.',
                'Mail item with bound edge on the left side or on the narrow sides are not allowed.',
                'Square mail items are allowed if the sides are at least 140 mm long and the design meets the requirements for portrait mail items.',
              ],
            },
          },
        },
        infobox: {
          title: 'Important hints',
          small: {
            list: [
              'The address area must be located in the reading zone, aligned parallel to the long sides and be clearly legible. The recipient must be able to be identified quickly and unambiguously.',
              'If the sender information is to be placed within the reading zone, please place it in a single line above the address. Additional postal information must not be placed within the reading zone.',
              'The background of the address area, including a surrounding area of 3 mm, must be white or in a pastel tone.',
            ],
          },
          big: {
            list: [
              'The address area must always be clearly legible in the reading zone. For mail items in landscape format, align the address area parallel to the long sides, and for mail items in portrait format, align it parallel to the narrow sides.',
              'The quiet zone around the address area can be designed in both light and dark colors.',
              'The quiet zone around the address area must be white or light-colored. No further deliverable information may be placed within the reading zone.',
            ],
          },
          footer:
            'You can find help with the test steps by clicking on the help icon (question mark at the top right of the page).',
        },
      },
      'step-three': {
        specify: {
          'franking-placement': 'Placement of the franking',
          'sender-placement': 'Placement of the sender',
        },
        FRANKING_ZONE: 'Franking zone',
        AUFSCHRIFT: 'Above the address',
        AUFSCHRIFT_SENDER: 'Above the address',
        FRANKING_ZONE_AND_AUFSCHRIFT: 'In both areas',
        SENDER_ZONE: 'Sender zone',
        NONE: 'Without sender',
        'important-instructions': {
          FRANKING_ZONE: {
            title: 'Important hints for "Franking zone"',
            list: [
              'The franking zone must be located on the address side in the top right corner of the mail item. It has the dimensions 74 mm in length and 40 mm in width.',
              'The franking zone is intended exclusively for franking mail items. Only franking methods approved by Deutsche Post may be used. Other postal markings or service information from Deutsche Post may not be placed in this zone.',
              'The background of the franking zone must be white or a plain pastel color.',
            ],
          },
          AUFSCHRIFT: {
            title: 'Important hints for "Above the address"',
            list: [
              'If you use franking marks where the franking zone can remain free (such as with computer franking), you can design the entire area of ​​the franking zone in color.',
              'Free-standing graphics, such as logos or distractions, are permitted in the franking zone. However, they should not be shown in a rectangular shape or with a rectangular frame.',
              'Alphanumeric details are also possible, but must only be printed in negative.',
            ],
          },
          FRANKING_ZONE_AND_AUFSCHRIFT: {
            title: 'Important hints for "In both areas"',
            list: [
              'All parts of the franking mark must be visible on the address side, including the franking wave, the product information and the reference to Deutsche Post.',
              'The franking zone is located on the address side in the top right corner of the mail item and measures 74 mm in length and 40 mm in width. This zone is intended exclusively for franking and may only be used with franking methods approved by Deutsche Post. Other postal markings or service information from Deutsche Post may not be placed in this zone.',
              'Free-standing graphics such as logos or distractions are permitted within the label as long as they are not rectangular or are provided with a rectangular frame. Alphanumeric information is also possible, but only in negative printing.',
            ],
          },
          SENDER_ZONE_SENDER: {
            title: 'Important hints for "Sender zone"',
            list: [
              'The sender zone is located on the address side in the top left corner of the mail item. It is 40 mm wide and extends to 74 mm from the right edge of the mail item.',
              'Please always place your sender information within this sender zone.',
            ],
          },
          AUFSCHRIFT_SENDER: {
            title: 'Important hints for "Above the address"',
            list: [
              'If you want to place the sender information within the address, please place it one line above the address and use a significantly smaller font size.',
            ],
          },
          NONE_SENDER: {
            title: 'Important hints for "Without sender"',
            list: [
              'In order to process a return, it is necessary to place the sender or return information on the address side.',
            ],
          },
        },
        infobox: {
          title: 'General information on franking and sender',
          list: [
            'For all detailed specifications for the franking zone, please see chapters 3.2 and 5.2 of the guide <0>Mail items suitable for automatic processing</0> as well as <1>Information Franking wave</1>. Also pay attention to the specific requirements of the franking type you have chosen.',
            'For further information on the design of the sender details, see chapters 3.3 and 5.3 of the guide <0>Mail items suitable for automatic processing</0>.',
          ],
          footer:
            'You can find help with the test steps by clicking on the help icon (question mark at the top right of the page).',
        },
      },
      'step-four.specify.select-pdf': 'Select PDF',
      'step-four.important-instructions': {
        title: 'Important information',
        dataprotection: {
          title: 'Data protection:',
          details: {
            0: 'By uploading, you confirm that the PDF does not contain any personal data in accordance with the General Data Protection Regulation (GDPR).',
            1: 'The service uses machine learning (AI) methods to measure your transmitted data. Your data remains exclusively on the IT systems of Deutsche Post AG.',
          },
        },
        recipientaddress: '<b>Test address area:</b> Please use our provided <l>test address area</l> for the address area of the layout.',
      },
      'step-four.infobox': {
        title: 'Notes on PDF upload',
        list: [
          'Please upload a <b>single-page PDF</b> that only shows your layout in a simple design and good quality.',
          'The uploaded PDF should correspond to the address side of your advertising material.',
          '<b>Please integrate the test address area provided including the test image for franking into your layout.</b> You can find the test address area <l>here</l>. It is important that you use the address area exactly as shown, including the line "An". Otherwise, the machine readability including quiet zones cannot be checked.​​',
          'For envelopes with a window, the <b>window cutout (cutting line)</b> must be shown in <b>magenta</b> in the layout.',
          'Make sure that all fonts are embedded.',
        ],
        footer:
          'You can find help with the test steps by clicking on the help icon (question mark at the top right of the page).',
      },
      'step-five.specify.result': 'Check result',
      'step-five.alert.checking': 'is being checked',
      'step-five.alert.not-automation-capable.title': 'The layout is NOT automation-capable.',
      'step-five.alert.automation-capable.title': 'Automation-capable:',
      'step-five.alert.automation-capable': 'The mail item is designed for automation and machine-readable.',
      'step-five.ok': 'OK',
      'step-five.please-check': 'Please check',
      'step-five.malwareAlert.title': 'Malware found',
      'step-five.malwareAlert.message': 'Malware found: The file was detected as malware. Please upload a clean file.',
      'step-five.malwareAlert.causeTitle': 'How does a malware error occur?',
      'step-five.malwareAlert.causeDescription':
        'A malware error occurs when a file contains malicious software (malware) that can be harmful to your computer or network. This can have various causes:',
      'step-five.malwareAlert.cause1':
        'Infected file: The file may have been created or edited on an infected computer.',
      'step-five.malwareAlert.cause2':
        'Downloaded file: Files downloaded from insecure sources on the internet may contain malware.',
      'step-five.malwareAlert.cause3':
        'Manipulated file: The file may have been intentionally or unintentionally manipulated by a third party to spread malicious software.',
      'step-five.malwareAlert.solution':
        'Make sure the file comes from a trusted source and run a virus scan before uploading the file again.',
      'step-five.print': 'Print error report',
      'step-five.preview': 'Layout preview',
      'step-five.info':
        'When submitting items of the above-mentioned mail item concept, please enclose a printout of all pages of this inspection report in the DIALOGPOST delivery list as proof of successful inspection.',
      'step-five.important': 'Important information',
      'step-five.preview-image-error': 'The preview image could not be loaded.',
      'step-five.process-number': 'Process number',
      'dashboard.my-checks': 'My checks',
      'dashboard.description.important': 'Important: ',
      'dashboard.description.details-1': 'Each mail item is checked for the correct design of the address sides.',
      'dashboard.description.details-2': 'For the recipient address of the layout please use our provided ',
      'dashboard.description.details-3': 'test label.',
      'dashboard.start-new-check': 'Start new check',
      'dashboard.projectNumber': 'Project Number',
      'dashboard.projectName': 'Name of the check',
      'dashboard.recordsPerPage': 'Number of checks per page',
      'dashboard.projectState': 'State',
      'dashboard.validUntil': 'Valid until',
      'dashboard.on': 'on',
      'dashboard.deletion': 'Deletion on',
      'dashboard.productType': 'Product',
      'dashboard.basisFormat': 'Basis Format',
      'dashboard.updated': 'Last updated on',
      'dashboard.downloadCheckReport': 'Download check result',
      'dashboard.downloadErrorReport': 'Download error report',
      'dashboard.checkAgain': 'Check again',
      'dashboard.copyAndapplySettings': 'Apply data and settings for new check',
      'dashboard.deleteCheck': 'Delete check',
      'dashboard.NameOfCheck': 'Name of the check',
      'dashboard.overtake': 'Apply',
      'dashboard.cancel': 'Cancel',
      'dashboard.confirmDeleteTitle': 'Do you really want to delete the check {{testName}}?',
      'dashboard.confirmDeleteMessage':
        'If you delete this check, the check report and the layout file will be deleted irrevocably.',
      'dashboard.deleteTest': 'Delete check',
      'dashboard.yes': 'Yes',
      'dashboard.search': 'Name of the Check',
      'dashboard.startDate': 'Start date',
      'dashboard.endDate': 'End date',
      'dashboard.product': 'Product',
      'dashboard.dateFormat': 'MM/DD/YYYY',
      'dashboard.state': 'State',
      'dashboard.createdByFamilyName': 'Lastname',
      'dashboard.name': 'Name',
      'helpButton.mouseover': 'Click here for help',
      'errorHelpButton.mouseover': 'For more information, click here',
      'optionsButton.mouseover': 'More options',
      intlDateTime: '{{val, datetime}}',
      DIALOGPOST: 'Dialogpost',
      POSTWURFSPEZIAL: 'Letterbox advertising',
      BRIEF: 'Letter',
      POSTKARTE: 'Postcard',
      WERBEANTWORT: 'Response',
      RESPONSEPLUS: 'Response Plus',
      WARENSENDUNG: 'Parcel',
      STANDARD: 'Standard',
      KOMPAKT: 'Compact',
      GROSS: 'Large',
      MAXI: 'Maxi',
      PENDING: 'Not checked yet',
      FAILED: 'Failed',
      RUNNING: 'Checking',
      DONE_FAILED: 'Not automation capable',
      DONE_SUCCEEDED: 'Automation capable',
      CLOSED: 'Automation capable',
      EXPIRED: 'Expired',
    },
  },
  de: {
    translation: {
      'network.error': 'Die Seite konnte leider nicht geladen werden.',
      'network.retry': 'Erneut versuchen',
      'network.error-notification':
        'Die Aktion konnte nicht durchgeführt werden. Bitte führen Sie die Aktion erneut aus.',
      'network.error-title': 'Fehler',
      automationCheck: 'Sendungslayout auf Automationsfähigkeit prüfen',
      NameTestOrder: 'Name & Sendungsmaße vergeben',
      ShipmentType: 'Gestaltungsvariante wählen',
      FrankingAndSenderPosition: 'Frankierung & Absender Platzierung',
      SelectPDF: 'PDF auswählen',
      UploadPDF: 'PDF hochladen & prüfen',
      fillMandatoryFields: 'Bitte machen Sie alle Pflichtangaben, um fortzufahren',
      cancel: 'Abbrechen',
      next: 'Weiter',
      startCheck: 'PDF hochladen & prüfen',
      complete: 'Prüfung abschließen & Prüfbericht herunterladen',
      download: 'Prüfbericht herunterladen',
      retry: 'Neues Layout hochladen',
      backToDashboard: 'Zurück zum Dashboard',
      mandatory: 'Pflichtangabe',
      'single-file-upload.drag-file-here': 'Datei für den Upload hier hin ziehen',
      'single-file-upload.or': 'oder',
      'single-file-upload.select-file': 'Datei auswählen',
      'file-upload-alert.selected': 'ausgewählt',
      'file-upload-alert.not-usable': 'kann nicht verwendet werden',
      'file-upload-alert.file-invalid-type': 'Es ist nur der Upload von PDF-Dateien erlaubt.',
      'file-upload-alert.file-too-large': 'Die hochgeladene Datei überschreitet die maximale Dateigröße.',
      'file-upload-alert.file-too-small': 'Die hochgeladene Datei unterschreitet die minimale Dateigröße.',
      'file-upload-alert.too-many-files': 'Sie haben zu viele Dateien hochgeladen.',
      'step-one.input.cannot-be-empty': 'Bitte geben Sie einen Wert ein.',
      'step-one.input.too-long': 'Der Name ist zu lang. Es sind maximal 50 Zeichen erlaubt.',
      'step-one.input.invalid-characters': 'Der Name enthält ungültige Zeichen. Erlaubte Zeichen: A-Za-z0-9_äöüÄÖÜß-',
      'step-one.input.must-be-number': 'Der Wert muss eine Zahl sein.',
      'step-one.input.invalid-length': 'Der Wert muss zwischen {{min}} und {{max}} liegen.',
      'step-one.input.invalid-width': 'Der Wert muss zwischen {{min}} und {{max}} liegen.',
      'step-one.specify.test-order-name': 'Name & Sendungsmaße vergeben',
      'step-one.test-order-name': 'Name Prüfauftrag',
      'step-one.specify.shipment.dimensions': 'Sendungsmaße',
      'step-one.specify.shipment.dimensions.details': 'Die Länge ist die längste Seite der Sendung.',
      'step-one.specify.weight-of-order': 'Gewicht der Sendung',
      'step-one.specify.height-of-order': 'Dicke der Sendung',
      'step-one.length': 'Länge',
      'step-one.width': 'Breite',
      'step-one.height': 'Höhe',
      'step-one.weight': 'Gewicht',
      'step-one.height.up-to-five': 'bis 5mm',
      'step-one.height.five-to-thirty': 'ab 5mm bis 30mm',
      'step-one.weight.up-to-fifty': 'bis 50g',
      'step-one.weight.fifty-to-thousand': 'ab 50g bis 1000g',
      'step-one.subtitle': 'Name dieser Prüfung',
      'step-one.base-format.standard': 'Basisformat "Standard" erkannt',
      'step-one.base-format.large': 'Basisformat "Groß" erkannt',
      'step-one.base-format.standard.details': 'Anhand Ihrer Eingaben haben wir das Basisformat "Standard" erkannt.',
      'step-one.base-format.large.details': 'Anhand Ihrer Eingaben haben wir das Basisformat "Groß" erkannt.',
      'step-one.info.general-hints': 'Allgemeine Hinweise zu Sendungsmaßen',
      'step-one.info.list.length': 'Länge:',
      'step-one.info.list.length.details': 'Die längste Seite ist immer die Länge.',
      'step-one.info.list.format-standard': 'Basisformat Standard:',
      'step-one.info.list.format-standard.details':
        'Die Länge bei Standard entspricht mind. dem 1,4-fachen der Breite.',
      'step-one.info.list.format-gross': 'Basisformat Groß:',
      'step-one.error.short-side-greater-than-long-side':
        'Im Feld "Breite" darf kein größerer Wert als im Feld "Länge" stehen',
      'step-two': {
        'specify.envelope-window': 'Gestaltungsvariante wählen',
        NO_ENVELOPE: 'Sendung ohne Umhüllung',
        'NO_ENVELOPE.disabled-reason':
          'Diese Auswahl steht Ihnen nicht zur Verfügung, da Sendungen\n ohne Umhüllung unter 50 g nicht automationsfähig sind.',
        NO_WINDOW: 'Sendung ohne Fenster',
        WITH_WINDOW: 'Sendung mit Fenster',
        WITH_TRENNSTRICH: 'Sendung mit Trennstrich',
        'specify.format': 'Format wählen',
        'landscape-format': 'Querformat',
        'portrait-format': 'Hochformat',
        'specify.address': 'Adresse angeben',
        'important-instructions': {
          small: {
            NO_WINDOW: {
              title: 'Wichtige Hinweise zu „Sendung ohne Fenster“',
              list: [
                'Die Aufschrift muss lesegerecht und parallel zu den Längsseiten der Sendung platziert werden.',
                'Bitte geben Sie die Absenderangabe entweder in der dafür vorgesehenen Absenderzone oder einzeilig oberhalb der Empfängeranschrift an.',
                'Achten Sie darauf, dass rund um die Aufschrift  eine Ruhezone von 20 mm eingehalten wird, die weiß oder hellfarbig gestaltet sein muss. Alphanumerische Angaben dürfen nur links und oberhalb der Aufschrift in einem Abstand von mindestens 20 mm  angebracht werden. Ausnahmen bilden alphanumerische Angaben im Negativdruck. Diese dürfen umlaufend bis 5 mm an die Aufschrift heranreichen und damit auch innerhalb der Ruhezone angebracht werden.',
                'Eine dunkle Umrahmung des Aufschriftfeldes ist erlaubt.',
              ],
            },
            WITH_WINDOW: {
              title: 'Wichtige Hinweise zu „Sendung mit Fenster“',
              list: [
                'Die Aufschrift muss immer vollständig im Fenster sichtbar sein und mindestens 3 mm von allen Rändern entfernt bleiben.',
                'Fenster können sowohl bei Briefumschlägen als auch bei Selfmailern verwendet werden und müssen in der Lesezone parallel zu den Längsseiten ausgerichtet sein.',
                'Das Fenster muss aus einem fest verklebten, durchsichtigen Material bestehen, sodass die Aufschrift gut lesbar ist.',
              ],
              footer:
                'Möchten Sie eine Sendung mit Panoramafenster und Trennstrich prüfen, wählen Sie bitte "Sendung mit Trennstrich".',
            },
            WITH_TRENNSTRICH: {
              title: 'Wichtige Hinweise zu „Sendung mit Trennstrich“',
              list: [
                'Der Trennstrich muss 1,2 mm breit und mindestens 35 mm lang sein und die gesamte Höhe der Aufschrift abdecken.',
                'Er muss dunkel, durchgehend und frei stehend sein, und um ihn herum muss eine weiße oder helle Ruhezone von mindestens 5 mm eingehalten werden.',
                'Die Empfängeranschrift muss 5 bis 8 mm rechts vom Trennstrich beginnen und die gesamte Aufschrift muss mindestens 5 mm vor dem rechten Rand der Sendung enden.',
              ],
            },
          },
          big: {
            NO_WINDOW: {
              title: 'Wichtige Hinweise zu „Sendung ohne Fenster“',
              list: [
                'Bitte achten Sie darauf, dass rund um die Aufschrift eine Ruhezone von 20 mm eingehalten wird, die weiß oder in einem hellen Farbton gestaltet sein muss. Die restliche Lesezone kann farblich oder grafisch gestaltet werden.',
                'Alphanumerische Angaben im Negativdruck dürfen bis zu 5 mm an die Aufschrift heranreichen, auch innerhalb der Ruhezone.',
                'Eine dunkle Gestaltung der Ruhezone ist ebenfalls möglich. In diesem Fall ist für die Aufschrift ein gut sichtbares Feld (ähnlich einem Etikett oder Fenster) zu verwenden.',
              ],
            },
            WITH_WINDOW: {
              title: 'Wichtige Hinweise zu „Sendung mit Fenster“',
              list: [
                'Das Fenster muss sich innerhalb der Lesezone befinden und parallel zu den Schmal- oder Längsseiten ausgerichtet sein.',
                'Die Aufschrift muss vollständig im Fenster sichtbar sein.',
                'Bei Briefhüllen mit Fenster ist eine farbliche Gestaltung bis zum Fensterrand erlaubt.',
                'Um das Fenster herum muss eine Ruhezone von mindestens 10 mm eingehalten werden. In dieser Ruhezone dürfen keine alphanumerischen Angaben gemacht werden oder hineinragen (außer im Negativdruck).',
              ],
            },
            NO_ENVELOPE: {
              title: 'Wichtige Hinweise zu „Sendung ohne Umhüllung“',
              list: [
                'Die Sendung muss im Hochformat gestaltet sein und entweder länger als 235 mm oder breiter als 125 mm sowie mindestens 50 g schwer.',
                'Der Heftrand oder finale Falz muss immer an der rechten Längsseite der Sendung platziert sein. Sie können die Adresse entweder auf der Titelseite im Kopf stehenden Format oder lesegerecht auf der Rückseite anbringen.',
                'Sendungen mit Hefträndern an der linken Seite oder an den Schmalseiten sind nicht zulässig.',
                'Quadratische Sendungen sind erlaubt, wenn die Seiten mindestens 140 mm lang sind und die Gestaltung den Anforderungen für Hochformat-Sendungen entspricht.',
              ],
            },
          },
        },
        infobox: {
          title: 'Es gelten folgende Richtlinien:',
          small: {
            list: [
              'Die Aufschrift muss sich in der Lesezone befinden, parallel zu den langen Seiten ausgerichtet und gut lesbar sein. Der Empfänger muss schnell und eindeutig identifiziert werden können.',
              'Wenn die Absenderangabe innerhalb der Lesezone angebracht werden soll, platzieren Sie diese bitte einzeilig oberhalb der Anschrift. Weitere zustellfähige Angaben dürfen nicht innerhalb der Lesezone erfolgen.',
              'Der Hintergrund der Aufschrift, einschließlich eines umlaufenden Bereichs von 3 mm, muss weiß oder in einem Pastellton gehalten sein.',
            ],
          },
          big: {
            list: [
              'Die Aufschrift muss immer gut lesbar in der Lesezone platziert werden. Bei Sendungen im Querformat richten Sie die Aufschrift parallel zu den langen Seiten aus, bei Sendungen im Hochformat parallel zu den schmalen Seiten.',
              'Die Ruhezone rund um die Aufschrift kann sowohl hell als auch dunkel gestaltet werden.',
              'Neben der Zustellanschrift und einer einzeiligen Absenderangabe oberhalb der Anschrift dürfen keine weiteren zustellfähigen Angaben innerhalb der Lesezone gemacht werden.',
            ],
          },
          footer:
            'Hilfe zu den Prüfschritten finden Sie, indem Sie auf das Hilfe-Symbol (Fragezeichen oben rechts auf der Seite) klicken.',
        },
      },
      'step-three': {
        specify: {
          'franking-placement': 'Platzierung der Frankierung',
          'sender-placement': 'Platzierung des Absenders',
        },
        FRANKING_ZONE: 'Innerhalb der Frankierzone',
        AUFSCHRIFT: 'Innerhalb der Aufschrift',
        AUFSCHRIFT_SENDER: 'Innerhalb der Aufschrift',
        FRANKING_ZONE_AND_AUFSCHRIFT: 'In beiden Bereichen',
        SENDER_ZONE: 'Innerhalb der Absenderzone',
        NONE: 'Ohne Absender',
        'important-instructions': {
          FRANKING_ZONE: {
            title: 'Wichtige Hinweise zu "Innerhalb der Frankierzone"',
            list: [
              'Die Frankierzone muss sich auf der Aufschriftseite in der rechten oberen Ecke der Sendung befinden. Sie hat die Maße 74 mm in der Länge und 40 mm in der Breite.',
              'Die Frankierzone ist ausschließlich für die Frankierung der Sendungen vorgesehen. Es dürfen nur von der Deutschen Post zugelassene Frankierarten verwendet werden. Weitere postalische Vermerke oder Servicehinweise der Deutschen Post dürfen in dieser Zone nicht angebracht werden.',
              'Der Hintergrund der Frankierzone muss in Weiß oder in einem einfarbigen Pastellton gestaltet werden.',
            ],
          },
          AUFSCHRIFT: {
            title: 'Wichtige Hinweise zu "Innerhalb der Aufschrift"',
            list: [
              'Wenn Sie Frankiervermerke nutzen, bei denen die Frankierzone frei bleiben kann (wie z. B. bei DV-Freimachung), dürfen Sie die gesamte Fläche der Frankierzone farbig gestalten.',
              'Frei stehende Grafiken, wie Logos oder Störer, sind in der Frankierzone erlaubt. Sie sollten jedoch nicht in rechteckiger Form oder mit einem rechteckigen Rahmen dargestellt werden.',
              'Alphanumerische Angaben sind ebenfalls möglich, müssen jedoch ausschließlich im Negativdruck erfolgen.',
            ],
          },
          FRANKING_ZONE_AND_AUFSCHRIFT: {
            title: 'Wichtige Hinweise zu "In beiden Bereichen"',
            list: [
              'Alle Teile des Frankiervermerks müssen auf der Aufschriftseite sichtbar sein, einschließlich der Frankierwelle, der Produktangabe und des Hinweises auf die Deutsche Post.',
              'Die Frankierzone befindet sich auf der Aufschriftseite in der rechten oberen Ecke der Sendung und hat die Maße 74 mm in der Länge und 40 mm in der Breite. Diese Zone ist ausschließlich für die Frankierung vorgesehen und darf nur mit von der Deutschen Post zugelassenen Frankierarten genutzt werden. Andere postalische Vermerke oder Servicehinweise der Deutschen Post dürfen in dieser Zone nicht angebracht werden.',
              'Innerhalb der Aufschrift sind frei stehende Grafiken, wie Logos oder Störer, erlaubt, solange sie nicht rechteckig sind oder mit einem rechteckigen Rahmen versehen werden. Alphanumerische Angaben sind ebenfalls möglich, jedoch nur im Negativdruck.',
            ],
          },
          SENDER_ZONE_SENDER: {
            title: 'Wichtige Hinweise zu "Innerhalb der Absenderzone"',
            list: [
              'Die Absenderzone befindet sich auf der Aufschriftseite in der linken oberen Ecke der Sendung. Sie ist 40 mm breit und erstreckt sich bis 74 mm vor dem rechten Rand der Sendung.',
              'Bitte platzieren Sie Ihre Absenderangabe stets innerhalb dieser Absenderzone.',
            ],
          },
          AUFSCHRIFT_SENDER: {
            title: 'Wichtige Hinweise zu "Innerhalb der Aufschrift"',
            list: [
              'Wenn Sie die Absenderangabe innerhalb der Aufschrift anbringen möchten, platzieren Sie diese bitte einzeilig oberhalb der Anschrift und verwenden Sie eine deutlich kleinere Schriftgröße.',
            ],
          },
          NONE_SENDER: {
            title: 'Wichtige Hinweise zu "Ohne Absender"',
            list: [
              'Für die Bearbeitung einer Rücksendung ist es notwendig, die Absender- bzw. Rücksendeangabe auf der Aufschriftseite anzubringen.',
            ],
          },
        },
        infobox: {
          title: 'Allgemeine Hinweise zur Frankierung und Absender',
          list: [
            'Für alle detaillierten Vorgaben zur Frankierzone schauen Sie bitte in die Kapitel 3.2 und 5.2 des Leitfadens <0>Automationsfähige Briefsendungen</0> sowie in das <1>Frankiervermerke</1>. Achten Sie zusätzlich auf die spezifischen Anforderungen der von Ihnen gewählten Frankierart.',
            'Weitere Informationen zur Gestaltung der Absenderangaben finden Sie in den Kapiteln 3.3 und 5.3 des Leitfadens <0>Automationsfähige Briefsendungen</0>.',
          ],
          footer:
            'Hilfe zu den Prüfschritten finden Sie, indem Sie auf das Hilfe-Symbol (Fragezeichen oben rechts auf der Seite) klicken.',
        },
      },
      'step-four.specify.select-pdf': 'PDF auswählen',
      'step-four.important-instructions': {
        title: 'Wichtige Hinweise',
        dataprotection: {
          title: 'Datenschutz:',
          details: {
            0: 'Mit dem Upload bestätigen Sie, dass das PDF keine personenbezogenen Daten gemäß der Datenschutz-Grundverordnung (DSGVO) enthält.',
            1: 'Der Dienst verwendet zur Vermessung Ihrer übermittelten Daten Methoden des maschinellen Lernens (KI). Ihre Daten verbleiben ausschließlich auf den IT-Systemen der Deutschen Post AG.',
          },
        },
        recipientaddress:
          '<b>Testaufschrift:</b> Bitte nutzen Sie für die Aufschrift des Layouts unsere bereitgestellte <l>Testaufschrift</l>.',
      },
      'step-four.infobox': {
        title: 'Hinweise zum PDF Upload',
        list: [
          'Bitte laden Sie ein <b>einseitiges PDF hoch</b>, das nur Ihr Layout in einfacher Ausführung und guter Qualität zeigt.',
          'Das hochgeladene PDF muss der Aufschriftseite Ihres Werbemittels entsprechen.',
          '<b>Integrieren Sie bitte die bereitgestellte Testaufschrift inkl. Frankierung in Ihr Layout.</b> Die Testaufschrift finden Sie <l>hier</l>. Es ist wichtig, dass Sie die Aufschrift genauso, wie sie dargestellt sind, einschließlich der Zeile "An„ verwenden. Ansonsten kann die Maschinenlesbarkeit inkl. Ruhezonen nicht geprüft werden.​​',
          'Bei Briefhüllen mit Fenster muss der <b>Fensterausschnitt (Stanzlinie)</b> im Layout in <b>Magenta</b> dargestellt werden.',
          'Stellen Sie sicher, dass alle Schriftarten eingebettet sind.',
        ],
        footer:
          'Hilfe zu den Prüfschritten finden Sie, indem Sie auf das Hilfe-Symbol (Fragezeichen oben rechts auf der Seite) klicken.',
      },
      'step-five.specify.result': 'Prüfergebnis',
      'step-five.alert.checking': 'wird geprüft',
      'step-five.alert.not-automation-capable.title': 'Das Layout ist NICHT automationsgerecht.',
      'step-five.alert.automation-capable.title': 'Automationsfähig:',
      'step-five.alert.automation-capable': 'Die Sendung ist automationsgerecht gestaltet und maschinell lesbar.',
      'step-five.ok': 'In Ordnung',
      'step-five.please-check': 'Bitte prüfen',
      'step-five.malwareAlert.title': 'Malware gefunden',
      'step-five.malwareAlert.message':
        'Malware gefunden: Die Datei wurde als Malware erkannt. Bitte laden Sie eine saubere Datei hoch.',
      'step-five.malwareAlert.causeTitle': 'Wie kommt es zu einem Malware-Fehler?',
      'step-five.malwareAlert.causeDescription':
        'Ein Malware-Fehler tritt auf, wenn eine Datei bösartige Software (Malware) enthält, die schädlich für Ihren Computer oder das Netzwerk sein kann. Dies kann verschiedene Ursachen haben:',
      'step-five.malwareAlert.cause1':
        'Infizierte Datei: Die Datei wurde möglicherweise auf einem infizierten Computer erstellt oder bearbeitet.',
      'step-five.malwareAlert.cause2':
        'Heruntergeladene Datei: Dateien die aus unsicheren Quellen im Internet heruntergeladen wurden, können Malware enthalten.',
      'step-five.malwareAlert.cause3':
        'Manipulierte Datei: Die Datei könnte absichtlich oder unbeabsichtigt von einem Dritten manipuliert worden sein, um schädliche Software zu verbreiten',
      'step-five.malwareAlert.solution':
        'Stellen Sie sicher, dass die Datei von einer vertrauenswürdigen Quelle stammt und führen Sie einen Virenscan durch, bevor sie die Datei erneut hochladen',
      'step-five.print': 'Fehlerbericht drucken',
      'step-five.preview': 'Layoutvorschau',
      'step-five.info':
        'Bitte legen Sie bei der Einlieferung von Sendungen des genannten Sendungskonzeptes einen Ausdruck aller Seiten dieses Prüfberichts der Einlieferungsliste DIALOGPOST als Nachweis der erfolgreichen Prüfung bei.',
      'step-five.important': 'Wichtiger Hinweis',
      'step-five.preview-image-error': 'Die Vorschau konnte nicht geladen werden.',
      'step-five.process-number': 'Vorgangsnummer',
      'dashboard.my-checks': 'Meine Prüfungen',
      'dashboard.description.important': 'Wichtig: ',
      'dashboard.description.details-1':
        'Jede Sendung wird auf die korrekte Gestaltung der Aufschriftseiten überprüft.',
      'dashboard.description.details-2':
        'Bitte nutzen Sie für die Empfängeradresse des Layouts unsere bereitgestellten ',
      'dashboard.description.details-3': 'Testaufschrift.',
      'dashboard.start-new-check': 'Neue Prüfung starten',
      'dashboard.projectNumber': 'Projektnummer',
      'dashboard.projectName': 'Name der Prüfung',
      'dashboard.recordsPerPage': 'Anzahl der Prüfungen pro Seite',
      'dashboard.projectState': 'Status',
      'dashboard.validUntil': 'Gültig bis',
      'dashboard.on': 'am',
      'dashboard.deletion': 'Löschung am',
      'dashboard.productType': 'Produkt',
      'dashboard.basisFormat': 'Basisformat',
      'dashboard.updated': 'Zuletzt geändert am',
      'dashboard.downloadCheckReport': 'Prüfbericht herunterladen',
      'dashboard.downloadErrorReport': 'Fehlerbericht herunterladen',
      'dashboard.checkAgain': 'Erneut prüfen',
      'dashboard.copyAndapplySettings': 'Daten & Einstellungen für neue Prüfung übernehmen',
      'dashboard.deleteCheck': 'Prüfung löschen',
      'dashboard.NameOfCheck': 'Name der Prüfung',
      'dashboard.overtake': 'Übernehmen',
      'dashboard.cancel': 'Abbrechen',
      'dashboard.confirmDeleteTitle': 'Möchten Sie die Prüfung {{testName}} wirklich löschen?',
      'dashboard.confirmDeleteMessage':
        'Wenn Sie diese Prüfung löschen, werden der Prüfbericht und die Layoutdatei unwiderruflich gelöscht.',
      'dashboard.deleteTest': 'Prüfung löschen',
      'dashboard.yes': 'Ja',
      'dashboard.search': 'Name der Prüfung',
      'dashboard.startDate': 'Startdatum',
      'dashboard.endDate': 'Enddatum',
      'dashboard.product': 'Produkt',
      'dashboard.dateFormat': 'DD.MM.YYYY',
      'dashboard.state': 'Status',
      'dashboard.createdByFamilyName': 'Nachname',
      'dashboard.name': 'Name',
      'helpButton.mouseover': 'Hier klicken, um die Hilfe aufzurufen',
      'errorHelpButton.mouseover': 'Für weitere Infos hier klicken',
      'optionsButton.mouseover': 'Mehr Optionen',
      intlDateTime: '{{val, datetime}}',
      DIALOGPOST: 'Dialogpost',
      POSTWURFSPEZIAL: 'Postwurfspezial',
      BRIEF: 'Brief',
      POSTKARTE: 'Postkarte',
      WERBEANTWORT: 'Werbeantwort',
      RESPONSEPLUS: 'Responseplus',
      WARENSENDUNG: 'Warensendung',
      STANDARD: 'Standard',
      KOMPAKT: 'Kompakt',
      GROSS: 'Groß',
      MAXI: 'Maxi',
      PENDING: 'Noch nicht geprüft',
      FAILED: 'Fehlgeschlagen',
      RUNNING: 'Prüfung läuft',
      DONE_FAILED: 'Nicht automationsfähig',
      DONE_SUCCEEDED: 'Automationsfähig',
      CLOSED: 'Automationsfähig',
      EXPIRED: 'Abgelaufen',
    },
  },
};

export const initializeI18n = (language: string) => {
  i18n.use(initReactI18next).init({
    resources,
    lng: language,
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false,
    },
  });
};

export const changeLanguage = (language: string) => {
  i18n.changeLanguage(language);
};
