import { Flex, Loader } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { GhostButton, PrimaryButton } from './Buttons';
import { Notification } from './Notification';

export const ButtonBar = ({
  onNext,
  onCancel,
  nextButtonLabel,
  cancelButtonLabel,
  showOnlyCancelButton,
  isLoading,
  isNextButtonDisabled,
}: {
  onNext: () => void;
  onCancel: () => void;
  nextButtonLabel: React.ReactNode;
  cancelButtonLabel: React.ReactNode;
  showOnlyCancelButton?: boolean;
  isLoading?: boolean;
  isNextButtonDisabled: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Flex direction="column" mt="25px">
      {isNextButtonDisabled && <Notification text={t('fillMandatoryFields')} />}
      <Flex gap="sm" style={{ marginTop: '15px' }}>
        {showOnlyCancelButton ? (
          <PrimaryButton data-testid="cancel-button" onClick={onCancel}>
            {cancelButtonLabel}
          </PrimaryButton>
        ) : (
          <>
            <PrimaryButton data-testid="next-button" disabled={isNextButtonDisabled || isLoading} onClick={onNext}>
              {isLoading ? <Loader size="sm" color="var(--BRAND-DHL-Red)" /> : nextButtonLabel}
            </PrimaryButton>
            <GhostButton data-testid="cancel-button" onClick={onCancel}>
              {cancelButtonLabel}
            </GhostButton>
          </>
        )}
      </Flex>
    </Flex>
  );
};
