import { ExternalRouter } from './ExternalRouter';
import { AppProviders } from './AppProviders';
import { AuthenticationManager } from '@gkuis/gkp-authentication';

export type RootProps = {
  authenticationManager?: AuthenticationManager;
  ekp?: string;
};

const basename = process.env.BASENAME ? '/ac-web/' : undefined;

export const DefaultEntryPoint = ({ authenticationManager, ekp }: RootProps) => (
  <AppProviders authenticationManager={authenticationManager} ekp={ekp} basename={basename}>
    <ExternalRouter basename={basename} />
  </AppProviders>
);
