interface LetterWithoutWindowStandardProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const LetterWithoutWindowStandard = ({ size, style, ...others }: LetterWithoutWindowStandardProps) => (
  <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M59.7098 24.3936H12.79V47.6068H59.7098V24.3936Z" fill="white" />
    <path
      d="M47.8196 28.3799H55.161C55.3103 28.3799 55.4534 28.4392 55.5589 28.5447C55.6644 28.6502 55.7237 28.7933 55.7237 28.9425V32.4735C55.7237 32.6228 55.6644 32.7661 55.5588 32.8716C55.4532 32.9772 55.31 33.0366 55.1607 33.0366H47.8196C47.6703 33.0366 47.5271 32.9772 47.4215 32.8716C47.3159 32.7661 47.2566 32.6228 47.2566 32.4735V28.9429C47.2566 28.7936 47.3159 28.6504 47.4215 28.5448C47.5271 28.4392 47.6703 28.3799 47.8196 28.3799Z"
      fill="#FFCC00"
    />
    <path
      d="M59.3934 23.2998H13.1066C12.7329 23.3003 12.3746 23.449 12.1103 23.7132C11.8461 23.9775 11.6974 24.3358 11.6969 24.7095V47.2901C11.6973 47.6639 11.8459 48.0222 12.1102 48.2865C12.3745 48.5508 12.7328 48.6994 13.1066 48.6998H59.3939C59.7676 48.6993 60.1258 48.5507 60.39 48.2864C60.6542 48.0221 60.8028 47.6638 60.8031 47.2901V24.7095C60.8027 24.3358 60.654 23.9774 60.3897 23.7132C60.1254 23.4489 59.7671 23.3002 59.3934 23.2998ZM59.1097 47.0065H13.3897V24.9931H59.1097V47.0065Z"
      fill="black"
    />
    <path
      d="M40.2955 40.522H55.146C55.3661 40.5204 55.5767 40.4319 55.7318 40.2757C55.8868 40.1195 55.9739 39.9083 55.9739 39.6882C55.9739 39.4682 55.8868 39.257 55.7318 39.1008C55.5767 38.9446 55.3661 38.8561 55.146 38.8545H40.2955C40.1855 38.8537 40.0765 38.8747 39.9746 38.9163C39.8728 38.9578 39.7802 39.0191 39.7021 39.0966C39.6241 39.1741 39.5621 39.2663 39.5198 39.3678C39.4776 39.4694 39.4558 39.5783 39.4558 39.6882C39.4558 39.7982 39.4776 39.9071 39.5198 40.0087C39.5621 40.1102 39.6241 40.2024 39.7021 40.2799C39.7802 40.3574 39.8728 40.4187 39.9746 40.4602C40.0765 40.5018 40.1855 40.5228 40.2955 40.522Z"
      fill="black"
    />
    <path
      d="M40.2955 43.898H55.146C55.3661 43.8964 55.5767 43.8079 55.7318 43.6517C55.8868 43.4955 55.9739 43.2843 55.9739 43.0642C55.9739 42.8441 55.8868 42.633 55.7318 42.4768C55.5767 42.3206 55.3661 42.2321 55.146 42.2305H40.2955C40.1855 42.2297 40.0765 42.2507 39.9746 42.2922C39.8728 42.3338 39.7802 42.3951 39.7021 42.4726C39.6241 42.5501 39.5621 42.6422 39.5198 42.7438C39.4776 42.8453 39.4558 42.9542 39.4558 43.0642C39.4558 43.1742 39.4776 43.2831 39.5198 43.3847C39.5621 43.4862 39.6241 43.5784 39.7021 43.6559C39.7802 43.7334 39.8728 43.7947 39.9746 43.8362C40.0765 43.8778 40.1855 43.8987 40.2955 43.898Z"
      fill="black"
    />
    <path
      d="M40.2955 37.1472H55.1461C55.3641 37.1426 55.5716 37.0527 55.7242 36.8968C55.8768 36.741 55.9622 36.5316 55.9622 36.3135C55.9622 36.0954 55.8768 35.8859 55.7242 35.7301C55.5716 35.5742 55.3641 35.4843 55.1461 35.4797H40.2955C40.1845 35.4773 40.0742 35.4971 39.9709 35.538C39.8677 35.5788 39.7737 35.6398 39.6943 35.7175C39.615 35.7951 39.552 35.8879 39.5089 35.9902C39.4659 36.0925 39.4437 36.2024 39.4437 36.3135C39.4437 36.4245 39.4659 36.5344 39.5089 36.6367C39.552 36.7391 39.615 36.8318 39.6943 36.9094C39.7737 36.9871 39.8677 37.0481 39.9709 37.089C40.0742 37.1298 40.1845 37.1496 40.2955 37.1472Z"
      fill="black"
    />
  </svg>
);
