interface CheckIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const CheckIconWithCircle = ({ size, style, color, ...others }: CheckIconProps) => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 1.62549C13.1355 1.62549 16.5 4.98999 16.5 9.12549C16.5 13.261 13.1355 16.6255 9 16.6255C4.8645 16.6255 1.5 13.261 1.5 9.12549C1.5 4.98999 4.8645 1.62549 9 1.62549ZM9 0.125488C4.02975 0.125488 0 4.15524 0 9.12549C0 14.0957 4.02975 18.1255 9 18.1255C13.9703 18.1255 18 14.0957 18 9.12549C18 4.15524 13.9703 0.125488 9 0.125488ZM13.6875 6.79374L12.6217 5.73699L8.043 10.4005L5.7345 8.19774L4.6875 9.27474L8.0625 12.514L13.6875 6.79374Z"
      fill={color || '#3C763D'}
    />
  </svg>
);
