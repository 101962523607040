import styled from '@emotion/styled';
import { Stepper, StepperProps } from '@mantine/core';
import { WizardStepperStep } from './WizardStepperStep';
import { useFormContext } from '../../hooks/useFormContext';
import { useCurrentStep } from '../../hooks/useCurrentStep';
import { useNavigateWithSearchParams } from '../../hooks/useNavigateWithSearchParams';
import { useAuthenticationManager } from '../../hooks/useAuthenticationManager';

interface WizardStepperProps {
  steps: string[];
}

export const WizardStepper = ({ steps }: WizardStepperProps) => {
  const { navigateAndKeepSearchParams: navigate } = useNavigateWithSearchParams();
  const { isInternal } = useAuthenticationManager();
  const { form } = useFormContext();
  const { step } = useCurrentStep();
  const active = step - 1;
  return (
    <WizardStepperBase
      onStepClick={(step) => {
        navigate(`/wizard/${form.values.projectNumber}/${step + 1}`);
      }}
      active={active}
    >
      {steps.map((step, index) => (
        <WizardStepperStep
          key={index}
          label={step}
          completedIcon={index + 1}
          allowStepSelect={!isInternal && active > index && form.values?.projectState !== 'CLOSED'}
        />
      ))}
    </WizardStepperBase>
  );
};

const WizardStepperBase = styled(Stepper)<StepperProps>`
  color: var(--BRAND-DHL-Red);
  height: 100px;
  margin-left: 200px;
  margin-right: 200px;

  & .mantine-Stepper-separator {
    color: var(--GRAY-OPAC-Gray-O-400);
    height: 3px;
    margin-inline: 0;
  }

  & .mantine-Stepper-separator[data-active='true'] {
    background-color: var(--SECONDARY-Green-O-500);
  }

  & .mantine-Stepper-step {
    flex-direction: column;
  }
`;
