interface ThreeDotsProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const ThreeDots = ({ size = 26, ...props }: ThreeDotsProps) => (
  <svg width={size} height={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0007 14.1667C13.645 14.1667 14.1673 13.6443 14.1673 13C14.1673 12.3557 13.645 11.8333 13.0007 11.8333C12.3563 11.8333 11.834 12.3557 11.834 13C11.834 13.6443 12.3563 14.1667 13.0007 14.1667ZM13.0007 18.25C13.645 18.25 14.1673 17.7277 14.1673 17.0833C14.1673 16.439 13.645 15.9167 13.0007 15.9167C12.3563 15.9167 11.834 16.439 11.834 17.0833C11.834 17.7277 12.3563 18.25 13.0007 18.25ZM13.0007 10.0833C13.645 10.0833 14.1673 9.561 14.1673 8.91667C14.1673 8.27233 13.645 7.75 13.0007 7.75C12.3563 7.75 11.834 8.27233 11.834 8.91667C11.834 9.561 12.3563 10.0833 13.0007 10.0833Z"
      fill="#D40511"
    />
  </svg>
);
