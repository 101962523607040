import { useTranslation } from 'react-i18next';
import { useProductQueryInfoContext } from './useProductInfo';

export const useValidation = () => {
  const { t } = useTranslation();

  const { getLongSideMinMaxForDialogpost, getShortSideMinMaxForDialogpost } = useProductQueryInfoContext();

  const validateName = (value: string) => {
    if (value.trim() === '') {
      return t('step-one.input.cannot-be-empty');
    }
    if (value.length > 50) {
      return t('step-one.input.too-long');
    }
    if (!/^[ A-Za-z0-9_äöüÄÖÜßẞ-]+$/.test(value)) {
      return t('step-one.input.invalid-characters');
    }
    return null;
  };

  const validateLongSideInMm = (value: number | '') => {
    if (!value) return t('step-one.input.cannot-be-empty');
    const { min, max } = getLongSideMinMaxForDialogpost();
    if (value < min || value > max) {
      return t('step-one.input.invalid-length', { min, max });
    }
    return null;
  };

  const validateShortSideInMm = (value: number | '') => {
    if (!value) return t('step-one.input.cannot-be-empty');
    const { min, max } = getShortSideMinMaxForDialogpost();
    if (value < min || value > max) {
      return t('step-one.input.invalid-width', { min, max });
    }
    return null;
  };

  return { validateName, validateLongSideInMm, validateShortSideInMm };
};
