interface InfoIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const InfoIcon = ({ size, style, color, ...others }: InfoIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_4044_6552)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00002 1.33313C11.676 1.33313 14.6669 4.32403 14.6669 8.00002C14.6669 11.676 11.676 14.6669 8.00002 14.6669C4.32399 14.6669 1.33309 11.676 1.33309 8.00002C1.33309 4.32403 4.32399 1.33313 8.00002 1.33313ZM8.00002 0C3.58229 0 0 3.58172 0 8.00002C0 12.4183 3.58229 16 8.00002 16C12.4177 16 16 12.4183 16 8.00002C16 3.58172 12.4177 0 8.00002 0ZM7.9994 3.83312C8.445 3.8534 8.7959 4.2205 8.7959 4.66663C8.7959 5.11261 8.445 5.47983 7.9994 5.50003C7.5391 5.50003 7.16601 5.12689 7.16601 4.66663C7.16601 4.20633 7.5391 3.83312 7.9994 3.83312ZM9.33311 12H6.66693V11.3331C6.9891 11.2143 7.33309 11.1994 7.33309 10.8434V7.86512C7.33309 7.50911 6.9891 7.4537 6.66693 7.3337V6.66743H8.66691V10.844C8.66691 11.2006 9.0114 11.216 9.33311 11.3337V12Z"
        fill={color || '#878787'}
      />
    </g>
    <defs>
      <clipPath id="clip0_4044_6552">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
