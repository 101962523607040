import styled from '@emotion/styled';
import { Select, SelectProps } from '@mantine/core';
import { ArrowDown } from '../Icons/ArrowDown';

export const StyledSelect = styled(Select)`
  position: relative;
  & .mantine-Select-label {
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    position: absolute;
    top: ${(props) => (props.value ? '5px' : '15px')};
    left: 12px;
    z-index: 1;
    color: ${(props) => (props.error ? 'var(--BRAND-DHL-Red)' : 'var(--GRAY-ALPHA-Gray-A-600)')};
  }
  &:focus-within .mantine-Select-label {
    top: 5px;
    transition: top 0.125s ease-in-out;
  }
  & .mantine-Select-required {
    color: ${(props) => (props.error ? 'var(--BRAND-DHL-Red)' : 'var(--GRAY-ALPHA-Gray-A-600)')};
  }
  & .mantine-Select-input {
    padding-top: 13px;
    &:focus {
      border: ${(props) => (props.error ? '2px solid var(--BRAND-DHL-Red)' : '2px solid var(--BASIC-Black)')};
    }
    &:disabled {
      border: 1px solid var(--GRAY-ALPHA-Gray-A-100);
      background: var(--GRAY-OPAC-Gray-O-100);
      opacity: 1;
    }
  }
`;

export const ArrowDownSelect = (props: SelectProps) => (
  <StyledSelect
    {...props}
    clearable={true}
    rightSection={props.value ? null : <ArrowDown style={{ color: 'var(--GRAY-OPAC-Gray-O-900)' }} />}
    value={props.value ?? null}
  />
);
