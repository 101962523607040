interface CheckIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const CheckIcon = ({ size, style, color, ...others }: CheckIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 4.4L8.4 14L6 16.4L3.6 14L0 10.4L2.4 8L6 11.6L15.6 2L18 4.4Z"
      fill={color || '#3C763D'}
    />
  </svg>
);
