import { Alert, AlertProps, Loader } from '@mantine/core';
import styled from '@emotion/styled';
import { CheckIcon } from '../Icons/CheckIcon';
import React from 'react';

export enum StyledAlertStatus {
  success = 'success',
  error = 'error ',
  info = 'info',
}

interface StyledAlertBaseProps extends AlertProps {
  status: StyledAlertStatus;
  showLoader: boolean;
  children: React.ReactNode;
}

export const StyledAlert = ({ status, showLoader, children, ...props }: StyledAlertBaseProps) => {
  return (
    <BaseAlert status={status} showLoader={showLoader} {...props}>
      <StyledDivContainer>
        {children}
        {showLoader && (
          <StatusIconContainer>
            <Loader size={20} color="var(--BRAND-DHL-Red)" />
          </StatusIconContainer>
        )}
        {status === StyledAlertStatus.success && (
          <StatusIconContainer>
            <CheckIcon size={20} color="var(--SECONDARY-Green-O-900)" />
          </StatusIconContainer>
        )}
      </StyledDivContainer>
    </BaseAlert>
  );
};

const StatusIconContainer = styled.div`
  display: flex;
  position: absolute;
  right: 20px;
`;

const StyledDivContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const BaseAlert = styled(Alert, {
  shouldForwardProp: (prop) => !['showLoader'].includes(prop),
})<StyledAlertBaseProps>`
  --alert-radius: 8px;

  color: ${(props) => getColorByStatus(props.status).color};
  background: ${(props) => getColorByStatus(props.status).background};
  margin-top: 10px;
  padding: 14px 20px;
  flex-grow: 1;

  & .mantine-Alert-icon {
    margin-inline-end: 10px;
    margin-top: 0;
  }

  & .mantine-Alert-body {
    flex-direction: unset;
    gap: 6px;
  }

  & .mantine-Alert-title {
    padding-inline-end: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 50%;
  }

  & .mantine-Alert-message {
    color: ${(props) => getColorByStatus(props.status).color};
  }
`;

const getColorByStatus = (status: StyledAlertStatus) => {
  if (status === StyledAlertStatus.error) {
    return {
      color: 'var(--BRAND-DHL-Red)',
      background: 'var(--SECONDARY-Red-O-100)',
    };
  } else if (status === StyledAlertStatus.success) {
    return {
      color: 'var(--SECONDARY-Green-O-900)',
      background: 'var(--SECONDARY-Green-O-100)',
    };
  } else {
    return {
      color: 'var(--GRAY-OPAC-Gray-O-900)',
      background: 'var(--GRAY-OPAC-Gray-O-100)',
    };
  }
};
