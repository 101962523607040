import { useNavigateWithSearchParams } from './useNavigateWithSearchParams';

export const useGoToDashboard = () => {
  const { navigateAndKeepSearchParams: navigate } = useNavigateWithSearchParams();

  const goToDashboard = () => {
    const searchParams = sessionStorage.getItem('ac-searchParams');

    navigate({
      pathname: '/dashboard',
      search: searchParams ? `?${searchParams}` : '',
    });
  };

  return goToDashboard;
};
