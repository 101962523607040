import { useEffect } from 'react';
import { useCurrentStep } from './useCurrentStep';
import { useWizardProgress } from './useWizardProgress';
import { useFormContext } from './useFormContext';
import { useNavigateWithSearchParams } from './useNavigateWithSearchParams';

export const useWizardGuard = () => {
  const { step: currentStepFromUrl } = useCurrentStep();
  const { currentStepFromForm } = useWizardProgress();
  const { form, isProjectClosed, isQueryComplete, isError, isQueryEnabled } = useFormContext();
  const projectNumber = form.values.projectNumber;
  const { navigateAndKeepSearchParams: navigate } = useNavigateWithSearchParams();

  useEffect(() => {
    if (isQueryEnabled) {
      if (isQueryComplete) {
        if (isProjectClosed && currentStepFromUrl < 5) {
          navigate('/dashboard', { replace: true });
          return;
        }
        if (currentStepFromUrl > currentStepFromForm) {
          if (projectNumber !== '') {
            navigate(`/wizard/${projectNumber}/${currentStepFromForm}`, { replace: true });
          } else {
            navigate(`/wizard/1`, { replace: true });
          }
        }
      } else if (isError) {
        navigate('/wizard/1', { replace: true });
      }
    } else if (currentStepFromUrl > currentStepFromForm) {
      navigate('/wizard/1', { replace: true });
    }
  }, [
    isQueryComplete,
    currentStepFromForm,
    currentStepFromUrl,
    projectNumber,
    navigate,
    isError,
    isProjectClosed,
    isQueryEnabled,
  ]);
};
