interface PdfIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const PdfIcon = ({ size, style, ...others }: PdfIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
    <g clipPath="url(#clip0_4044_6567)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3708 0.5V1.81434H3.40462C3.09426 1.81434 2.84183 2.07268 2.84183 2.39001V8H15.1582V6.51827H16.5V16.6096C16.5 17.6519 15.6456 18.5 14.5954 18.5H3.40462C2.35436 18.5 1.5 17.6519 1.5 16.6096V2.39001C1.5 1.34776 2.35436 0.5 3.40462 0.5H10.3708ZM15.1582 16.25H2.84183V17.1858C2.84183 17.3315 3.09426 17.45 3.40462 17.45H14.5954C14.9057 17.45 15.1582 17.3315 15.1582 17.1858V16.25ZM4.68983 9.5H3V14.75H4.29529V13.0795H4.68983C5.3201 13.0795 5.81141 12.9443 6.16377 12.6739C6.4841 12.428 6.65882 12.0463 6.68794 11.5287L6.69231 11.3693V11.2102C6.69231 10.611 6.51613 10.1761 6.16377 9.90568C5.84665 9.66227 5.41697 9.5284 4.87474 9.50406L4.68983 9.5ZM9.05955 9.5H7.30273V14.75H9.05955C9.72457 14.75 10.2395 14.5458 10.6042 14.1375C10.9386 13.7632 11.1218 13.2285 11.1538 12.5333L11.1588 12.3398V11.9102C11.1588 11.1201 10.9752 10.5208 10.6079 10.1125C10.2713 9.73819 9.80955 9.53545 9.2227 9.50425L9.05955 9.5ZM15 9.5H11.9181V14.75H13.2134V12.8568H14.7916V11.7114H13.2134V10.6852H15V9.5ZM9.02978 10.6852C9.31762 10.6852 9.5273 10.786 9.65881 10.9875C9.77388 11.1638 9.83861 11.4335 9.853 11.7966L9.85608 11.958V12.292C9.85112 12.7375 9.78288 13.061 9.65137 13.2625C9.51985 13.464 9.31265 13.5648 9.02978 13.5648H8.59801V10.6852H9.02978ZM4.7196 10.6136C4.92804 10.6136 5.09181 10.6574 5.21092 10.7449C5.31301 10.8199 5.37135 10.9524 5.38593 11.1423L5.38958 11.242V11.3614C5.38958 11.5894 5.33002 11.7472 5.21092 11.8347C5.09181 11.9222 4.92804 11.9659 4.7196 11.9659H4.29529V10.6136H4.7196ZM11.4604 0.511987L16.4862 5.51901H12.9093C12.1094 5.51901 11.4604 4.88327 11.4604 4.09972V0.511987Z"
        fill="#323232"
      />
    </g>
    <defs>
      <clipPath id="clip0_4044_6567">
        <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
