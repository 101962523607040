import { BasisformatProduktDto } from './../analyzer-api/requests';
import { useFormContext } from './useFormContext';
import { useProductQueryInfoContext } from './useProductInfo';

export const useLetterType = () => {
  const { form } = useFormContext();
  const { getStandardAndGrossFromDialogpost } = useProductQueryInfoContext();
  const { standard, gross } = getStandardAndGrossFromDialogpost();

  const checkLetterSize = (specs: BasisformatProduktDto, values: typeof form.values) => {
    const { longSideInMm, shortSideInMm, thicknessInMm, weightInG } = values;
    if (!longSideInMm || !shortSideInMm || !thicknessInMm || !weightInG) return false;

    return (
      longSideInMm >= specs.minLongSideInMM &&
      longSideInMm <= specs.maxLongSideInMM &&
      shortSideInMm >= specs.minShortSideInMM &&
      shortSideInMm <= specs.maxShortSideInMM &&
      thicknessInMm <= specs.maxThicknessInMM &&
      weightInG <= specs.maxWeightInG &&
      longSideInMm / shortSideInMm >= specs.minAspectRatio
    );
  };

  const isStandardLetter = checkLetterSize(standard, form.values);
  const isLargeLetter = !isStandardLetter && checkLetterSize(gross, form.values);

  return { isStandardLetter, isLargeLetter };
};
