import { AlertProps, Button, ButtonProps, Flex } from '@mantine/core';
import styled from '@emotion/styled';
import { FileRejection } from '@mantine/dropzone';
import { useTranslation } from 'react-i18next';
import { DeleteIcon } from '../Icons/DeleteIcon';
import { MouseEventHandler, useMemo } from 'react';
import { ErrorIcon } from '../Icons/ErrorIcon';
import { SuccessIcon } from '../Icons/SuccessIcon';
import { StyledAlert, StyledAlertStatus } from '../Alert/StyledAlert';

interface SingleFileUploadAlertProps extends AlertProps {
  fileRejection?: FileRejection;
  deleteUpload: MouseEventHandler<HTMLButtonElement>;
  isUploadDocumentPending: boolean;
}

export const SingleFileUploadAlert = ({
  fileRejection,
  deleteUpload,
  title,
  isUploadDocumentPending,
}: SingleFileUploadAlertProps) => {
  const { t } = useTranslation();

  const icon = useMemo(() => getIconByStatus(fileRejection), [fileRejection]);
  const text = useMemo(() => t(getTextByStatus(fileRejection)), [fileRejection, t]);
  const fileRejectionCode = useMemo(() => `file-upload-alert.${fileRejection?.errors[0].code}` || '', [fileRejection]);
  const hasFileRejection = fileRejection !== undefined;

  return (
    <>
      <Flex gap={12} justify="flex-start" align="center" direction="row" wrap="nowrap">
        <StyledAlert
          icon={icon}
          title={title}
          status={
            hasFileRejection
              ? StyledAlertStatus.error
              : isUploadDocumentPending
                ? StyledAlertStatus.info
                : StyledAlertStatus.success
          }
          showLoader={isUploadDocumentPending}
        >
          {text}
        </StyledAlert>
        <ClickableAlertCloseButton onClick={deleteUpload} />
      </Flex>
      {hasFileRejection && <StyledAlertText>{t(fileRejectionCode)}</StyledAlertText>}
    </>
  );
};

interface ClickableAlertCloseButtonProps extends ButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const ClickableAlertCloseButton: React.FC<ClickableAlertCloseButtonProps> = ({ onClick }) => (
  <AlertCloseButton size="sm" variant="transparent" onClick={onClick}>
    <DeleteIcon />
  </AlertCloseButton>
);

const AlertCloseButton = styled(Button)<ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  color: var(--BRAND-DHL-Red);
  padding: 10px 10px 0 0;
`;

const StyledAlertText = styled.p`
  color: var(--BRAND-DHL-Red);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  margin: 0;
  display: flex;
  padding: 5px 0 10px 0;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const getIconByStatus = (fileRejection: FileRejection | undefined) => {
  return fileRejection ? <ErrorIcon /> : <SuccessIcon />;
};

const getTextByStatus = (fileRejection: FileRejection | undefined) => {
  return fileRejection ? 'file-upload-alert.not-usable' : 'file-upload-alert.selected';
};
