interface PlusIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const PlusIcon = ({ size, style, color, ...others }: PlusIconProps) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: size, height: size, ...style }}
    {...others}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00001 14C3.134 14 0 10.866 0 6.99998C0 3.134 3.134 0 7.00001 0C10.866 0 14 3.134 14 6.99998C14 10.866 10.866 14 7.00001 14ZM6.3 6.3H2.80001V7.7H6.3V11.2H7.7V7.7H11.2V6.3H7.7V2.79998H6.3V6.3Z"
      fill={color || 'white'}
    />
  </svg>
);
