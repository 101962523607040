import React, { useEffect, useState } from 'react';
import { Container, Flex, TextInput, Title } from '@mantine/core';
import { useAuthenticationManager } from '../hooks/useAuthenticationManager';
import { MockAuthenticationManager } from '../mocks/mockAuthenticationManager';
import { DefaultButton, PrimaryButton } from '../components/Buttons/Buttons';
import { basePath } from '../config/basePath';
import { useNavigateWithSearchParams } from '../hooks/useNavigateWithSearchParams';

export const User: React.FC = () => {
  const [tokenData, setTokenData] = useState({
    scope: '',
    ekp: '',
    givenName: '',
    familyName: '',
    email: '',
    username: '',
  });
  const [expirationTime, setExpirationTime] = useState(60);
  const mockAuthenticationManager = useAuthenticationManager() as MockAuthenticationManager;
  const { navigateAndKeepSearchParams: navigate } = useNavigateWithSearchParams();

  useEffect(() => {
    const loadTokenData = async () => {
      const parsedToken = await mockAuthenticationManager.getAccessTokenParsed();
      if (parsedToken) {
        setTokenData({
          scope: parsedToken.scope as string,
          ekp: parsedToken.ekp as string,
          givenName: parsedToken.given_name as string,
          familyName: parsedToken.family_name as string,
          email: parsedToken.email as string,
          username: parsedToken.username as string,
        });
      }
    };

    loadTokenData();
  }, [mockAuthenticationManager]);

  const handleGenerateJwt = async () => {
    mockAuthenticationManager.generateTokenAndStore(
      {
        ekp: tokenData.ekp,
        given_name: tokenData.givenName,
        family_name: tokenData.familyName,
        scope: tokenData.scope,
        email: tokenData.email,
        username: tokenData.username,
      },
      expirationTime,
    );

    window.location.href = `${basePath}/dashboard`;
  };

  const navigateToDashboard = () => {
    navigate(`/dashboard`);
  };

  return (
    <Container size="sm">
      <Title order={2} mb="lg" style={{ textAlign: 'center' }}>
        JWT Generator
      </Title>
      <TextInput
        label="Scope"
        value={tokenData.scope}
        onChange={(e) => setTokenData({ ...tokenData, scope: e.target.value })}
        mb="sm"
      />
      <TextInput
        label="EKP"
        value={tokenData.ekp}
        onChange={(e) => setTokenData({ ...tokenData, ekp: e.target.value })}
        mb="sm"
      />
      <TextInput
        label="Given Name"
        value={tokenData.givenName}
        onChange={(e) => setTokenData({ ...tokenData, givenName: e.target.value })}
        mb="sm"
      />
      <TextInput
        label="Family Name"
        value={tokenData.familyName}
        onChange={(e) => setTokenData({ ...tokenData, familyName: e.target.value })}
        mb="sm"
      />
      <TextInput
        label="Email"
        value={tokenData.email}
        onChange={(e) => setTokenData({ ...tokenData, email: e.target.value })}
        mb="sm"
      />
      <TextInput
        label="Username"
        value={tokenData.username}
        onChange={(e) => setTokenData({ ...tokenData, username: e.target.value })}
        mb="sm"
      />
      <TextInput
        label="Gültigkeit (Minuten)"
        type="number"
        value={expirationTime}
        onChange={(e) => setExpirationTime(Number(e.target.value))}
        mb="sm"
      />
      <Flex justify={'left'} mt="lg" gap="sm">
        <DefaultButton onClick={navigateToDashboard}>Zurück zum Dashboard</DefaultButton>
        <PrimaryButton onClick={handleGenerateJwt}>JWT erstellen und als Benutzer anmelden</PrimaryButton>
      </Flex>
    </Container>
  );
};
