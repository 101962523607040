import styled from '@emotion/styled';
import { StepperStep, StepperStepProps } from '@mantine/core';
import { EditIcon } from '../Icons/EditIcon';

interface CustomLabelProps {
  label: string;
  props: StepperStepProps;
}

export const WizardStepperStep = (props: StepperStepProps) => {
  const { label, ...rest } = props;
  return (
    <WizardStepperStepBase
      allowStepSelect={false}
      allowStepClick={false}
      label={<CustomLabel label={label as string} props={props} />}
      {...rest}
    />
  );
};

const WizardStepperStepBase = styled(StepperStep)<StepperStepProps>`
  color: var(--GRAY-OPAC-Gray-O-700);
  text-align: center;

  & .mantine-Stepper-stepIcon {
    color: ${(props) => (props.state === 'stepProgress' ? 'var(--BASIC-White)' : 'var(--GRAY-OPAC-Gray-O-700)')};
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    border: ${(props) =>
      props.state === 'stepProgress'
        ? '3px solid var(--BRAND-DHL-Red)'
        : props.state === 'stepCompleted'
          ? '3px solid var(--SECONDARY-Green-O-500)'
          : '3px solid var(--GRAY-OPAC-Gray-O-400)'};
    background: ${(props) => (props.state === 'stepProgress' ? 'var(--BRAND-DHL-Red)' : 'var(--BASIC-White)')};
  }

  & .mantine-Stepper-stepCompletedIcon {
    color: var(--SECONDARY-Green-O-500);
  }

  & .mantine-Stepper-stepWrapper {
    padding: ${(props) => (props.state === 'stepProgress' ? '0 5px' : '0')};
  }

  & .mantine-Stepper-stepBody {
    margin-inline-start: 0;
    margin-top: 50px;
    position: absolute;
  }

  & .mantine-Stepper-stepLabel {
    color: ${(props) =>
      props.state === 'stepProgress'
        ? 'var(--BRAND-DHL-Red)'
        : props.state === 'stepCompleted'
          ? 'var(--SECONDARY-Green-O-500)'
          : 'var(--GRAY-ALPHA-Gray-A-600)'};
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 12px;
  }
`;

const toUppercase = (text: string) => {
  return text.replace(/ß/g, 'ẞ').toUpperCase();
};

const CustomLabel: React.FC<CustomLabelProps> = ({ label, props }) => (
  <span style={{ display: 'flex' }}>
    <span style={{ maxWidth: '150px' }}>{toUppercase(label)}</span>
    {props.state === 'stepCompleted' && props.allowStepSelect && (
      <EditIcon size={12} style={{ width: '12px', height: '12px', marginLeft: '5px', marginTop: '-1px' }} />
    )}
  </span>
);
