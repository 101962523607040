interface LetterWithSeparatorProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const LetterWithSeparator = ({ size, style, ...others }: LetterWithSeparatorProps) => (
  <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M54.737 24.5625H46.5498C46.2388 24.5625 45.9867 24.8146 45.9867 25.1256V28.6561C45.9867 28.9671 46.2388 29.2192 46.5498 29.2192H54.737C55.0479 29.2192 55.3 28.9671 55.3 28.6561V25.1256C55.3 24.8146 55.0479 24.5625 54.737 24.5625Z"
      fill="#FFCC00"
    />
    <path
      d="M14.2367 50.4012H58.2634V21.5996H14.2367V50.4012ZM45.9867 25.126C45.9872 24.9768 46.0466 24.8339 46.1521 24.7284C46.2576 24.6229 46.4006 24.5634 46.5497 24.563H54.737C54.8862 24.5635 55.0291 24.6229 55.1346 24.7284C55.2401 24.8339 55.2996 24.9768 55.3 25.126V28.6566C55.2996 28.8058 55.2401 28.9487 55.1346 29.0542C55.0291 29.1597 54.8862 29.2192 54.737 29.2197H46.5497C46.4005 29.2192 46.2576 29.1597 46.1521 29.0543C46.0466 28.9488 45.9871 28.8058 45.9867 28.6566V25.126ZM42.3456 38.533H54.9391C55.1637 38.533 55.3791 38.6222 55.5378 38.781C55.6966 38.9398 55.7858 39.1551 55.7858 39.3797C55.7858 39.6042 55.6966 39.8196 55.5378 39.9783C55.3791 40.1371 55.1637 40.2263 54.9391 40.2263H42.3456C42.121 40.2263 41.9057 40.1371 41.7469 39.9783C41.5881 39.8196 41.4989 39.6042 41.4989 39.3797C41.4989 39.1551 41.5881 38.9398 41.7469 38.781C41.9057 38.6222 42.121 38.533 42.3456 38.533ZM42.3456 43.613H54.9391C55.1637 43.613 55.3791 43.7022 55.5378 43.861C55.6966 44.0198 55.7858 44.2351 55.7858 44.4597C55.7858 44.6842 55.6966 44.8996 55.5378 45.0583C55.3791 45.2171 55.1637 45.3063 54.9391 45.3063H42.3456C42.121 45.3063 41.9057 45.2171 41.7469 45.0583C41.5881 44.8996 41.4989 44.6842 41.4989 44.4597C41.4989 44.2351 41.5881 44.0198 41.7469 43.861C41.9057 43.7022 42.121 43.613 42.3456 43.613ZM35.4034 25.4097C35.4034 25.1851 35.4926 24.9697 35.6513 24.811C35.8101 24.6522 36.0255 24.563 36.25 24.563C36.4746 24.563 36.6899 24.6522 36.8487 24.811C37.0075 24.9697 37.0967 25.1851 37.0967 25.4097V46.9381C37.0967 47.1626 37.0075 47.378 36.8487 47.5367C36.6899 47.6955 36.4746 47.7847 36.25 47.7847C36.0255 47.7847 35.8101 47.6955 35.6513 47.5367C35.4926 47.378 35.4034 47.1626 35.4034 46.9381V25.4097Z"
      fill="white"
    />
    <path
      d="M59.9567 50.6847V21.3159C59.9562 20.9422 59.8076 20.5839 59.5433 20.3196C59.279 20.0554 58.9207 19.9067 58.547 19.9062H13.953C13.5793 19.9067 13.221 20.0553 12.9567 20.3196C12.6924 20.5839 12.5438 20.9422 12.5433 21.3159V50.6847C12.5438 51.0584 12.6924 51.4168 12.9567 51.681C13.221 51.9453 13.5793 52.094 13.953 52.0944H58.547C58.9207 52.094 59.279 51.9453 59.5433 51.681C59.8076 51.4168 59.9562 51.0584 59.9567 50.6847ZM58.2633 50.4011H14.2367V21.5995H58.2633V50.4011Z"
      fill="black"
    />
    <path
      d="M36.25 47.7842C36.4745 47.7842 36.6899 47.695 36.8487 47.5363C37.0075 47.3775 37.0967 47.1621 37.0967 46.9376V25.4092C37.0967 25.1846 37.0075 24.9693 36.8487 24.8105C36.6899 24.6517 36.4745 24.5625 36.25 24.5625C36.0254 24.5625 35.8101 24.6517 35.6513 24.8105C35.4925 24.9693 35.4033 25.1846 35.4033 25.4092V46.9376C35.4033 47.1621 35.4925 47.3775 35.6513 47.5363C35.8101 47.695 36.0254 47.7842 36.25 47.7842Z"
      fill="black"
    />
    <path
      d="M42.3456 40.2256H54.9391C55.1637 40.2256 55.379 40.1364 55.5378 39.9776C55.6966 39.8188 55.7858 39.6034 55.7858 39.3789C55.7858 39.1543 55.6966 38.939 55.5378 38.7802C55.379 38.6214 55.1637 38.5322 54.9391 38.5322H42.3456C42.121 38.5322 41.9057 38.6214 41.7469 38.7802C41.5881 38.939 41.4989 39.1543 41.4989 39.3789C41.4989 39.6034 41.5881 39.8188 41.7469 39.9776C41.9057 40.1364 42.121 40.2256 42.3456 40.2256Z"
      fill="black"
    />
    <path
      d="M42.3456 45.3056H54.9391C55.1637 45.3056 55.379 45.2164 55.5378 45.0577C55.6966 44.8989 55.7858 44.6835 55.7858 44.459C55.7858 44.2344 55.6966 44.0191 55.5378 43.8603C55.379 43.7015 55.1637 43.6123 54.9391 43.6123H42.3456C42.121 43.6123 41.9057 43.7015 41.7469 43.8603C41.5881 44.0191 41.4989 44.2344 41.4989 44.459C41.4989 44.6835 41.5881 44.8989 41.7469 45.0577C41.9057 45.2164 42.121 45.3056 42.3456 45.3056Z"
      fill="black"
    />
  </svg>
);
