import React, { ReactNode, useEffect, useState } from 'react';
import { OpenAPI } from '../api/requests';
import { useAuthenticationManager } from './useAuthenticationManager';
import { Container, Loader } from '@mantine/core';

const setupInterceptors = (getToken: () => Promise<string | undefined>, base: string, ekp?: string) => {
  const regexPatternsToInterceptImagePreview = [/^(?:\/ac-web)?\/api\/project\/[a-zA-Z0-9]+\/preview$/];

  OpenAPI.interceptors.request.use(async (config) => {
    const url = new URL(config.url || '', base);
    const shouldIntercept = regexPatternsToInterceptImagePreview.some((pattern) => pattern.test(url.pathname));

    if (shouldIntercept) {
      config.headers = {
        ...config.headers,
        Accept: 'image/jpeg',
      };
      config.responseType = 'blob'; // Setze den responseType auf 'blob'
    }

    const token = await getToken();
    config.headers = config.headers || {};
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      console.error('No token available');
    }

    if (ekp) {
      config.headers['X-DELEGATED-EKP'] = ekp;
    }

    return config;
  });

  OpenAPI.interceptors.response.use((response) => {
    const url = new URL(response.config.url || '', base);
    const shouldIntercept = regexPatternsToInterceptImagePreview.some((pattern) => pattern.test(url.pathname));

    if (shouldIntercept && response.data instanceof Blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.result) {
            resolve({
              ...response,
              data: reader.result,
            });
          } else {
            reject(new Error('Failed to read the Blob data'));
          }
        };
        reader.onerror = reject;
        reader.readAsDataURL(response.data);
      });
    }

    return response;
  });
};

export const OpenAPIInitializer: React.FC<{ children: ReactNode; ekp?: string }> = ({ children, ekp }) => {
  const [loading, setLoading] = useState(true);
  const authenticationManager = useAuthenticationManager();
  OpenAPI.BASE = process.env.BASENAME ? `${window.location.protocol}//${window.location.host}/ac-web` : OpenAPI.BASE;

  const getToken = async () => {
    return authenticationManager.getAccessToken();
  };

  useEffect(() => {
    const setupOpenAPI = async () => {
      setupInterceptors(getToken, OpenAPI.BASE, ekp);
      setLoading(false);
    };

    setupOpenAPI();
  }, [authenticationManager, ekp]);

  if (loading) {
    return (
      <Container size="xl" style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
        <Loader color="var(--BRAND-DHL-Red)" />
      </Container>
    );
  }

  return children;
};
