import { Router } from './Router';
import { useRoutes } from './hooks/useRoutes';

interface InternalRouterProps {
  basename?: string;
}

export const InternalRouter = ({ basename }: InternalRouterProps) => {
  const { internalRoutes } = useRoutes();
  return <Router routes={internalRoutes} basename={basename} />;
};
