import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from './useFormContext';

export const useUpdateStepNames = () => {
  const { t, i18n } = useTranslation();
  const { form } = useFormContext();
  const [stepNames, setStepNames] = useState([
    t('NameTestOrder'),
    t('ShipmentType'),
    t('FrankingAndSenderPosition'),
    t('SelectPDF'),
    t('UploadPDF'),
  ]);

  const updateStepNames = () => {
    updateStepName(0, t('NameTestOrder'));
    if (form.values.letterWindowType) {
      updateStepName(1, t(`step-two.${form.values.letterWindowType}`));
    } else {
      updateStepName(1, t('ShipmentType'));
    }
    if (form.values.letterFrankingPlacementType) {
      updateStepName(2, t(`step-three.${form.values.letterFrankingPlacementType}`));
    } else {
      updateStepName(2, t('FrankingAndSenderPosition'));
    }
    if (form.values.letterSenderPlacementType) {
      updateStepName(
        2,
        `${t(`step-three.${form.values.letterFrankingPlacementType}`)} & ${t(`step-three.${form.values.letterSenderPlacementType}`)}`,
      );
    }
    updateStepName(3, t('SelectPDF'));
    updateStepName(4, t('UploadPDF'));
  };

  const updateStepName = (index: number, newName: string) => {
    setStepNames((prevNames) => {
      const updatedNames = [...prevNames];
      updatedNames[index] = newName;
      return updatedNames;
    });
  };

  useEffect(() => {
    updateStepNames();

    i18n.on('languageChanged', updateStepNames);

    return () => {
      i18n.off('languageChanged', updateStepNames);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    form.values.letterWindowType,
    form.values.letterFrankingPlacementType,
    form.values.letterSenderPlacementType,
    i18n,
  ]);

  return stepNames;
};
