import { DateInput, DateInputProps } from '@mantine/dates';
import { useRef, useState } from 'react';
import { CalendarIcon } from '../Icons/CalendarIcon';
import styled from '@emotion/styled';
import { ActionIcon, CloseIcon } from '@mantine/core';

const StyledDateInput = styled(DateInput)<DateInputProps>`
  position: relative;
  & .mantine-DateInput-label {
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    position: absolute;
    top: ${(props) => (props.value ? '5px' : '15px')};
    left: 12px;
    z-index: 1;
    color: ${(props) => (props.error ? 'var(--BRAND-DHL-Red)' : 'var(--GRAY-ALPHA-Gray-A-600)')};
  }
  &:focus-within .mantine-DateInput-label {
    top: 5px;
    transition: top 0.125s ease-in-out;
  }
  & .mantine-DateInput-required {
    color: ${(props) => (props.error ? 'var(--BRAND-DHL-Red)' : 'var(--GRAY-ALPHA-Gray-A-600)')};
  }
  & .mantine-DateInput-input {
    padding-top: 13px;
    &:focus {
      border: ${(props) => (props.error ? '2px solid var(--BRAND-DHL-Red)' : '2px solid var(--BASIC-Black)')};
    }
    &:disabled {
      border: 1px solid var(--GRAY-ALPHA-Gray-A-100);
      background: var(--GRAY-OPAC-Gray-O-100);
      opacity: 1;
    }
  }
`;

export const DateInputStyled = (props: DateInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleIconClick = () => {
    if (inputRef.current) {
      if (props.value && props.onChange) {
        props.onChange(null);
      } else {
        if (isFocused) {
          inputRef.current.blur();
        } else {
          inputRef.current.focus();
        }
      }
      setIsFocused(!isFocused);
    }
  };

  return (
    <StyledDateInput
      {...props}
      rightSection={
        <ActionIcon variant="transparent" onClick={handleIconClick}>
          {props.value ? <CloseIcon color="black" /> : <CalendarIcon />}
        </ActionIcon>
      }
      ref={inputRef}
      onFocus={() => setIsFocused(true)}
    />
  );
};
