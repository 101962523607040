import { useEffect, useState } from 'react';
import { useProjectControllerServiceGetProject, useProjectControllerServicePerformCheck } from '../api/queries';
import { useFormContext } from './useFormContext';
import { useQueryClient } from '@tanstack/react-query';

export const useCheckProject = () => {
  const queryClient = useQueryClient();
  const checkQueryInfo = useProjectControllerServicePerformCheck();

  const { form, setFormValues } = useFormContext();
  const { projectNumber } = form.values;
  const [isProjectQueryRunning, setIsProjectQueryRunning] = useState(false);

  const getProjectQueryInfo = useProjectControllerServiceGetProject({ projectNumber }, undefined, {
    enabled: isProjectQueryRunning,
    refetchInterval: 1000,
  });

  useEffect(() => {
    if (checkQueryInfo.isSuccess) {
      setIsProjectQueryRunning(true);
    }
  }, [checkQueryInfo.isSuccess]);

  useEffect(() => {
    if (getProjectQueryInfo.data?.projectState === 'DONE' && getProjectQueryInfo.data) {
      setFormValues(getProjectQueryInfo.data);
    }
  }, [getProjectQueryInfo.data]);

  useEffect(() => {
    setIsProjectQueryRunning(getProjectQueryInfo.data?.projectState === 'RUNNING');
  }, [getProjectQueryInfo.data?.projectState]);

  const checkProject = async () => {
    queryClient.removeQueries({
      queryKey: ['ProjectControllerServiceGetPreview', { projectNumber }],
    });
    queryClient.removeQueries({
      queryKey: ['ProjectControllerServiceGetProject', { projectNumber }],
    });
    queryClient.removeQueries({
      queryKey: ['ProjectControllerServiceGetLatestAnalyzeResults', { projectNumber }],
    });
    await checkQueryInfo.mutateAsync({ projectNumber });
  };

  return {
    checkProject,
    isSuccess: getProjectQueryInfo.data?.projectState === 'DONE',
    isChecking: isProjectQueryRunning,
  };
};
