import styled from '@emotion/styled';
import { Dropzone, DropzoneProps, FileRejection } from '@mantine/dropzone';
import { Flex, Group, Loader, Text } from '@mantine/core';
import { FileUploadIcon } from '../Icons/FileUploadIcon';
import { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import { useFormContext } from '../../hooks/useFormContext';
import { SingleFileUploadAlert } from './SingleFileUploadAlert';
import 'pdfjs-dist/build/pdf.worker.min.mjs';

interface SingleFileUploadProps {
  uploadDocument: (projectNumber: string, userFile: File) => void;
  isUploadDocumentPending: boolean;
}

pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.mjs';

export const SingleFileUpload = ({ uploadDocument, isUploadDocumentPending }: SingleFileUploadProps) => {
  const { t } = useTranslation();
  const [fileRejection, setFileRejection] = useState<FileRejection | undefined>(undefined);
  const { form } = useFormContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const [pageWidth, setPageWidth] = useState<number>(0);

  const updatePageWidth = () => {
    if (containerRef.current) {
      setPageWidth(containerRef.current.offsetWidth);
    }
  };

  useLayoutEffect(() => {
    const observer = new MutationObserver(() => {
      updatePageWidth();
    });

    if (containerRef.current) {
      observer.observe(containerRef.current, { childList: true, subtree: true });
    }

    window.addEventListener('resize', updatePageWidth);

    return () => {
      if (containerRef.current) {
        observer.disconnect();
      }
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);

  useLayoutEffect(() => {
    if (containerRef.current) {
      updatePageWidth();
    }
  });

  const handleDrop = async (files: File[]) => {
    const file = files[0];
    if (file) {
      form.setFieldValue('userFile', file);
      form.setFieldValue('userFileName', file.name);
      uploadDocument(form.values.projectNumber, file);
    }
  };

  const handleReject = async (fileRejections: FileRejection[]) => {
    setFileRejection(fileRejections.at(0));
  };

  const handleRemove = () => {
    setFileRejection(undefined);
    form.setFieldValue('userFile', undefined);
    form.setFieldValue('userFileName', '');
  };

  const showUploadAlert = form.values.userFile !== undefined || fileRejection;

  return (
    <>
      {form.values.userFile ? (
        <Container ref={containerRef}>
          <Document
            file={form.values.userFile}
            loading={
              <Flex style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Loader color="var(--BRAND-DHL-Red)" />
              </Flex>
            }
          >
            <StyledPage pageNumber={1} width={pageWidth} renderAnnotationLayer={false} />
          </Document>
        </Container>
      ) : (
        <FileUploadBase
          data-testid="pdf-upload"
          maxSize={15 * 1024 ** 2}
          accept={['application/pdf']}
          onDrop={handleDrop}
          onReject={handleReject}
          maxFiles={1}
          multiple={false}
          hasError={fileRejection !== undefined}
        >
          <Group justify="center" gap="xl" mih={100}>
            <center>
              <FileUploadIcon />
              <Text size="sm" ta="center">
                {t('single-file-upload.drag-file-here')}
              </Text>
              <FlexContainer>
                <Text size="sm" ta="center" inline mt={7}>
                  {t('single-file-upload.or')}
                </Text>
                <Text size="sm" ta="center" td="underline" c="red" inline mt={7}>
                  {t('single-file-upload.select-file')}
                </Text>
              </FlexContainer>
            </center>
          </Group>
        </FileUploadBase>
      )}
      {showUploadAlert && (
        <SingleFileUploadAlert
          title={fileRejection ? fileRejection.file.name : form.values.userFile?.name}
          fileRejection={fileRejection}
          deleteUpload={handleRemove}
          isUploadDocumentPending={isUploadDocumentPending}
        />
      )}
    </>
  );
};

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
`;

type DropzoneStyleProps = { hasError: boolean };
const FileUploadBase = styled(Dropzone, {
  shouldForwardProp: (prop) => prop !== 'hasError',
})<DropzoneProps & DropzoneStyleProps>`
  --dropzone-radius: 4px;
  position: relative;

  & .mantine-Dropzone-inner {
    display: flex;
    padding: 10px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    border: ${(props) =>
      props.hasError ? '2px dashed var(--BRAND-DHL-Red);' : '2px dashed var(--GRAY-OPAC-Gray-O-500)'};
    border-radius: var(--dropzone-radius, 4px);
    background: var(--GRAY-OPAC-Gray-O-50);

    &:hover {
      cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
      background: ${(props) => (props.disabled ? 'var(--GRAY-OPAC-Gray-O-50)' : 'var(--GRAY-OPAC-Gray-O-200)')};
    }

    &:focus {
      border: 2px dashed var(--GRAY-OPAC-Gray-O-500);
    }
  }
`;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const StyledPage = styled(Page)`
  border: 1px solid var(--GRAY-OPAC-Gray-O-400);
  box-shadow: 2px 2px 4px var(--GRAY-ALPHA-Gray-A-200);
`;
