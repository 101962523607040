type SearchIconProps = React.ComponentPropsWithoutRef<'svg'>;

export const SearchIcon = (props: SearchIconProps) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1013_30026)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5876 19.1598L12.9897 12.5619C14.0206 11.2732 14.6907 9.67528 14.6907 7.87113C14.6907 3.79899 11.3917 0.5 7.37113 0.5C3.29896 0.5 0 3.79899 0 7.87113C0 11.9433 3.29896 15.1907 7.37113 15.1907C8.91752 15.1907 10.3093 14.7268 11.4948 13.9021L18.1443 20.5L19.5876 19.1598ZM1.64947 7.9227C1.64947 4.77836 4.17525 2.25258 7.31959 2.25258C10.4639 2.25258 12.9897 4.77836 12.9897 7.9227C12.9897 11.067 10.4639 13.5928 7.31959 13.5928C4.22679 13.5928 1.64947 11.067 1.64947 7.9227Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1013_30026">
        <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
