import { Button, ButtonProps } from '@mantine/core';
import styled from '@emotion/styled';

interface PrimaryButtonProps {
  ref?: any;
}

export const PrimaryButton = styled(Button)<
  PrimaryButtonProps & ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>`
  background: var(--BRAND-DHL-Red);
  color: var(--BASIC-White);
  border-radius: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  height: 42px;
  padding: 7px 16px 7px 16px;
  border: 2px solid var(--BRAND-DHL-Red);
  min-width: 160px;

  &:hover,
  &:focus {
    background: var(--BASIC-White);
    color: var(--BRAND-DHL-Red);
  }

  &:active {
    border-radius: 4px;
    border: 2px solid var(--SECONDARY-Red-O-900);
    background: var(--SECONDARY-Red-O-800);
    box-shadow: 0px 3px 5px 0px rgba(50, 50, 50, 0.2) inset; // variable missing?
    color: var(--BASIC-White);
  }

  &:disabled {
    box-shadow: none;
    border-radius: 4px;
    border: none;
    background: var(--GRAY-ALPHA-Gray-A-100);
    color: var(--BASIC-White);
  }
`;

export const DefaultButton = styled(PrimaryButton)<ButtonProps>`
  color: var(--BRAND-DHL-Red);
  background: var(--BASIC-White);
  border: 2px solid var(--GRAY-OPAC-Gray-O-500);

  &.active {
    color: var(--BRAND-DHL-Red);
    border: 2px solid var(--BRAND-DHL-Red);
  }

  &:hover,
  &:focus {
    color: var(--BRAND-DHL-Red);
    border: 2px solid var(--BRAND-DHL-Red);
  }

  &:active {
    border-radius: 4px;
    border: 2px solid var(--GRAY-ALPHA-Gray-A-400);
    background: var(--GRAY-OPAC-Gray-O-400);
    color: var(--GRAY-OPAC-Gray-O-900);

    box-shadow: 0px 3px 5px 0px rgba(50, 50, 50, 0.2) inset; // variable missing?
    color: var(--GRAY-OPAC-Gray-O-900);
  }

  &:disabled {
    box-shadow: none;
    border-radius: 4px;
    border: 2px solid var(--GRAY-ALPHA-Gray-A-100);
    background: var(--BASIC-White);
    color: var(--GRAY-ALPHA-Gray-A-100);
  }
`;

export const GhostButton = styled(PrimaryButton)<ButtonProps>`
  color: var(--BRAND-DHL-Red);
  background: transparent;
  border: 2px solid transparent;

  &.active {
    color: var(--BRAND-DHL-Red);
  }

  &:hover,
  &:focus {
    background: var(--GRAY-OPAC-Gray-O-400);
    color: var(--GRAY-OPAC-Gray-O-900);
  }

  &:active {
    border-radius: 4px;
    background: var(--GRAY-OPAC-Gray-O-400);
    color: var(--GRAY-OPAC-Gray-O-900);

    box-shadow: 0px 3px 5px 0px rgba(50, 50, 50, 0.2) inset; // variable missing?
    color: var(--GRAY-OPAC-Gray-O-900);
  }

  &:disabled {
    box-shadow: none;
    border-radius: 4px;
    border: 2px solid var(--GRAY-ALPHA-Gray-A-100);
    background: var(--BASIC-White);
    color: var(--GRAY-ALPHA-Gray-A-100);
  }
`;

export const SecondaryButton = styled(PrimaryButton)<ButtonProps>`
  border: 2px solid var(--GRAY-OPAC-Gray-O-600);
  background: var(--GRAY-OPAC-Gray-O-600);

  &:hover,
  &:focus {
    color: var(--BRAND-DHL-Red);
    border: 2px solid var(--BRAND-DHL-Red);
  }

  &:active {
    border-radius: 4px;
    border: 2px solid var(--GRAY-ALPHA-Gray-A-400);
    background: var(--GRAY-OPAC-Gray-O-400);
    color: var(--GRAY-OPAC-Gray-O-900);

    box-shadow: 0px 3px 5px 0px rgba(50, 50, 50, 0.2) inset; // variable missing?
  }

  &:disabled {
    box-shadow: none;
    border: none;
    border-radius: 4px;
    background: var(--GRAY-ALPHA-Gray-A-100);
    color: var(--BASIC-White);
  }
`;
