interface DownloadFileIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const DownloadFileIcon = ({ size, style, color, ...others }: DownloadFileIconProps) => (
  <svg width={size || 24} height={size || 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.875 0H2V24H21V7.2H13.875V0ZM15.0625 14.4L11.5 18.6L7.9375 14.4H10.3125V10.2H12.6875V14.4H15.0625ZM21 6H15.0625V0L21 6Z"
      fill={color || '#878787'}
    />
  </svg>
);
