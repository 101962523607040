import { useForm } from '@mantine/form';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useValidation } from './useValidation';
import {
  DocumentVO,
  letterFrankingPlacementType,
  letterSenderPlacementType,
  letterWindowType,
  ProjectDetailVO,
} from '../api/requests';
import { useProjectControllerServiceGetProject } from '../api/queries';
import { useParams } from 'react-router-dom';

export const useFormHook = () => {
  const { validateName, validateLongSideInMm, validateShortSideInMm } = useValidation();

  const initialValues = {
    projectNumber: '',
    name: '',
    longSideInMm: '' as number | '',
    shortSideInMm: '' as number | '',
    thicknessInMm: '' as number | '',
    weightInG: '' as number | '',
    letterWindowType: null as letterWindowType | null,
    letterFrankingPlacementType: null as letterFrankingPlacementType | null,
    letterSenderPlacementType: null as letterSenderPlacementType | null,
    userFileName: '',
    userFile: undefined as File | undefined,
    attestationState: undefined as DocumentVO['attestationState'] | undefined,
    projectState: undefined as ProjectDetailVO['projectState'] | undefined,
  };

  const form = useForm({
    validateInputOnChange: true,
    initialValues: initialValues,
    validate: {
      name: validateName,
      longSideInMm: validateLongSideInMm,
      shortSideInMm: validateShortSideInMm,
    },
  });

  const { projectNumber } = useParams<{ projectNumber?: string }>();

  const getProjectQueryInfo = useProjectControllerServiceGetProject({ projectNumber: projectNumber ?? '' }, undefined, {
    enabled: !!projectNumber,
  });

  const resetForm = () => {
    form.setValues(initialValues);
    setQueryComplete(false);
  };

  const [isQueryComplete, setQueryComplete] = useState(false);

  const setFormValues = (data: ProjectDetailVO) => {
    form.setValues((prevValues) => ({
      projectNumber: data.projectNumber ?? prevValues.projectNumber,
      name: data.name ?? prevValues.name,
      longSideInMm: data.physicalDocumentProperties?.longSideInMm ?? prevValues.longSideInMm,
      shortSideInMm: data.physicalDocumentProperties?.shortSideInMm ?? prevValues.shortSideInMm,
      thicknessInMm: data.physicalDocumentProperties?.thicknessInMm ?? prevValues.thicknessInMm,
      weightInG: data.physicalDocumentProperties?.weightInG ?? prevValues.weightInG,
      letterWindowType: data.letterWindowType ?? prevValues.letterWindowType,
      letterFrankingPlacementType: data.letterFrankingPlacementType ?? prevValues.letterFrankingPlacementType,
      letterSenderPlacementType: data.letterSenderPlacementType ?? prevValues.letterSenderPlacementType,
      userFileName: data.lastDocument?.userFileName ?? prevValues.userFileName,
      attestationState: data.lastDocument?.attestationState ?? prevValues.attestationState,
      projectState: data.projectState ?? prevValues.projectState,
    }));
  };

  useEffect(() => {
    if (getProjectQueryInfo.data) {
      setFormValues(getProjectQueryInfo.data);
      setQueryComplete(true);
    }
  }, [getProjectQueryInfo.data]);

  return {
    form,
    resetForm,
    refetch: getProjectQueryInfo.refetch,
    isCheckPositive: form.values.attestationState === 'SUCCEEDED',
    isCheckNegative: form.values.attestationState === 'FAILED',
    isCheckPositiveOrNegative:
      form.values.attestationState === 'SUCCEEDED' || form.values.attestationState === 'FAILED',
    isProjectClosed: form.values.projectState === 'CLOSED',
    isMalicious: form.values.attestationState === 'MALICIOUS',
    isQueryComplete,
    isError: getProjectQueryInfo.isError,
    isQueryEnabled: !!projectNumber,
    setFormValues,
  };
};

type FormContextProps = ReturnType<typeof useFormHook>;

export const FormContext = createContext<FormContextProps | undefined>(undefined);

export const FormContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const formContextValue = useFormHook();

  return <FormContext.Provider value={formContextValue}>{children}</FormContext.Provider>;
};

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (context === undefined) {
    throw new Error('useFormContext must be used within a FormContextProvider');
  }
  return context;
};
