interface SuccessIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const SuccessIcon = ({ size, style, ...others }: SuccessIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
    <g clipPath="url(#clip0_4044_6592)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2C13.1355 2 16.5 5.3645 16.5 9.5C16.5 13.6355 13.1355 17 9 17C4.8645 17 1.5 13.6355 1.5 9.5C1.5 5.3645 4.8645 2 9 2ZM9 0.5C4.02975 0.5 0 4.52975 0 9.5C0 14.4703 4.02975 18.5 9 18.5C13.9703 18.5 18 14.4703 18 9.5C18 4.52975 13.9703 0.5 9 0.5ZM13.6875 7.16825L12.6217 6.1115L8.043 10.775L5.7345 8.57225L4.6875 9.64925L8.0625 12.8885L13.6875 7.16825Z"
        fill="#3C763D"
      />
    </g>
    <defs>
      <clipPath id="clip0_4044_6592">
        <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
