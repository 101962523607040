import styled from '@emotion/styled';
import { ActionIcon, CloseIcon, TextInput, TextInputProps } from '@mantine/core';
import { useEffect, useState } from 'react';
import { FormChecked } from '../Icons/FormChecked';
import { SearchIcon } from '../Icons/Search';

export const InputCompact = (props: TextInputProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleWheel = (event: React.WheelEvent<HTMLInputElement>) => {
    event.currentTarget.blur();
  };

  const rightSection =
    !isFocused && props.value && !props.error ? (
      <FormChecked color="var(--SECONDARY-Green-O-500, #4caf50)" />
    ) : props.rightSection ? (
      props.rightSection
    ) : (
      ' '
    );

  return (
    <div>
      <InputCompactBase
        {...props}
        onFocus={handleFocus}
        onBlur={handleBlur}
        rightSection={rightSection}
        onWheel={handleWheel}
      />
    </div>
  );
};

interface InputCompactWithSearchButtonProps {
  onSearch: (value: string | undefined) => void;
  searchValue?: string;
}

export const InputCompactWithSearchButton = ({
  onSearch,
  searchValue,
  ...props
}: InputCompactWithSearchButtonProps & TextInputProps) => {
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    setValue(searchValue || '');
  }, [searchValue]);

  const search = !searchValue || value !== searchValue;
  const reset = !search;

  const rightSection = (
    <ActionIcon
      variant="filled"
      aria-label="Settings"
      color="var(--BRAND-DHL-Red)"
      onClick={() => {
        if (reset) {
          setValue('');
          onSearch(undefined);
        } else {
          onSearch(value);
        }
      }}
    >
      {reset ? <CloseIcon /> : <SearchIcon />}
    </ActionIcon>
  );

  return (
    <div>
      <InputCompactBase
        {...props}
        value={value}
        rightSection={rightSection}
        onChange={(event) => setValue(event.currentTarget.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onSearch(value || undefined);
          }
        }}
        onBlur={() => {
          onSearch(value || undefined);
        }}
      />
    </div>
  );
};

const InputCompactBase = styled(TextInput)<TextInputProps>`
  position: relative;
  --input-border-radius: 4px;
  --input-margin-top: 10px;
  height: 50px;

  & .mantine-TextInput-label {
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    position: absolute;
    top: ${(props) => (props.value ? '5px' : '15px')};
    left: 12px;
    z-index: 1;
    color: ${(props) => (props.error ? 'var(--BRAND-DHL-Red)' : 'var(--GRAY-ALPHA-Gray-A-600)')};
  }

  &:focus-within .mantine-TextInput-label {
    top: 5px;
    transition: top 0.125s ease-in-out;
  }

  & .mantine-TextInput-required {
    color: ${(props) => (props.error ? 'var(--BRAND-DHL-Red)' : 'var(--GRAY-ALPHA-Gray-A-600)')};
  }

  & .mantine-TextInput-error {
    position: absolute;
    width: 200%;
    white-space: nowrap;
  }

  & .mantine-TextInput-input {
    padding-top: 13px;

    &:focus {
      border: ${(props) => (props.error ? '2px solid var(--BRAND-DHL-Red)' : '2px solid var(--BASIC-Black)')};
    }

    &:disabled {
      border: 1px solid var(--GRAY-ALPHA-Gray-A-100);
      background: var(--GRAY-OPAC-Gray-O-100);
      opacity: 1;
    }
  }
`;
