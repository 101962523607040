import React from 'react';
import { GhostButton } from './Buttons';
import { InfoIcon } from '../Icons/InfoIcon';
import { HelpButton } from './HelpButton';
import { Tooltip } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { errorCodeToPageAnchorMap } from './errorCodeToPageAnchorMap';

interface ErrorHelpButtonProps {
  errorCode: string | undefined;
}

export const ErrorHelpButton: React.FC<ErrorHelpButtonProps> = ({ errorCode }) => {
  const { t } = useTranslation();
  const mapToErrorHelpPage = (errorCode: string | undefined): string => {
    return errorCode ? errorCodeToPageAnchorMap[errorCode] || '' : '';
  };

  return (
    <HelpButton page={mapToErrorHelpPage(errorCode)}>
      <Tooltip
        withArrow
        arrowSize={10}
        label={t('errorHelpButton.mouseover')}
        style={{
          backgroundColor: 'var(--BASIC-Black)',
          color: 'var(--BASIC-White)',
        }}
      >
        <GhostButton style={{ minWidth: 'auto', height: 'auto', padding: '2px', marginLeft: '5px' }}>
          <InfoIcon color="var(--BRAND-DHL-Red)" size="14" />
        </GhostButton>
      </Tooltip>
    </HelpButton>
  );
};
