import { Container, createTheme, rem } from '@mantine/core';

const CONTAINER_SIZES: Record<string, string> = {
  xs: rem('936px'),
  sm: rem('936px'),
  md: rem('936px'),
  lg: rem('1140px'),
  xl: rem('1416px'),
};

export const theme = createTheme({
  fontFamily: 'Delivery, sans-serif',
  headings: {
    fontFamily: 'Delivery, sans-serif',
  },
  breakpoints: {
    xs: '576px',
    sm: '768px',
    md: '992px',
    lg: '1200px',
    xl: '1440px',
  },
  components: {
    Container: Container.extend({
      vars: (_, { size, fluid }) => ({
        root: {
          '--container-size': fluid
            ? '100%'
            : size !== undefined && size in CONTAINER_SIZES
              ? CONTAINER_SIZES[size]
              : rem(size),
        },
      }),
    }),
  },
});
