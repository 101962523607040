import { useProjectControllerServiceCreateProject } from '../api/queries';

export const useCreateProject = () => {
  const createProjectQueryInfo = useProjectControllerServiceCreateProject();

  const createProject = async (name: string) => {
    const value = await createProjectQueryInfo.mutateAsync({
      requestBody: {
        name,
      },
    });
    return value.projectNumber;
  };

  return {
    createProject,
    isCreateProjectSuccess: createProjectQueryInfo.isSuccess,
  };
};
