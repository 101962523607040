import React, { createContext, ReactNode, useContext } from 'react';
import { useProductsProxyControllerServiceGetProductInfo } from '../api/queries';
import { UseQueryResult } from '@tanstack/react-query';
import { GetProductInfoResponse } from '../api/requests';
import { BasisformatProduktDto, produkt } from '../analyzer-api/requests';

type ProductInfo = Record<produkt, BasisformatProduktDto[]>;

type ProductQueryInfoContextType = UseQueryResult<GetProductInfoResponse, unknown> & {
  getShortSideMinMaxForDialogpost: () => { max: number; min: number };
  getLongSideMinMaxForDialogpost: () => { max: number; min: number };
  getStandardAndGrossFromDialogpost: () => { standard: BasisformatProduktDto; gross: BasisformatProduktDto };
};

const ProductQueryInfoContext = createContext<ProductQueryInfoContextType | undefined>(undefined);
type ProductQueryInfoProviderProps = {
  children: ReactNode;
};

export const ProductQueryInfoProvider: React.FC<ProductQueryInfoProviderProps> = ({ children }) => {
  const productQueryInfo = useProductsProxyControllerServiceGetProductInfo();
  const productInfo = productQueryInfo.data as unknown as ProductInfo;

  const getStandardAndGrossFromDialogpost = () => {
    const dialogpost = productInfo.DIALOGPOST;
    const standard = dialogpost.find((item) => item.basisFormat === 'STANDARD');
    const gross = dialogpost.find((item) => item.basisFormat === 'GROSS');
    if (!standard || !gross) {
      throw new Error('No Productinformation for DIALOGPOST.');
    }
    return { standard, gross };
  };

  const getShortSideMinMaxForDialogpost = (): { max: number; min: number } => {
    const { standard, gross } = getStandardAndGrossFromDialogpost();
    return {
      max: Math.max(standard.maxShortSideInMM, gross.maxShortSideInMM),
      min: Math.min(standard.minShortSideInMM, gross.minShortSideInMM),
    };
  };

  const getLongSideMinMaxForDialogpost = (): { max: number; min: number } => {
    const { standard, gross } = getStandardAndGrossFromDialogpost();
    return {
      max: Math.max(standard.maxLongSideInMM, gross.maxLongSideInMM),
      min: Math.min(standard.minLongSideInMM, gross.minLongSideInMM),
    };
  };

  return (
    <ProductQueryInfoContext.Provider
      value={{
        ...productQueryInfo,
        getShortSideMinMaxForDialogpost,
        getLongSideMinMaxForDialogpost,
        getStandardAndGrossFromDialogpost,
      }}
    >
      {children}
    </ProductQueryInfoContext.Provider>
  );
};

export const useProductQueryInfoContext = () => {
  const context = useContext(ProductQueryInfoContext);
  if (context === undefined) {
    throw new Error('useProductQueryInfo muss innerhalb eines ProductQueryInfoProvider verwendet werden');
  }
  return context;
};
