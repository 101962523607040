import React from 'react';
import { DefaultButton } from './Buttons';
import { QuestionIcon } from '../Icons/QuestionIcon';
import { HelpButton } from './HelpButton';
import { Tooltip } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useCurrentStep } from '../../hooks/useCurrentStep';

interface PageHelpButtonProps {
  page?: string;
}

export const PageHelpButton: React.FC<PageHelpButtonProps> = ({ page }) => {
  const { t } = useTranslation();
  const { step } = useCurrentStep();
  const getHelpPage = (page?: string, step?: number) => {
    if (page) {
      return page;
    }

    switch (step) {
      case 1:
        return 'name-masse-lo9g3d.html';
      case 2:
        return 'sendungstyp-af5n8z.html';
      case 3:
        return 'frankierung-absender-n8f43d.html';
      case 4:
        return 'upload-7hg5fm.html';
      default:
        return 'faq-jnhg43.html';
    }
  };

  if (page || step < 5) {
    return (
      <HelpButton page={getHelpPage(page, step)}>
        <Tooltip
          withArrow
          arrowSize={10}
          label={t('helpButton.mouseover')}
          style={{
            backgroundColor: 'var(--BASIC-Black)',
            color: 'var(--BASIC-White)',
          }}
        >
          <DefaultButton style={{ minWidth: 'auto', height: 'auto', padding: '9px' }}>
            <QuestionIcon color="var(--BRAND-DHL-Red)" size="14" />
          </DefaultButton>
        </Tooltip>
      </HelpButton>
    );
  } else {
    return null;
  }
};
