import React, { useEffect, useState } from 'react';
import { Flex } from '@mantine/core';
import { useAuthenticationManager } from '../../hooks/useAuthenticationManager';
import { GhostButton } from '../Buttons/Buttons';
import { changeLanguage } from 'i18next';
import { useNavigateWithSearchParams } from '../../hooks/useNavigateWithSearchParams';

export const DevTools: React.FC = () => {
  const { navigateAndKeepSearchParams: navigate } = useNavigateWithSearchParams();
  const authenticationManager = useAuthenticationManager();
  const isMicroFrontend = process.env.MIFO;
  const [user, setUser] = useState<string>('');
  const [currentLanguage, setCurrentLanguage] = useState('de');

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === 'de' ? 'en' : 'de';
    changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
  };

  useEffect(() => {
    const fetchUser = async () => {
      const token = await authenticationManager.getAccessTokenParsed();
      setUser(`${token.given_name} ${token.family_name} (${token.ekp})`);
    };

    fetchUser();
  }, [authenticationManager]);

  if (isMicroFrontend) {
    return null;
  }

  return (
    <Flex justify="flex-end">
      <GhostButton onClick={() => navigate('/user')}>{user}</GhostButton>
      <GhostButton onClick={toggleLanguage}>
        {currentLanguage === 'de' ? 'Switch to English' : 'Umschalten auf Deutsch'}
      </GhostButton>
    </Flex>
  );
};
