import {
  AccessToken,
  Acr,
  AuthenticationEvent,
  AuthenticationEventListener,
  AuthenticationManager,
  Language,
  LoginOptions,
  Scope,
  ScopeCheckFn,
} from '@gkuis/gkp-authentication';
import { decodeJwt, JWTPayload, SignJWT } from 'jose';

const mockAccessToken: AccessToken = {
  exp: Date.now() + 60 * 60 * 1000, // expires in 1 hour
  sid: 'mockSessionId',
  sub: 'mockSubject',
  scope: 'automation_check:user',
  user_id: 'mockUserId',
  language: 'de',
  given_name: 'M.',
  family_name: 'Muster',
  email: 'm.muster@example.com',
  username: 'mmuster',
  phone: '123-456-7890',
  ekp: '123456789',
  acr: 'mockAcr',
  acr_exp: Date.now() + 60 * 60 * 1000, // expires in 1 hour
};

const localStorageKeyToken = 'acJwtToken';

export class MockAuthenticationManager implements AuthenticationManager {
  private getOrCreateToken = async () => {
    const storedToken = localStorage.getItem(localStorageKeyToken);
    if (!storedToken || !this.isTokenValid(storedToken)) {
      return this.generateTokenAndStore(
        {
          ekp: mockAccessToken.ekp ?? '',
          given_name: mockAccessToken.given_name,
          family_name: mockAccessToken.family_name,
          scope: mockAccessToken.scope,
          email: mockAccessToken.email,
          username: mockAccessToken.username,
        },
        60,
      );
    } else {
      return storedToken;
    }
  };

  private isTokenValid(token: string): boolean {
    const jwtPayload = decodeJwt(token);
    return jwtPayload.exp ? jwtPayload.exp > Date.now() / 1000 : false;
  }

  generateTokenAndStore = async (
    payload: {
      ekp: string;
      given_name: string;
      family_name: string;
      scope: string;
      email: string;
      username: string;
    },
    expirationTime: number,
  ) => {
    const token = await this.generateToken(payload, expirationTime);
    localStorage.setItem(localStorageKeyToken, token);
    return token;
  };

  private createSecretKey = async (): Promise<Uint8Array> => {
    const arrayBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode('fkbr'));
    return new Uint8Array(arrayBuffer);
  };

  private generateToken = async (
    payload: {
      ekp: string;
      given_name: string;
      family_name: string;
      scope: string;
      email: string;
      username: string;
    },
    expirationTime: number,
  ): Promise<string> => {
    const secret = await this.createSecretKey();
    const iat = Math.floor(Date.now() / 1000);
    const iss = 'http://jwt-user-issuer';
    const language = 'de';
    const typ = 'Bearer';
    return await new SignJWT({ ...payload, iat, iss, language, typ })
      .setProtectedHeader({ alg: 'HS256', typ: 'JWT' })
      .setExpirationTime(`${expirationTime}m`)
      .sign(secret);
  };

  private mapToAccessToken = (parsedToken: JWTPayload): AccessToken => {
    return {
      exp: parsedToken.exp ? parsedToken.exp : 0,
      sub: parsedToken.sub ? parsedToken.sub : '',
      scope: parsedToken.scope as string,
      language: (parsedToken.language as string) === 'en' ? 'en' : 'de',
      given_name: parsedToken.given_name as string,
      family_name: parsedToken.family_name as string,
      ekp: parsedToken.ekp as string,
      email: parsedToken.email as string,
      username: parsedToken.username as string,
      sid: '',
      user_id: '',
    };
  };

  getAccessToken(minValiditySeconds?: number | undefined): Promise<string | undefined> {
    return this.getOrCreateToken();
  }

  getAccessTokenWithoutRefresh(): string | undefined {
    return localStorage.getItem(localStorageKeyToken) || undefined;
  }

  authenticated = true;
  authenticatedSubject = 'mockSubject';
  userId = 123;
  language = 'de' as Language;
  lastActionUpdateStatus = undefined;

  get isInternal() {
    // Change this to true to simulate an internal user
    return false;
  }

  get isExternal() {
    return false;
  }

  addEventListener<T extends AuthenticationEvent>(event: T, listener: AuthenticationEventListener<T>): () => void {
    // Mock implementation
    return () => {
      // Mock implementation
    };
  }

  removeEventListener<T extends AuthenticationEvent>(event: T, listener: AuthenticationEventListener<T>): void {
    // Mock implementation
  }

  login(loginOptions?: LoginOptions): Promise<void>;
  login(username: string, password: string): Promise<void>;
  login(usernameOrOptions?: string | LoginOptions, password?: string): Promise<void> {
    return Promise.resolve();
  }

  updatePassword(): Promise<void> {
    return Promise.resolve();
  }

  configureTotp(): Promise<void> {
    return Promise.resolve();
  }

  configureSecurityProfile(): Promise<void> {
    return Promise.resolve();
  }

  logout(redirectUri?: string): Promise<void> {
    return Promise.resolve();
  }

  updateToken(minValiditySeconds?: number): Promise<boolean> {
    return Promise.resolve(true);
  }

  isTokenExpired(minValiditySeconds?: number): boolean {
    return false;
  }

  async getAccessTokenParsed(minValiditySeconds?: number): Promise<AccessToken> {
    const jwtToken = await this.getOrCreateToken();
    if (!jwtToken) {
      throw new Error('No token found');
    }
    const token = decodeJwt(jwtToken);
    return this.mapToAccessToken(token);
  }

  getRefreshTokenParsed(): AccessToken {
    throw new Error('Method not implemented.');
  }

  getUserId(): Promise<number> {
    return Promise.resolve(123);
  }

  getScopes(): Promise<(Scope | string)[]> {
    return Promise.resolve([]);
  }

  hasScopes(...scopes: string[]): Promise<boolean> {
    return Promise.resolve(true);
  }

  scopeCheck(requirement: Scope | string | ScopeCheckFn): Promise<boolean> {
    return Promise.resolve(true);
  }

  acrCheck(requirement: Acr): Promise<boolean> {
    return Promise.resolve(true);
  }
}
