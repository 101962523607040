export const Max50g = () => {
  return (
    <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_485_1866)">
        <path
          d="M39.9108 45.3517V39.3122C39.9109 39.2497 39.9141 39.1872 39.9205 39.125C40.0392 37.7757 41.0483 36.6769 42.3762 36.4512C42.4658 36.4386 42.5561 36.4321 42.6465 36.4318C43.685 36.4321 44.5276 35.5862 44.5293 34.5416V31.8293C44.5281 30.7864 43.6876 29.9414 42.6508 29.9407H30.562C29.525 29.9414 28.6843 30.7863 28.6827 31.8293V34.542C28.6843 35.5853 29.5247 36.4306 30.562 36.4322C30.6542 36.4322 30.7463 36.4393 30.8374 36.4533C32.2201 36.688 33.2485 37.8677 33.2991 39.2772C33.3017 39.3142 33.3026 39.3495 33.3026 39.3866V45.3521C33.3022 46.35 32.498 47.159 31.5057 47.1593H22.8015C21.7649 47.1604 20.9249 48.0053 20.9238 49.0479V67.57C20.9249 68.6125 21.7649 69.4575 22.8015 69.4586H50.4109C51.4479 69.4579 52.2886 68.613 52.2902 67.57V49.0479C52.2886 48.0049 51.4479 47.16 50.4109 47.1593H41.7076C40.7153 47.1589 39.911 46.3498 39.9108 45.3517Z"
          fill="#FFCC00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.9204 39.1249C39.9141 39.1871 39.9108 39.2496 39.9107 39.3121V45.3516C39.9109 46.3497 40.7152 47.1588 41.7075 47.1592H50.4108C51.4478 47.1599 52.2885 48.0048 52.2901 49.0478V67.5699C52.2885 68.6129 51.4478 69.4578 50.4108 69.4585H22.8014C21.7649 69.4574 20.9248 68.6125 20.9238 67.5699V49.0478C20.9248 48.0053 21.7649 47.1603 22.8014 47.1592H31.5057C32.4979 47.1589 33.3021 46.35 33.3025 45.352V39.3865C33.3025 39.3494 33.3016 39.3142 33.299 39.2771C33.2484 37.8676 32.2201 36.6879 30.8373 36.4533C30.7462 36.4392 30.6541 36.4321 30.5619 36.4321C29.5246 36.4305 28.6842 35.5852 28.6826 34.5419V31.8292C28.6842 30.7862 29.5249 29.9413 30.5619 29.9406H42.6507C43.6875 29.9413 44.528 30.7863 44.5292 31.8292V34.5415C44.5275 35.5862 43.685 36.432 42.6464 36.4318C42.556 36.432 42.4657 36.4385 42.3761 36.4511C41.0482 36.6768 40.0391 37.7756 39.9204 39.1249ZM42.8121 39.2804C42.7886 39.2973 42.7683 39.3254 42.7622 39.3644V44.3078H50.4127C53.0376 44.3095 55.1376 46.4437 55.1416 49.0435L55.1416 49.0478L55.1416 67.5742C55.1376 70.1741 53.0376 72.3082 50.4127 72.31H22.7984C20.1738 72.3072 18.075 70.1724 18.0723 67.5729L18.0723 67.5699L18.0723 49.0449C18.075 46.4453 20.1738 44.3105 22.7984 44.3078L30.451 44.3078V39.4259L30.4493 39.3793C30.4476 39.3322 30.4249 39.2991 30.3984 39.2807C27.849 39.1928 25.835 37.0926 25.8311 34.5462L25.8311 34.5419L25.8311 31.8247C25.8352 29.225 27.9351 27.091 30.5598 27.0891L42.6528 27.0891C45.2779 27.091 47.3777 29.2259 47.3807 31.8259L47.3807 31.8292L47.3807 34.5461C47.3766 37.0933 45.3614 39.1931 42.8121 39.2804Z"
          fill="black"
        />
        <path
          d="M33.0832 55.1814V51.9762C33.1163 51.4401 32.9744 50.9078 32.6788 50.4593C32.4069 50.1008 31.9752 49.9001 31.5259 49.9232C31.1734 49.9119 30.8264 50.0128 30.535 50.2115C30.2647 50.4045 30.0403 50.6548 29.8779 50.9446C29.7146 50.3115 29.1252 49.8832 28.4727 49.9232C28.1326 49.9123 27.7978 50.0099 27.5168 50.2018C27.255 50.3897 27.0419 50.6374 26.8952 50.9243V50.0249H25.5098V55.1814H26.9759V51.6421C27.0667 51.5044 27.1856 51.3875 27.3247 51.2989C27.4738 51.203 27.648 51.1536 27.8252 51.1569C28.0404 51.1414 28.249 51.2344 28.3813 51.4047C28.5114 51.6002 28.5752 51.8323 28.5633 52.0668V55.1814H30.0294V51.663C30.1184 51.5195 30.2374 51.397 30.3782 51.3038C30.5296 51.2041 30.7077 51.1529 30.8889 51.1569C31.1045 51.1384 31.3145 51.232 31.4449 51.4047C31.5684 51.6027 31.6284 51.8337 31.6169 52.0668V55.1814H33.0832Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.4887 52.5623C34.9435 52.2444 35.4916 52.0878 36.0457 52.1174L36.8539 52.1177V52.0464C36.8955 51.7839 36.8112 51.5171 36.6263 51.3262C36.4413 51.1354 36.1773 51.0426 35.9137 51.0759C35.5054 51.0776 35.1111 51.2247 34.8016 51.4909L34.1243 50.5803C34.6547 50.1174 35.342 49.8751 36.0454 49.9031C37.5082 49.9033 38.2397 50.6145 38.2397 52.0368V53.6441C38.2396 53.9407 38.3643 54.089 38.6139 54.089H38.6696C38.7085 54.088 38.7473 54.0847 38.7858 54.0792V55.2418C38.6911 55.255 38.5968 55.2663 38.5025 55.2725C38.4082 55.2787 38.3138 55.2822 38.2195 55.2822C37.9203 55.3008 37.6224 55.2285 37.365 55.0747C37.1541 54.9176 37.0142 54.6832 36.9758 54.4231C36.6541 54.9687 36.0622 55.2974 35.4289 55.2822C35.0203 55.3044 34.6216 55.1518 34.3322 54.8626C34.0428 54.5733 33.8901 54.1745 33.9123 53.766C33.8957 53.2945 34.111 52.8449 34.4887 52.5623ZM36.0154 54.2003C36.3487 54.199 36.6611 54.0373 36.8546 53.766V53.0273H36.2479C35.6615 53.0278 35.3683 53.2334 35.3683 53.6441C35.3599 53.7999 35.4232 53.951 35.5403 54.0542C35.6759 54.1585 35.8446 54.2104 36.0154 54.2003Z"
          fill="black"
        />
        <path
          d="M41.8404 53.5738L42.7914 55.1813H44.3982L42.7807 52.6129L44.2877 50.0251H42.7501L41.911 51.612H41.7499L40.9101 50.0251H39.3026L40.8089 52.6129L39.1914 55.1813H40.7284L41.6786 53.5738H41.8404Z"
          fill="black"
        />
        <path
          d="M45.784 55.3331C46.0274 55.3397 46.2649 55.257 46.4516 55.1005C46.6256 54.9385 46.7244 54.7114 46.7244 54.4737C46.7244 54.2359 46.6256 54.0088 46.4516 53.8468C46.0606 53.5367 45.5076 53.5367 45.1167 53.8468C44.9426 54.0088 44.8438 54.2359 44.8438 54.4737C44.8438 54.7114 44.9426 54.9385 45.1167 55.1005C45.3032 55.257 45.5406 55.3397 45.784 55.3331Z"
          fill="black"
        />
        <path
          d="M30.171 60.2528C29.706 60.053 29.2038 59.9547 28.6979 59.9646H27.6735V58.479H31.1698V56.8394H25.9826V61.5398H28.0189C28.4352 61.5111 28.8505 61.6091 29.21 61.8211C29.4844 62.0288 29.6341 62.362 29.6072 62.7051C29.6293 63.0347 29.479 63.3522 29.21 63.544C28.8878 63.7432 28.5124 63.8391 28.1341 63.8189C27.7569 63.8229 27.3819 63.7601 27.0266 63.6336C26.714 63.5244 26.4249 63.3569 26.1747 63.1401L25.2012 64.4976C25.5699 64.8162 25.9959 65.0619 26.4563 65.2216C27.0134 65.4075 27.5984 65.4963 28.1856 65.484C28.7942 65.4968 29.3986 65.381 29.9594 65.1443C30.4427 64.9391 30.8532 64.5935 31.1377 64.1523C31.4246 63.6825 31.5692 63.1396 31.554 62.5894C31.5735 62.0752 31.4446 61.5662 31.1826 61.1233C30.9394 60.739 30.5872 60.4359 30.171 60.2528Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.3913 57.7355C34.0032 56.9776 34.9497 56.5705 35.9208 56.6474C36.8918 56.5706 37.8382 56.9777 38.4503 57.7355C39.0361 58.6119 39.3215 59.6549 39.2634 60.7074V61.4245C39.3216 62.4768 39.0362 63.5196 38.4503 64.3957C37.7925 65.0907 36.8777 65.4845 35.9208 65.4845C34.9639 65.4845 34.0491 65.0907 33.3913 64.3957C32.8049 63.5198 32.5194 62.4769 32.5778 61.4245V60.7074C32.5195 59.6548 32.805 58.6117 33.3913 57.7355ZM36.9838 63.224C37.2425 62.6429 37.3566 62.0078 37.3166 61.373V60.7583C37.3566 60.1237 37.2425 59.4888 36.9838 58.9079C36.7574 58.5377 36.3547 58.3119 35.9208 58.3119C35.4869 58.3119 35.0842 58.5377 34.8578 58.9079C34.5986 59.4886 34.4843 60.1236 34.5246 60.7583V61.373C34.4843 62.0079 34.5986 62.643 34.8578 63.224C35.0844 63.5939 35.487 63.8193 35.9208 63.8193C36.3546 63.8193 36.7572 63.5939 36.9838 63.224Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.7978 58.9337C45.1298 59.1331 45.3968 59.4245 45.5664 59.7726V58.7597H47.321V64.7286C47.3211 66.8287 46.177 67.8788 43.8886 67.8788C43.0513 67.9075 42.2277 67.66 41.545 67.1742L42.3132 65.8554C42.787 66.1594 43.3386 66.3196 43.9015 66.3169C44.338 66.3863 44.7815 66.2462 45.0989 65.9387C45.4164 65.6313 45.5706 65.1924 45.5152 64.754V64.2291C45.3348 64.5546 45.0691 64.8246 44.7467 65.0102C44.4156 65.1999 44.0397 65.2973 43.6582 65.2922C42.9806 65.3192 42.3325 65.0132 41.9227 64.4728C41.4673 63.8172 41.2442 63.0282 41.2888 62.2312V61.693C41.2442 60.8962 41.4673 60.1074 41.9227 59.452C42.3324 58.9115 42.9805 58.6054 43.6582 58.6322C44.0587 58.6254 44.4532 58.7298 44.7978 58.9337ZM44.3623 63.7428C44.7971 63.7594 45.2128 63.5636 45.4769 63.2179V60.7078C45.2105 60.3648 44.7964 60.1695 44.3623 60.1822C44.0114 60.1596 43.6717 60.3105 43.4532 60.5859C43.2271 60.9285 43.1192 61.3355 43.1458 61.7451V62.1799C43.1459 63.2219 43.5514 63.7428 44.3623 63.7428Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_485_1866">
          <rect width="72" height="72" fill="white" transform="translate(0.25)" />
        </clipPath>
      </defs>
    </svg>
  );
};
