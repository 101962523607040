import { Router } from './Router';
import { useRoutes } from './hooks/useRoutes';

interface ExternalRouterProps {
  basename?: string;
}

export const ExternalRouter = ({ basename }: ExternalRouterProps) => {
  const { externalRoutes } = useRoutes();
  return <Router routes={externalRoutes} basename={basename} />;
};
