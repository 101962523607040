interface FormCheckedProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const FormChecked = ({ size, style, color, ...others }: FormCheckedProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <circle className="icon-fill-bg" cx="12" cy="12" r="11" fill="transparent" />
      <path
        fill={color || '#878787'}
        d="M12,0 C5.373,0 0,5.373 0,12 C0,18.627 5.373,24 12,24 C18.627,24 24,18.627 24,12 C24,5.373 18.627,0 12,0 L12,0 Z M18.25,8.891 L16.829,7.482 L10.724,13.7 L7.646,10.763 L6.25,12.199 L10.75,16.518 L18.25,8.891 L18.25,8.891 Z"
      />
    </g>
  </svg>
);
