import { Flex, Grid, List, ListItem, Paper, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useFormContext } from '../hooks/useFormContext';
import { InputCompact } from '../components/Input/InputCompact';
import { useLetterType } from '../hooks/useLetterType';
import { RadioButtonCard } from '../components/RadioButtonCard/RadioButtonCard';
import { Max50g } from '../components/Icons/Max50g';
import { Max1Kg } from '../components/Icons/Max1Kg';
import { Max5mm } from '../components/Icons/Max5mm';
import { Max30mm } from '../components/Icons/Max30mm';
import { InfoIcon } from '../components/Icons/InfoIcon';
import { DimensionsStandard } from '../components/Icons/DimensionsStandard';
import { DimensionsGross } from '../components/Icons/DimensionsGross';
import styled from '@emotion/styled';
import { ButtonBar } from '../components/Buttons/ButtonBar';
import { useCreateProject } from '../hooks/useCreateProject';
import { useUpdateProject } from '../hooks/useUpdateProject';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGoToDashboard } from '../hooks/useGoToDashboard';
import { useNavigateWithSearchParams } from '../hooks/useNavigateWithSearchParams';
import { Notification } from '../components/Buttons/Notification';

export const RedStart = () => <span style={{ color: 'var(--BRAND-DHL-Red)' }}>*</span>;

const BaseFormatContainer = styled.div`
  position: relative;
  height: 560px;
  width: 330px;
  background-color: var(--GRAY-OPAC-Gray-O-200);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &::before {
    content: '';
    position: absolute;
    top: 10px; /* Abstand von oben */
    left: -15px; /* Abstand von links */
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid var(--GRAY-OPAC-Gray-O-200);
  }
`;

const BaseFormatInfoBox = () => {
  const { t } = useTranslation();
  const { isStandardLetter, isLargeLetter } = useLetterType();
  const showBaseFormat = isStandardLetter || isLargeLetter;

  if (!showBaseFormat) {
    return null;
  }

  return (
    <BaseFormatContainer>
      <Flex direction={'column'} pt="20px">
        {isStandardLetter && <DimensionsStandard />}
        {isLargeLetter && <DimensionsGross />}
      </Flex>
      <Flex direction={'column'} align={'center'}>
        <Text fw={800} fz="20px" pt="50px">
          {isStandardLetter ? t('step-one.base-format.standard') : t('step-one.base-format.large')}
        </Text>
        <Text pt="10px" style={{ textAlign: 'center' }}>
          {isStandardLetter ? t('step-one.base-format.standard.details') : t('step-one.base-format.large.details')}
        </Text>
      </Flex>
    </BaseFormatContainer>
  );
};

export const StepOne = () => {
  const { t } = useTranslation();
  const { form, resetForm } = useFormContext();
  const { navigateAndKeepSearchParams: navigate } = useNavigateWithSearchParams();
  const { createProject, isCreateProjectSuccess } = useCreateProject();
  const { updateProject, isUpdateProjectSuccess } = useUpdateProject();
  const goToDashboard = useGoToDashboard();

  const { projectNumber } = useParams<{ projectNumber?: string }>();

  useEffect(() => {
    if (!projectNumber) {
      resetForm();
      return;
    }
  }, [projectNumber]);

  const updateProjectForStepOne = async (projectNumber: string) => {
    if (
      !form.values.longSideInMm ||
      !form.values.shortSideInMm ||
      !form.values.weightInG ||
      !form.values.thicknessInMm ||
      !form.values.name
    ) {
      return;
    }
    await updateProject(projectNumber, {
      name: form.values.name,
      physicalDocumentProperties: {
        longSideInMm: form.values.longSideInMm,
        shortSideInMm: form.values.shortSideInMm,
        weightInG: form.values.weightInG,
        thicknessInMm: form.values.thicknessInMm,
      },
      productType: 'DIALOGPOST',
    });
  };

  useEffect(() => {
    if (
      (form.values.projectNumber && isUpdateProjectSuccess) ||
      (!form.values.projectNumber && isCreateProjectSuccess && isUpdateProjectSuccess)
    ) {
      navigate(`/wizard/${form.values.projectNumber}/2`);
    }
  }, [isCreateProjectSuccess, isUpdateProjectSuccess]);

  const createAndUpdateProjectForStepOne = async () => {
    if (form.values.projectNumber) {
      await updateProjectForStepOne(form.values.projectNumber);
    } else {
      const projectNumber = await createProject(form.values.name);
      form.setFieldValue('projectNumber', projectNumber);
      await updateProjectForStepOne(projectNumber);
    }
  };

  const shortSideInMm = Number(form.values.shortSideInMm);
  const longSideInMm = Number(form.values.longSideInMm);
  const isShortSideGreaterThanLongSide = shortSideInMm > longSideInMm;
  const nameTestOrderIsEmptyOrInvalid = !form.values.name || !!form.errors.name;
  const lengthIsEmptyOrInvalid = !form.values.longSideInMm || !!form.errors.longSideInMm;
  const widthIsEmptyOrInvalid = !form.values.shortSideInMm || !!form.errors.shortSideInMm;
  const heightIsInvalid = !form.values.thicknessInMm;
  const weightIsInvalid = !form.values.weightInG;

  const isNextButtonDisabledStepOne =
    nameTestOrderIsEmptyOrInvalid ||
    lengthIsEmptyOrInvalid ||
    widthIsEmptyOrInvalid ||
    heightIsInvalid ||
    weightIsInvalid ||
    isShortSideGreaterThanLongSide;

  return (
    <>
      <Paper shadow="sm" radius="md" withBorder p="xl">
        <Grid gutter={40}>
          <Grid.Col span={4}>
            <Grid>
              <Grid.Col>
                <h2>{t('step-one.specify.test-order-name')}</h2>
                <Text style={{ marginTop: '10px' }}>
                  <RedStart /> {t('mandatory')}
                </Text>
              </Grid.Col>
              <Grid.Col>
                <Flex direction={'column'}>
                  <h3>{t('step-one.subtitle')}</h3>
                  <InputCompact
                    data-testid="name"
                    required
                    label={t('step-one.test-order-name')}
                    key={form.key('name')}
                    {...form.getInputProps('name')}
                  />
                </Flex>
                <Flex direction={'column'} style={{ marginTop: '10px' }}>
                  <h3>{t('step-one.specify.shipment.dimensions')}</h3>
                  <Text>{t('step-one.specify.shipment.dimensions.details')}</Text>
                </Flex>
                <Flex direction={'column'}>
                  <Flex direction={'column'} w="200px" mb="10px">
                    <Flex align={'center'} gap="10px">
                      <InputCompact
                        type="number"
                        data-testid="longSideInMm"
                        required
                        label={t('step-one.length')}
                        key={form.key('longSideInMm')}
                        {...form.getInputProps('longSideInMm')}
                      />

                      <Text>mm</Text>
                    </Flex>
                    <Flex align={'center'} gap="10px">
                      <InputCompact
                        type="number"
                        data-testid="shortSideInMm"
                        required
                        label={t('step-one.width')}
                        key={form.key('shortSideInMm')}
                        {...form.getInputProps('shortSideInMm')}
                      />
                      <Text>mm</Text>
                    </Flex>
                  </Flex>
                  <Flex style={{ visibility: isShortSideGreaterThanLongSide ? 'visible' : 'hidden' }}>
                    <Notification text={t('step-one.error.short-side-greater-than-long-side')} />
                  </Flex>

                  <Flex direction="column" gap="15px">
                    <Flex direction="column">
                      <h3>
                        {t('step-one.specify.weight-of-order')} <span style={{ color: 'var(--BRAND-DHL-Red)' }}>*</span>
                      </h3>
                      <Flex gap="15px">
                        <RadioButtonCard
                          style={{ width: '175px' }}
                          data-testid="weightUpToFifty"
                          onChange={() => form.setFieldValue('weightInG', 50)}
                          label={t('step-one.weight.up-to-fifty')}
                          checked={form.values.weightInG === 50}
                          image={<Max50g />}
                          value={'50'}
                        />
                        <RadioButtonCard
                          style={{ width: '175px' }}
                          data-testid="weightFiftyToThousand"
                          onChange={() => form.setFieldValue('weightInG', 1000)}
                          label={t('step-one.weight.fifty-to-thousand')}
                          checked={form.values.weightInG === 1000}
                          image={<Max1Kg />}
                          value={'1000'}
                        />
                      </Flex>
                    </Flex>
                    <Flex direction="column">
                      <h3>
                        {t('step-one.specify.height-of-order')} <span style={{ color: 'var(--BRAND-DHL-Red)' }}>*</span>
                      </h3>
                      <Flex gap="15px">
                        <RadioButtonCard
                          style={{ width: '175px' }}
                          data-testid="heightUpToFive"
                          onChange={() => form.setFieldValue('thicknessInMm', 5)}
                          label={t('step-one.height.up-to-five')}
                          checked={form.values.thicknessInMm === 5}
                          image={<Max5mm />}
                          value={'5'}
                        />
                        <RadioButtonCard
                          style={{ width: '175px' }}
                          data-testid="heightFiveToThirty"
                          onChange={() => form.setFieldValue('thicknessInMm', 30)}
                          label={t('step-one.height.five-to-thirty')}
                          checked={form.values.thicknessInMm === 30}
                          image={<Max30mm />}
                          value={'30'}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={5} style={{ display: 'flex', alignItems: 'flex-end' }}>
            <BaseFormatInfoBox />
          </Grid.Col>
          <Grid.Col span={3}>
            <Flex
              style={{
                borderLeft: '1px solid var(--GRAY-OPAC-Gray-O-400)',
                paddingLeft: '20px',
                paddingRight: '20px',
                height: '100%',
              }}
            >
              <Flex direction={'column'} gap={10}>
                <InfoIcon color="var(--BASIC-Black)"></InfoIcon>
                <Text fw={700}>{t('step-one.info.general-hints')}</Text>
                <List spacing="10">
                  <ListItem>
                    <Text>
                      <span style={{ fontWeight: 700 }}>{t('step-one.info.list.length')} </span>
                      {t('step-one.info.list.length.details')}
                    </Text>
                  </ListItem>
                  <ListItem>
                    <span style={{ fontWeight: 700 }}>{t('step-one.info.list.format-standard')} </span>
                    <DimensionsStandard />
                    <Text>{t('step-one.info.list.format-standard.details')}</Text>
                  </ListItem>
                  <ListItem>
                    <Text style={{ paddingBottom: '20px' }}>
                      <span style={{ fontWeight: 700 }}>{t('step-one.info.list.format-gross')} </span>
                    </Text>
                    <DimensionsGross />
                  </ListItem>
                </List>
              </Flex>
            </Flex>
          </Grid.Col>
        </Grid>
      </Paper>
      <ButtonBar
        onNext={createAndUpdateProjectForStepOne}
        onCancel={goToDashboard}
        cancelButtonLabel={t('cancel')}
        nextButtonLabel={t('next')}
        isNextButtonDisabled={isNextButtonDisabledStepOne}
      />
    </>
  );
};
