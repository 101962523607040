interface LetterWithoutWindowGrossProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const LetterWithoutWindowGross = ({ size, style, ...others }: LetterWithoutWindowGrossProps) => (
  <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.2034 59.2669H53.8777V12.7002H18.2034L18.2034 59.2669Z" fill="white" />
    <path
      d="M43.9939 15.4404H50.5348C50.87 15.4404 51.1417 15.7121 51.1417 16.0473V19.8519C51.1417 20.0127 51.0779 20.1668 50.9642 20.2805C50.8506 20.3941 50.6964 20.458 50.5357 20.458H43.9939C43.6587 20.458 43.387 20.1863 43.387 19.8511V16.0473C43.387 15.7121 43.6587 15.4404 43.9939 15.4404V15.4404Z"
      fill="#FFCC00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.51 59.2668V12.7002C16.51 12.2511 16.6884 11.8204 17.006 11.5028C17.3235 11.1852 17.7542 11.0068 18.2033 11.0068H53.8777C54.8129 11.0068 55.571 11.765 55.571 12.7002V59.2668C55.571 60.202 54.8129 60.9602 53.8777 60.9602H18.2033C17.2681 60.9602 16.51 60.202 16.51 59.2668ZM18.2033 59.2668H53.8777V12.7002H18.2033V59.2668Z"
      fill="black"
    />
    <rect x="22.4366" y="21.5898" width="6.77333" height="1.69333" rx="0.846667" fill="black" />
    <rect x="22.4366" y="24.1299" width="12.2767" height="1.69333" rx="0.846667" fill="black" />
    <rect x="22.4366" y="26.6699" width="10.5833" height="1.69333" rx="0.846666" fill="black" />
  </svg>
);
