import { useSearchParams } from 'react-router-dom';
import { DataTableSortStatus } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { ProjectVO } from '../api/requests';

const QUERY_PREFIX = 'ac-';

export const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    sessionStorage.setItem('ac-searchParams', searchParams.toString());
  }, [searchParams]);

  const getPrefixedParam = (param: string) => searchParams.get(`${QUERY_PREFIX}${param}`) || undefined;

  const [page, setPage] = useState(Number(getPrefixedParam('page')) || 0);
  const [pageSize, setPageSize] = useState(Number(getPrefixedParam('pageSize')) || 5);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<ProjectVO>>({
    columnAccessor: getPrefixedParam('sortColumn') || 'updated',
    direction: (getPrefixedParam('sortDirection') as 'asc' | 'desc') || 'desc',
  });
  const [productType, setProductType] = useState<string | undefined>(getPrefixedParam('productType'));
  const [name, setName] = useState<string | undefined>(getPrefixedParam('name'));
  const [updatedAfter, setUpdatedAfter] = useState<string | undefined>(getPrefixedParam('updatedAfter'));
  const [updatedBefore, setUpdatedBefore] = useState<string | undefined>(getPrefixedParam('updatedBefore'));
  const [state, setState] = useState<string | undefined>(getPrefixedParam('state'));
  const [attestationState, setAttestationState] = useState<string | undefined>(getPrefixedParam('attestationState'));
  const [createdByFamilyName, setCreatedByFamilyName] = useState<string | undefined>(
    getPrefixedParam('createdByFamilyName'),
  );

  const setPrefixedParam = (params: URLSearchParams, key: string, value: string | undefined) => {
    if (value) {
      params.set(`${QUERY_PREFIX}${key}`, value);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams();
    // Add existing params that do not start with the prefix
    searchParams.forEach((value, key) => {
      if (!key.startsWith(QUERY_PREFIX)) {
        params.set(key, value);
      }
    });

    setPrefixedParam(params, 'page', String(page));
    setPrefixedParam(params, 'pageSize', String(pageSize));
    setPrefixedParam(params, 'sortColumn', sortStatus.columnAccessor);
    setPrefixedParam(params, 'sortDirection', sortStatus.direction);
    setPrefixedParam(params, 'productType', productType);
    setPrefixedParam(params, 'name', name);
    setPrefixedParam(params, 'updatedAfter', updatedAfter);
    setPrefixedParam(params, 'updatedBefore', updatedBefore);
    setPrefixedParam(params, 'state', state);
    setPrefixedParam(params, 'attestationState', attestationState);
    setPrefixedParam(params, 'createdByFamilyName', createdByFamilyName);
    setSearchParams(params);
  }, [
    page,
    pageSize,
    sortStatus,
    productType,
    name,
    updatedAfter,
    updatedBefore,
    state,
    attestationState,
    createdByFamilyName,
    searchParams,
    setSearchParams,
  ]);

  return {
    page,
    setPage,
    pageSize,
    setPageSize,
    sortStatus,
    setSortStatus,
    productType,
    setProductType,
    name,
    setName,
    updatedAfter,
    setUpdatedAfter,
    updatedBefore,
    setUpdatedBefore,
    state,
    setState,
    attestationState,
    setAttestationState,
    createdByFamilyName,
    setCreatedByFamilyName,
  };
};
