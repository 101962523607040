export const Max30mm = () => {
  return (
    <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M52.4336 66.5958V5.34668L56.7108 6.05955V65.1701L52.4336 66.5958Z" fill="#B2B2B2" />
      <path d="M3.60156 7.53032L52.4438 5.34668V66.6098L3.60156 57.8602V7.53032Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 7.53027C0.75 6.00496 1.95034 4.74975 3.47413 4.68163L52.2529 2.50083C52.3161 2.49681 52.3797 2.49491 52.4433 2.49514C52.6352 2.4951 52.8252 2.51442 53.0108 2.55208L57.1792 3.24681C58.5541 3.47597 59.5619 4.66558 59.5619 6.0595V65.17C59.5619 66.3974 58.7765 67.4871 57.6121 67.8752L53.6319 69.2019C53.1087 69.4417 52.5192 69.5201 51.9409 69.4166L3.09868 60.667C1.73944 60.4235 0.75 59.241 0.75 57.8602V7.53027ZM56.7104 65.17V6.0595L52.4438 5.34839L52.4438 5.3471L52.4354 5.347L52.4332 5.34662L3.60149 7.53027V57.8602L52.4438 66.6098V66.5923L56.7104 65.17Z"
        fill="black"
      />
      <path
        d="M32.123 13.4499C32.123 12.6967 32.7088 12.0735 33.4605 12.0268L41.5269 11.5265C42.3474 11.4756 43.0409 12.1273 43.0409 12.9495V16.978C43.0409 17.7562 42.4169 18.3906 41.6388 18.4035L33.5724 18.5372C32.7759 18.5504 32.123 17.9084 32.123 17.1117V13.4499Z"
        fill="#FFCC00"
      />
      <path
        d="M67.3997 35.6503C68.1354 35.3695 68.9594 35.7382 69.2402 36.4738C69.521 37.2094 69.1523 38.0334 68.4167 38.3143C64.7217 39.7248 60.7218 40.7292 56.4187 41.3288C52.1255 41.927 47.7488 42.0918 43.2901 41.8236C42.5041 41.7764 41.9053 41.1009 41.9526 40.3149C41.9998 39.5289 42.6753 38.93 43.4613 38.9773C47.733 39.2342 51.9205 39.0765 56.0252 38.5046C60.1201 37.934 63.911 36.9821 67.3997 35.6503Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.4138 38.3151C68.4145 38.3148 68.4152 38.3145 68.4159 38.3143C69.1516 38.0334 69.5203 37.2094 69.2394 36.4738C68.9586 35.7382 68.1346 35.3695 67.3989 35.6503C63.9241 36.9769 60.1355 37.9318 56.0244 38.5046C51.924 39.0759 47.7367 39.2345 43.4608 38.9773C43.4607 38.9773 43.4606 38.9773 43.4605 38.9773C42.6745 38.93 41.999 39.5289 41.9518 40.3149C41.9045 41.1009 42.5034 41.7764 43.2893 41.8236C47.7435 42.0915 52.1203 41.9276 56.418 41.3288C60.7032 40.7317 64.7048 39.7308 68.4138 38.3151ZM66.382 32.9863C68.5889 32.1438 71.0609 33.2499 71.9034 35.4568C72.7459 37.6637 71.6398 40.1357 69.4329 40.9782C65.5178 42.4728 61.3066 43.5267 56.8115 44.153C52.3255 44.778 47.7595 44.9491 43.1182 44.67C40.7602 44.5282 38.9636 42.5017 39.1054 40.1437C39.2472 37.7857 41.2737 35.9891 43.6317 36.131C47.7209 36.3769 51.7191 36.2254 55.6309 35.6804C59.5346 35.1365 63.1141 34.2339 66.382 32.9863Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.6187 33.7198C63.9266 33.4392 63.1352 33.7217 62.8126 34.3825C62.4747 35.0747 62.7793 35.92 63.4931 36.2705L64.6888 36.8577C62.0395 37.6254 59.2275 38.1935 56.2522 38.5616C53.3539 38.9201 48.988 39.0561 45.1635 38.9962L46.14 38.2951L46.2313 38.2237C46.7848 37.7523 46.8752 36.9237 46.4202 36.3191C45.9413 35.6828 45.0393 35.5358 44.4056 35.9907L40.4341 38.842C39.8279 38.9563 39.3592 39.4539 39.3147 40.0862C39.2067 40.5714 39.3666 41.105 39.7779 41.4652L44.2521 45.3837L44.3396 45.4548C44.936 45.9028 45.7798 45.8392 46.2723 45.2913C46.7894 44.716 46.7233 43.8247 46.1247 43.3004L44.3964 41.7868C48.5343 41.8825 53.4459 41.7466 56.6832 41.3461C59.6727 40.9763 62.5131 40.4124 65.2039 39.654L64.8577 40.3559L64.8147 40.4521C64.5415 41.1318 64.8504 41.9277 65.5383 42.269C66.2589 42.6265 67.1226 42.3497 67.4674 41.6506L69.1457 38.2478C69.4467 37.9644 69.6039 37.5563 69.5666 37.1374C69.5841 36.7776 69.4605 36.4192 69.2231 36.1387C69.0907 35.9699 68.9183 35.827 68.7117 35.7255L64.7168 33.7637L64.6187 33.7198Z"
        fill="black"
      />
    </svg>
  );
};
