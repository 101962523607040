interface LetterWithWindowGrossProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const LetterWithWindowGross = ({ size, style, ...others }: LetterWithWindowGrossProps) => (
  <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.1628 59.2835H53.8371V12.7168H18.1628V59.2835ZM43.3464 16.0644C43.347 15.9036 43.4111 15.7495 43.5248 15.6358C43.6385 15.5221 43.7925 15.458 43.9533 15.4575H50.4943C50.6551 15.458 50.8091 15.5221 50.9229 15.6358C51.0366 15.7495 51.1007 15.9036 51.1012 16.0644V19.869C51.1006 20.0297 51.0365 20.1837 50.9228 20.2972C50.8091 20.4108 50.655 20.4748 50.4943 20.4751H43.9533C43.7926 20.4748 43.6386 20.4108 43.5249 20.2972C43.4111 20.1837 43.347 20.0297 43.3464 19.869V16.0644ZM21.2295 21.4111C21.2295 21.1865 21.3187 20.9712 21.4775 20.8124C21.6363 20.6536 21.8516 20.5644 22.0762 20.5644H36.2363C36.4609 20.5644 36.6762 20.6536 36.835 20.8124C36.9938 20.9712 37.083 21.1865 37.083 21.4111C37.083 21.6356 36.9938 21.851 36.835 22.0098C36.6762 22.1686 36.4609 22.2578 36.2363 22.2578V29.1203H22.9225C22.9225 29.3449 22.8333 29.5602 22.6745 29.719C22.5157 29.8778 22.3004 29.967 22.0758 29.967C21.8513 29.967 21.6359 29.8778 21.4772 29.719C21.3184 29.5602 21.2292 29.3449 21.2292 29.1203L21.2295 21.4111Z"
      fill="white"
    />
    <path
      d="M43.9533 15.458H50.4943C50.6552 15.458 50.8096 15.5219 50.9234 15.6358C51.0372 15.7496 51.1012 15.9039 51.1012 16.0649V19.8695C51.1012 19.9491 51.0855 20.0279 51.055 20.1014C51.0246 20.175 50.9799 20.2418 50.9237 20.2981C50.8674 20.3543 50.8006 20.399 50.7271 20.4294C50.6535 20.4599 50.5747 20.4756 50.4951 20.4756H43.9533C43.7924 20.4756 43.638 20.4116 43.5242 20.2978C43.4104 20.184 43.3464 20.0296 43.3464 19.8687V16.0649C43.3464 15.9039 43.4104 15.7496 43.5242 15.6358C43.638 15.5219 43.7924 15.458 43.9533 15.458Z"
      fill="#FFCC00"
    />
    <path d="M36.2365 22.2578H22.9229V29.1204H36.2365V22.2578Z" fill="#FFCC00" />
    <path
      d="M16.4695 12.7168V59.2834C16.4695 59.7325 16.6479 60.1633 16.9654 60.4808C17.283 60.7984 17.7137 60.9768 18.1628 60.9768H53.8371C54.2862 60.9768 54.7169 60.7984 55.0345 60.4808C55.3521 60.1633 55.5305 59.7325 55.5305 59.2834V12.7168C55.5305 12.2677 55.3521 11.837 55.0345 11.5194C54.7169 11.2018 54.2862 11.0234 53.8371 11.0234H18.1628C17.9404 11.0234 17.7202 11.0672 17.5148 11.1523C17.3094 11.2374 17.1227 11.3622 16.9654 11.5194C16.8082 11.6766 16.6835 11.8633 16.5984 12.0688C16.5133 12.2742 16.4695 12.4944 16.4695 12.7168ZM53.8371 59.2834H18.1628V12.7168H53.8371V59.2834Z"
      fill="black"
    />
    <path
      d="M22.0762 29.9671C22.3007 29.9671 22.5161 29.8779 22.6748 29.7191C22.8336 29.5603 22.9228 29.345 22.9228 29.1204V22.2578H36.2367C36.4612 22.2578 36.6766 22.1686 36.8353 22.0098C36.9941 21.851 37.0833 21.6357 37.0833 21.4111C37.0833 21.1866 36.9941 20.9712 36.8353 20.8124C36.6766 20.6537 36.4612 20.5645 36.2367 20.5645H22.0762C21.8516 20.5645 21.6363 20.6537 21.4775 20.8124C21.3187 20.9712 21.2295 21.1866 21.2295 21.4111V29.1204C21.2295 29.345 21.3187 29.5603 21.4775 29.7191C21.6363 29.8779 21.8516 29.9671 22.0762 29.9671Z"
      fill="black"
    />
  </svg>
);
