import { useProjectControllerServiceUpdateProject } from '../api/queries';
import { ProjectDetailVO } from '../api/requests';

export const useUpdateProject = () => {
  const updateProjectQueryInfo = useProjectControllerServiceUpdateProject();

  const updateProject = async (projectNumber: string, data: Partial<ProjectDetailVO>) => {
    return updateProjectQueryInfo.mutateAsync({
      projectNumber,
      requestBody: data,
    });
  };

  return {
    updateProject,
    isUpdateProjectSuccess: updateProjectQueryInfo.isSuccess,
  };
};
