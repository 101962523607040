interface ErrorIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const ErrorIcon = ({ size, style, ...others }: ErrorIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
    <g clipPath="url(#clip0_4044_6624)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00002 0.5C13.9703 0.5 18 4.52974 18 9.49998C18 14.4702 13.9703 18.5 9.00002 18.5C4.02974 18.5 0 14.4702 0 9.49998C0 4.52974 4.02974 0.5 9.00002 0.5ZM9.00002 1.99999C4.86449 1.99999 1.49999 5.36449 1.49999 9.49998C1.49999 13.6355 4.86449 17 9.00002 17C13.1355 17 16.5 13.6355 16.5 9.49998C16.5 5.36449 13.1355 1.99999 9.00002 1.99999ZM10.0607 9.49998L12.7123 12.1516L11.6516 13.2123L9.00002 10.5606L6.34836 13.2123L5.28768 12.1516L7.93933 9.49998L5.28768 6.84832L6.34836 5.78768L9.00002 8.43933L11.6516 5.78768L12.7123 6.84832L10.0607 9.49998Z"
        fill="#D40511"
      />
    </g>
    <defs>
      <clipPath id="clip0_4044_6624">
        <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
