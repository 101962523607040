export const Max5mm = () => {
  return (
    <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.10156 7.49854L54.0824 4.99023C54.0824 4.99023 56.9788 12.0013 56.9788 40.6337C56.9788 69.2661 54.7847 66.6535 54.7847 66.6535L3.10156 57.8601V7.49854Z"
        fill="#B2B2B2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 7.49851C0.25 5.97815 1.44283 4.72518 2.96136 4.65047L53.9422 2.14217C55.4434 2.06831 56.7442 3.17232 56.9153 4.66553L54.0824 4.99021L3.10149 7.49851V57.8601L54.0824 66.6096C54.0824 66.6096 54.2046 66.7614 54.3785 66.3711C54.3786 66.3711 54.3786 66.371 54.3786 66.371C54.5229 66.0472 54.7028 65.3506 54.878 63.8854C54.8786 63.8809 54.8791 63.8764 54.8796 63.8719C55.2255 60.9706 55.553 55.0705 55.553 43.1287C55.553 17.8218 54.0824 4.99021 54.0824 4.99021C56.9153 4.66553 56.9154 4.66589 56.9154 4.6663L56.9155 4.66735L56.9159 4.67035L56.9169 4.67987L56.9206 4.713C56.9238 4.74135 56.9283 4.78222 56.934 4.83561C56.9455 4.94238 56.962 5.0992 56.9828 5.30598C57.0243 5.71955 57.083 6.33296 57.1531 7.14552C57.2933 8.77065 57.479 11.1924 57.6644 14.4051C58.0352 20.8306 58.4045 30.42 58.4045 43.1287C58.4045 55.7881 58.0392 61.8278 57.6432 64.7431C57.5427 65.4828 57.4331 66.0776 57.3107 66.5575C57.1991 66.9953 57.0335 67.5176 56.7412 67.993C56.4947 68.394 55.675 69.5095 54.1099 69.4899C53.8193 69.4863 53.5563 69.4407 53.3237 69.3726L2.61915 60.6705C1.25044 60.4356 0.25 59.2488 0.25 57.8601V7.49851ZM56.2913 64.8065C56.2913 64.8064 56.2913 64.8064 56.2912 64.8063L56.291 64.8061C56.2911 64.8062 56.2912 64.8063 56.2913 64.8065Z"
        fill="black"
      />
      <path
        d="M3.10156 7.53003L54.0824 5.02173C52.6461 8.55432 53.359 27.8018 53.359 34.9306C53.359 49.9009 54.0824 66.6095 54.0824 66.6095L3.10156 57.8599V7.53003Z"
        fill="white"
      />
      <path
        d="M35.9004 13.4496C35.9004 12.6965 36.4862 12.0732 37.2379 12.0266L45.3042 11.5262C46.1248 11.4753 46.8182 12.1271 46.8182 12.9492V16.9777C46.8182 17.7559 46.1942 18.3904 45.4161 18.4033L37.3498 18.537C36.5532 18.5502 35.9004 17.9081 35.9004 17.1115V13.4496Z"
        fill="#FFCC00"
      />
      <path
        d="M64.7611 35.6503C65.4967 35.3695 66.3207 35.7382 66.6015 36.4738C66.8824 37.2094 66.5137 38.0334 65.778 38.3143C62.0831 39.7248 58.0832 40.7292 53.7801 41.3288C49.4868 41.927 45.1102 42.0918 40.6515 41.8236C39.8655 41.7764 39.2666 41.1009 39.3139 40.3149C39.3612 39.5289 40.0366 38.93 40.8226 38.9773C45.0943 39.2342 49.2818 39.0765 53.3866 38.5046C57.4814 37.934 61.2724 36.9821 64.7611 35.6503Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.7751 38.3151C65.7758 38.3148 65.7765 38.3145 65.7772 38.3143C66.5129 38.0334 66.8816 37.2094 66.6007 36.4738C66.3199 35.7382 65.4959 35.3695 64.7603 35.6503C61.2854 36.9769 57.4968 37.9318 53.3858 38.5046C49.2853 39.0759 45.0981 39.2345 40.8221 38.9773C40.822 38.9773 40.8219 38.9773 40.8219 38.9773C40.0359 38.93 39.3604 39.5289 39.3131 40.3149C39.2658 41.1009 39.8647 41.7764 40.6507 41.8236C45.1048 42.0915 49.4816 41.9276 53.7793 41.3288C58.0645 40.7317 62.0662 39.7308 65.7751 38.3151ZM63.7433 32.9863C65.9502 32.1438 68.4222 33.2499 69.2647 35.4568C70.1072 37.6637 69.0011 40.1357 66.7942 40.9782C62.8792 42.4728 58.668 43.5267 54.1728 44.153C49.6868 44.778 45.1209 44.9491 40.4795 44.67C38.1215 44.5282 36.3249 42.5017 36.4668 40.1437C36.6086 37.7857 38.6351 35.9891 40.993 36.131C45.0822 36.3769 49.0805 36.2254 52.9923 35.6804C56.8959 35.1365 60.4755 34.2339 63.7433 32.9863Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.9817 33.7197C61.2897 33.4392 60.4982 33.7217 60.1756 34.3824C59.8377 35.0746 60.1424 35.9199 60.8561 36.2705L62.0519 36.8577C59.4025 37.6253 56.5905 38.1935 53.6152 38.5615C53.2985 38.6007 53.0066 38.6372 52.7357 38.6711C50.9146 38.899 50.0407 39.0084 48.9233 39.0098L49.9189 38.295L50.0101 38.2237C50.5636 37.7522 50.6541 36.9236 50.1991 36.3191C49.7201 35.6828 48.8181 35.5357 48.1844 35.9907L43.6631 39.2366L43.5719 39.308C43.5024 39.3672 43.4402 39.432 43.3855 39.5013C42.8943 40.0772 42.9674 40.949 43.5567 41.4651L48.031 45.3837L48.1185 45.4547C48.7148 45.9028 49.5586 45.8392 50.0511 45.2913C50.5682 44.716 50.5022 43.8246 49.9036 43.3004L48.1927 41.802C49.9177 41.8595 50.8577 41.7426 53.3699 41.43C53.5835 41.4034 53.8085 41.3755 54.0463 41.346C57.0358 40.9762 59.8762 40.4124 62.5669 39.654L62.2208 40.3559L62.1778 40.4521C61.9046 41.1317 62.2135 41.9277 62.9013 42.269C63.622 42.6265 64.4857 42.3496 64.8305 41.6506L66.5087 38.2478C66.8097 37.9644 66.9669 37.5563 66.9297 37.1374C66.9472 36.7776 66.8236 36.4191 66.5861 36.1386C66.4537 35.9699 66.2814 35.8269 66.0747 35.7254L62.0799 33.7637L61.9817 33.7197Z"
        fill="black"
      />
    </svg>
  );
};
