import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact, { ReactAppOrParcel } from 'single-spa-react';
import { DefaultEntryPoint } from './src/DefaultEntryPoint';
import {
  AutomationCheckInternalEntryPoint as AutomationCheckInternalEntryPointComponent,
  AutomationCheckInternalEntryPointProps,
} from './src/InternalEntryPoint';

console.log('Build Date:', process.env.BUILD_DATE);

/*
 * This is the entry point for the microfrontend single-spa for GKP integration
 */
const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: DefaultEntryPoint,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    // There is no i18Next
    return <h1>Could not render Microfrontend</h1>;
  },
});

export const AutomationCheckInternalEntryPoint: ReactAppOrParcel<AutomationCheckInternalEntryPointProps> =
  singleSpaReact({
    React,
    ReactDOM,
    rootComponent: AutomationCheckInternalEntryPointComponent,
  });

export const { bootstrap, mount, unmount } = lifecycles;
export { AutomationCheckInternalEntryPointProps };
