import { useEffect, useMemo, useState } from 'react';
import { useProjectControllerServiceGetLatestAnalyzeResults } from '../api/queries';
import { useFormContext } from './useFormContext';

export const useAnalyticResults = (enabled: boolean) => {
  const { form } = useFormContext();
  const { projectNumber } = form.values;

  const [openedSections, setOpenedSections] = useState<string[]>([]);

  const analyzeResultsQueryInfo = useProjectControllerServiceGetLatestAnalyzeResults({ projectNumber }, undefined, {
    enabled,
  });

  const sections = useMemo(
    () => analyzeResultsQueryInfo.data?.sections ?? [],
    [analyzeResultsQueryInfo.data?.sections],
  );

  const sortedSections = useMemo(() => {
    return [...sections].sort((a, b) => {
      const aHasError = a.checks.some((check) => check.result === 'ERROR');
      const bHasError = b.checks.some((check) => check.result === 'ERROR');
      if (aHasError && !bHasError) return 1;
      if (!aHasError && bHasError) return -1;
      return 0;
    });
  }, [sections]);

  useEffect(() => {
    const errorSections = sortedSections
      .filter((section) => section.checks.some((check) => check.result === 'ERROR'))
      .map((section) => section.name);
    setOpenedSections(errorSections);
  }, [sortedSections]);

  return {
    sections: sortedSections,
    openedSections,
    setOpenedSections,
    isAnalyticsLoading: analyzeResultsQueryInfo.isLoading,
  };
};
