import { Flex } from '@mantine/core';

interface NotificationProps {
  text: string;
}

export const Notification = ({ text }: NotificationProps) => (
  <Flex
    direction="column"
    style={{
      backgroundColor: 'var(--BRAND-Postyellow-30p)',
      borderRadius: '5px',
      padding: '5px 15px',
      alignItems: 'flex-start',
      fontSize: '12px',
      display: 'inline-flex',
      maxWidth: 'max-content',
    }}
  >
    {text}
  </Flex>
);
