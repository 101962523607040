import { createContext, useContext } from 'react';
import { AuthenticationManager } from '@gkuis/gkp-authentication';

export const AuthenticationManagerContext = createContext<AuthenticationManager | undefined>(undefined);

export const useAuthenticationManager = () => {
  const authenticationManager: AuthenticationManager | undefined = useContext(AuthenticationManagerContext);

  if (authenticationManager === undefined) {
    throw new Error('useAuthenticationManager hook must be used inside AuthenticationManagerContext provider');
  }

  return authenticationManager;
};
