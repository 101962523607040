import { useProjectControllerServiceUploadDocument } from '../api/queries';

export const useUploadDocument = () => {
  const uploadDocumentQueryInfo = useProjectControllerServiceUploadDocument();

  const uploadDocument = async (projectNumber: string, userFile: File) => {
    return uploadDocumentQueryInfo.mutateAsync({
      projectNumber: projectNumber,
      formData: { file: userFile },
    });
  };

  return {
    uploadDocument,
    isUploadDocumentPending: uploadDocumentQueryInfo.isPending,
    isUploadDocumentSuccess: uploadDocumentQueryInfo.isSuccess,
  };
};
