interface QuestionIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  color?: string;
  size?: number | string;
}

export const QuestionIcon = ({ color, size, ...others }: QuestionIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size || '24'} height={size || '24'} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" r="11" fill="transparent" />
      <path
        fill={color || '#878787'}
        d="M12,0 C18.623,0 24,5.377 24,12 C24,18.623 18.623,24 12,24 C5.377,24 0,18.623 0,12 C0,5.377 5.377,0 12,0 Z M12.109,17 C11.683,17 11.312,17.145 10.996,17.435 C10.676,17.729 10.515,18.101 10.515,18.551 C10.515,19.001 10.676,19.372 10.996,19.666 C11.312,19.956 11.683,20.101 12.109,20.101 C12.536,20.101 12.907,19.956 13.223,19.666 C13.543,19.372 13.704,19.001 13.704,18.551 C13.704,18.101 13.543,17.729 13.223,17.435 C12.907,17.145 12.536,17 12.109,17 Z M12.286,4.153 C11.262,4.153 10.384,4.327 9.65,4.671 C8.909,5.019 8.299,5.509 7.821,6.142 L7.821,6.142 L7.7,6.302 L9.158,7.704 L9.314,7.535 C9.662,7.16 10.079,6.865 10.566,6.649 C11.053,6.433 11.59,6.326 12.176,6.326 C12.987,6.326 13.653,6.524 14.168,6.93 C14.668,7.323 14.911,7.896 14.911,8.642 C14.911,9.303 14.689,9.866 14.244,10.331 C13.783,10.814 13.08,11.168 12.142,11.406 C12.143,11.406 10.994,11.694 10.994,11.694 L10.994,11.694 L10.978,15 L13.2,15 L13.25,12.997 C14.636,12.643 15.643,12.098 16.277,11.375 C16.946,10.613 17.283,9.666 17.283,8.531 C17.283,7.13 16.817,6.05 15.898,5.287 C14.993,4.535 13.79,4.153 12.286,4.153 Z"
      />
    </g>
  </svg>
);
