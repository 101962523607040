import { css, Global } from '@emotion/react';
import Delivery_W_Rg_woff2 from './assets/fonts/Delivery_W_Rg.woff2';
import Delivery_W_Rg_woff from './assets/fonts/Delivery_W_Rg.woff';
import Delivery_W_It_woff2 from './assets/fonts/Delivery_W_It.woff2';
import Delivery_W_It_woff from './assets/fonts/Delivery_W_It.woff';
import Delivery_W_Bd_woff2 from './assets/fonts/Delivery_W_Bd.woff2';
import Delivery_W_Bd_woff from './assets/fonts/Delivery_W_Bd.woff';

const globalStyles = css`
  :root {
    --BASIC-Black: #000;
    --BASIC-White: #fff;

    --BRAND-DHL-Red: #d40511;
    --BRAND-Postyellow: #ffcc00;
    --BRAND-Postyellow-50p: #ffe57f;
    --BRAND-Postyellow-30p: #fff0b2;
    --BRAND-DHL-Yellow: #daae00;

    --SECONDARY-Green-O-900: #3c763d;
    --SECONDARY-Green-O-500: #4caf50;
    --SECONDARY-Green-A-200: rgba(76, 175, 80, 0.2);
    --SECONDARY-Green-O-100: #dff0d8;
    --SECONDARY-Red-O-900: #7f030a;
    --SECONDARY-Red-O-800: #a2040d;
    --SECONDARY-Red-O-100: #fae6e7;
    --SECONDARY-Red-A-100: rgba(212, 5, 17, 0.1);
    --SECONDARY-Orange-O-500: #dff0d8;

    --GRAY-OPAC-Gray-O-900: #323232;
    --GRAY-OPAC-Gray-O-800: #5b5b5b;
    --GRAY-OPAC-Gray-O-700: #666;
    --GRAY-OPAC-Gray-O-600: #878787;
    --GRAY-OPAC-Gray-O-500: #d1d1d1;
    --GRAY-OPAC-Gray-O-400: #e6e6e6;
    --GRAY-OPAC-Gray-O-300: #e8e8e8;
    --GRAY-OPAC-Gray-O-200: #f2f2f2;
    --GRAY-OPAC-Gray-O-100: #f5f5f5;
    --GRAY-OPAC-Gray-O-50: #fafafa;

    --GRAY-ALPHA-Gray-A-900: rgba(50, 50, 50, 0.9);
    --GRAY-ALPHA-Gray-A-800: rgba(50, 50, 50, 0.8);
    --GRAY-ALPHA-Gray-A-700: rgba(50, 50, 50, 0.7);
    --GRAY-ALPHA-Gray-A-600: rgba(50, 50, 50, 0.6);
    --GRAY-ALPHA-Gray-A-500: rgba(50, 50, 50, 0.5);
    --GRAY-ALPHA-Gray-A-400: rgba(50, 50, 50, 0.4);
    --GRAY-ALPHA-Gray-A-300: rgba(50, 50, 50, 0.3);
    --GRAY-ALPHA-Gray-A-200: rgba(50, 50, 50, 0.2);
    --GRAY-ALPHA-Gray-A-100: rgba(50, 50, 50, 0.1);
    --GRAY-ALPHA-Gray-A-50: rgba(50, 50, 50, 0.05);
  }

  @font-face {
    font-family: 'Delivery';
    src:
      url(${Delivery_W_Rg_woff2}) format('woff2'),
      url(${Delivery_W_Rg_woff}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'DeliveryItalic';
    src:
      url(${Delivery_W_It_woff2}) format('woff2'),
      url(${Delivery_W_It_woff}) format('woff');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'DeliveryBold';
    src:
      url(${Delivery_W_Bd_woff2}) format('woff2'),
      url(${Delivery_W_Bd_woff}) format('woff');
    font-weight: bold;
    font-style: normal;
  }

  h1 {
    font-size: 25px;
    line-height: 28px;
  }

  h2 {
    font-size: 20px;
    line-height: 22px;
    margin: 0;
  }

  h3 {
    font-size: 16px;
    line-height: 22px;
  }

  a {
    color: var(--BASIC-Black);
    background-color: transparent;
    text-decoration: underline;
  }

  .mantine-Text-root,
  .mantine-List-item {
    font-size: 14px;
  }
`;

export const GlobalStyle = () => <Global styles={globalStyles} />;
